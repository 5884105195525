import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as Actions from "./constants";

const initState = {
  loading: false,
  error: false,
  countNotification: 0,
};

const NotificationReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_COUNT_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case Actions.GET_COUNT_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, countNotification: action.payload };
    case Actions.GET_COUNT_NOTIFICATION_FAILD:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "notification",
  storage,
};

export default persistReducer(persistConfig, NotificationReducer);
