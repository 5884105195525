import * as Actions from "../constants";
import { getFolderData, getBanner } from "../service";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { getCodeLanguage } from "@store/common/selectors";

function* getDataFolder() {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getFolderData, codeLanguage);
    const { data } = res;
    if (res.retCode === 0) {
      yield put({ type: Actions.GET_DATA_HOME_SUCCESS, payload: data });
    } else {
      yield put({ type: Actions.GET_DATA_HOME_FAILED, error: res.retText });
    }
  } catch (e) {
    yield put({ type: Actions.GET_DATA_HOME_FAILED, error: e });
  }
}

function* getDataASOBanner() {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getBanner, codeLanguage);
    const { data } = res;
    if (res.retCode === 0) {
      yield put({ type: Actions.GET_ASO_BANNER_SUCCESS, payload: data });
    } else {
      yield put({ type: Actions.GET_ASO_BANNER_FAILED, error: res.retText });
    }
  } catch (e) {
    yield put({ type: Actions.GET_ASO_BANNER_FAILED, error: e });
  }
}

export default function* folderSaga() {
  yield takeEvery(Actions.GET_DATA_HOME_REQUEST, getDataFolder);
  yield takeEvery(Actions.GET_ASO_BANNER_REQUEST, getDataASOBanner);
}
