import * as Actions from "./constants";
import { put, call, takeEvery } from "redux-saga/effects";

/*Services*/
import { countNotification } from "@Modules/Notification/Store/service";

/*Constants*/
import { RETCODE_SUCCESS } from "@configs/contants";

function* getCountNotification({ payload }) {
  try {
    const res = yield call(countNotification, payload);
    if (res.retCode === RETCODE_SUCCESS) {
      yield put({
        type: Actions.GET_COUNT_NOTIFICATION_SUCCESS,
        payload: parseInt(res.data),
      });
    } else {
      yield put({
        type: Actions.GET_COUNT_NOTIFICATION_FAILD,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_COUNT_NOTIFICATION_FAILD, error: e });
  }
}

export default function* notificationSaga() {
  yield takeEvery(Actions.GET_COUNT_NOTIFICATION, getCountNotification);
}
