import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as Actions from "./constants";

const initState = {
  language: "vi",
  codeLanguage: "vi-VN",
  province: [],
  district: [],
  shareInfo: {},
  category: [],
  settingApp: {
    isAutoLayout: true,
  },
  previousShowExpiriedTime: null,
  mobilePanelToShow: null,
  msgNoti: false,
  defaultVideoQuality: null,
  mindmapDataToShare: null,
  listMindmapShareShowType: null,
};

const CommonReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        codeLanguage: action.payload === "vi" ? "vi-VN" : "en-US",
        itemsLanguageSelect: action.payload,
      };
    case Actions.GET_SHARE_INFO_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_SHARE_INFO_SUCCESS:
      return {
        ...state,
        shareInfo: action.payload,
        loading: false,
      };
    case Actions.GET_SHARE_INFO_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case Actions.GET_LEFT_PANEL_FOLDER_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_LEFT_PANEL_FOLDER_SUCCESS:
      return {
        ...state,
        category: action.payload,
        loading: false,
      };
    case Actions.GET_LEFT_PANEL_FOLDER_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case Actions.REFRESH_LEFT_PANEL_FOLDER_REQUEST:
      return {
        ...state,
        category: action.payload,
      };
    case Actions.SET_SETTING_APP:
      return {
        ...state,
        settingApp: { ...state.settingApp, ...action.payload },
      };

    case Actions.SET_PREVIOUS_SHOW_EXPIRIED_REMAINING_POPUP_TIME: {
      return {
        ...state,
        previousShowExpiriedTime: action.payload,
      };
    }
    case Actions.SET_MOBILE_PANEL_TO_SHOW: {
      return {
        ...state,
        mobilePanelToShow: action.payload,
      };
    }
    case Actions.SET_MSG_NOTI: {
      return {
        ...state,
        msgNoti: action.payload,
      };
    }
    case Actions.SET_DEFAULT_VIDEO_QUALITY: {
      return {
        ...state,
        defaultVideoQuality: action.payload,
      };
    }
    case Actions.SET_MINDMAP_DATA_TO_SHARE: {
      return {
        ...state,
        mindmapDataToShare: action.payload,
      };
    }

    case Actions.SET_LIST_MINDMAP_SHARE_SHOW_TYPE: {
      return {
        ...state,
        listMindmapShareShowType: action.payload,
      };
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: "common",
  storage,
  blacklist: ["mindmapDataToShare"],
};

export default persistReducer(persistConfig, CommonReducer);
