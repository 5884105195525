import { select, call, put, takeLatest } from "redux-saga/effects";
import { actions, types } from "./reducer";
import * as service from "./service";
import { getCodeLanguage } from "../common/selectors";

const getListMessage = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setListMessage(payload));
    } else {
      yield put(actions.setListMessage([]));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setListMessage([]));
  }
};

const getListRoom = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setListRoom(payload));
    } else {
      yield put(actions.setListRoom([]));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setListRoom([]));
  }
};

const getIsTyping = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setIsTyping(payload));
    } else {
      yield put(actions.setIsTyping({}));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setIsTyping({}));
  }
};

const getCurrentRoom = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setCurrentRoom(payload));
    } else {
      yield put(actions.setCurrentRoom({}));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setCurrentRoom({}));
  }
};

const getHasNewMessage = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setHasNewMessage(payload));
    } else {
      yield put(actions.setHasNewMessage(false));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setHasNewMessage(false));
  }
};

const getIsShowChat = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setIsShowChat(payload));
    } else {
      yield put(actions.setIsShowChat(false));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setIsShowChat(false));
  }
};

const getPinMessage = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setPinMessage(payload));
    } else {
      yield put(actions.setPinMessage(null));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setPinMessage(null));
  }
};

const getIsCloseChatFrame = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setIsCloseChatFrame(payload));
    } else {
      yield put(actions.setIsCloseChatFrame(false));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setIsCloseChatFrame(false));
  }
};

const getMessageEmoji = function* ({ payload }) {
  try {
    if (payload) {
      yield put(actions.setMessageEmoji(payload));
    } else {
      yield put(actions.setMessageEmoji(null));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.setMessageEmoji(null));
  }
};

export default function* () {
  yield takeLatest(types.GET_LIST_MESSAGE, getListMessage);
  yield takeLatest(types.GET_LIST_ROOM, getListRoom);
  yield takeLatest(types.GET_IS_TYPING, getIsTyping);
  yield takeLatest(types.GET_CURRENT_ROOM, getCurrentRoom);
  yield takeLatest(types.GET_HAS_NEW_MESSAGE, getHasNewMessage);
  yield takeLatest(types.GET_IS_SHOW_CHAT, getIsShowChat);
  yield takeLatest(types.GET_PIN_MESSAGE, getPinMessage);
  yield takeLatest(types.GET_IS_CLOSE_CHAT_FRAME, getIsCloseChatFrame);
  yield takeLatest(types.GET_MESSAGE_EMOJI, getMessageEmoji);
}
