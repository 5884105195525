import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initSagas from "./Store/initSagas";
import initReducer from "./Store/initReducer";

export default {
  name: "Authenticate",
  dir: "Authenticate",
  pathRoot: "",
  routes: [
    // {
    //   url: "register",
    //   component: "Page/Register",
    //   layout: "VerticalLayoutLandingPage",
    //   meta: {
    //     authRoute: true,
    //   },
    //   props: {
    //     title: "Đăng ký | vMindmap Tâm Trí Lực",
    //     titleI18n: "Auth:title_register",
    //     exact: true,
    //   },
    // },
    {
      url: "forgot-password",
      component: "Page/FogotPassword",
      layout: "VerticalLayoutLandingPage",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Quên mật khẩu | Buzan Mindmap",
        titleI18n: "Auth:title",
        exact: true,
      },
    },

    {
      url: "*",
      component: "Page/index",
      layout: "VerticalLayoutLandingPage",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đăng nhập | Buzan Mindmap",
        titleI18n: "Auth:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: false,
  sagas: initSagas,
  redux: initReducer,
};
