import API from "../../configs/api";
import apiMethodMessage from "@utility/ApiMethodMessage";

export const getMoreMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_GET_MORE_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const sendMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_SEND_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const updateReadFlag = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_UPDATE_READ_FLAG, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const getListRoom = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_GET_LIST_ROOM, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const createRoom = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_CREATE_ROOM, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const searchUser = ({ payload, token }) => {
  return apiMethodMessage.get(API.GET_SEARCH_USER + payload.search, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const createGroup = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_CREATE_GROUP, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const addMemberGroup = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_ADD_MEMBER_GROUP, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const removeMemberGroup = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_REMOVE_MEMBER_GROUP, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const outGroup = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_OUT_GROUP, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const typing = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_TYPING, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const pinMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_PIN_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const unpinMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_UNPIN_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const reactMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_REACT_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const unreactMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_UNREACT_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};

export const getListReactMessage = ({ payload, token }) => {
  return apiMethodMessage.post(API.POST_GET_LIST_REACT_MESSAGE, payload, {
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    // },
  });
};
export const postSearchMessage = (payload) => {
  return apiMethodMessage.post(API.POST_SEARCH_MESSAGE, payload);
};
export const postSearchRoom = (payload) => {
  return apiMethodMessage.post(API.POST_SEARCH_ROOM, payload);
};
export const getRoomByMindmapID = (payload) => {
  return apiMethodMessage.get(API.GET_ROOM_BY_MINDMAP + "/" + payload);
};
