import { createSelector } from "reselect";

export const common = (state) => state.common;

export const getCodeLanguage = createSelector(common, (data) => {
  return data.codeLanguage || "vi-VN";
});

export const getLanguage = createSelector(common, (data) => {
  return data.language || "vi";
});

export const getShareInfoSelector = createSelector(common, (data) => {
  return data.shareInfo || {};
});

export const getLeftPanelFolderSelector = createSelector(common, (data) => {
  return data.category;
});

export const getCommonLoading = createSelector(common, (data) => {
  return data.loading || false;
});
export const getSettingAppSelector = createSelector(common, (data) => {
  return data.settingApp || {};
});
export const getPreviousShowExpiriedTime = createSelector(common, (data) => {
  return data.previousShowExpiriedTime || null;
});
export const getMobilePanelToShow = createSelector(common, (data) => {
  return data.mobilePanelToShow || null;
});

export const getMsgNotiSelector = createSelector(common, (data) => {
  return data.msgNoti || false;
});
export const getDefaultVideoQualitySelector = createSelector(common, (data) => {
  return data.defaultVideoQuality || null;
});
export const getMindmapDataToShareSelector = createSelector(common, (data) => {
  return data.mindmapDataToShare || null;
});

export const getListMindmapShareShowTypeSelector = createSelector(
  common,
  (data) => {
    return data.listMindmapShareShowType || null;
  }
);
