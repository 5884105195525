import API from "@Modules/UpgradeAccount/configs/api";
import apiMethod from "@utility/ApiMethod";
import axios from "axios";
const apiUrl = "https://apidev.stnhd.com/api";

export const getLevelList = async ({ codeLanguage = "vi-VN", payload }) => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.LEVEL_LIST}`, {
      params: {
        ...payload,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUpgradeAccountListByLevel = async ({
  codeLanguage = "vi-VN",
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.TEACHER_LIST_BY_LEVEL}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUpgradeAccountListBySearch = async ({
  codeLanguage = "vi-VN",
  payload,
}) => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.TEACHER_LIST_BY_SEARCH}`,
      {
        params: {
          ...payload,
        },
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListProduct = async ({ codeLanguage = "vi-VN" }) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}/${codeLanguage}/${API.GET_LIST_PRODUCT}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
