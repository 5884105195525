import mindmapCollectionSagas from "./mindmapCollections/sagas";
import pictureCollectionsSagas from "./pictureCollections/sagas";
import autoLayoutSagas from "./autoLayout/sagas";
import notesSagas from "./notes/sagas";
import patternsSagas from "./patterns/sagas";

export default [
  mindmapCollectionSagas(),
  pictureCollectionsSagas(),
  autoLayoutSagas(),
  notesSagas(),
  patternsSagas(),
];
