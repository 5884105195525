import { createAction, handleActions } from "redux-actions"
import { generateListRedux } from "@utility/StoreMethod"
import _ from "lodash"
const name = "Notes"
const reduxGenerate = generateListRedux(name)

export const types = {
  ...reduxGenerate.types,
  PARSE_LIST_NOTES_TO_OBJECT: "PARSE_LIST_NOTES_TO_OBJECT",
  SET_NOTES_DATA_CONTENT: "SET_NOTES_DATA_CONTENT",
  DELETE_NOTES: "DELETE_NOTES",
  SET_NOTE_TO_DELETE: "SET_NOTE_TO_DELETE"
}

export const actions = {
  ...reduxGenerate.actions,
  parseListNotesToObject: createAction(types.PARSE_LIST_NOTES_TO_OBJECT),
  setNoteContent: createAction(types.SET_NOTES_DATA_CONTENT),
  deleteNotes: createAction(types.DELETE_NOTES),
  setNoteToDelete: createAction(types.SET_NOTE_TO_DELETE)
}

const defaultState = {
  ...reduxGenerate.defaultState,
  listObject: {},
  noteToDelete: null
}

export default handleActions(
  {
    ...reduxGenerate.handleActions,
    [types.PARSE_LIST_NOTES_TO_OBJECT]: (state, { payload }) => {
      return {
        ...state,
        listObject: payload
      }
    },
    [types.SET_NOTES_DATA_CONTENT]: (state, { payload }) => {
      return {
        ...state,
        listObject: {
          ...state.listObject,
          ...payload
        }
      }
    },
    [types.DELETE_NOTES]: (state, { payload }) => {
      let newNotes = _.cloneDeep(state.listObject)
      payload?.forEach((ele) => {
        delete newNotes[ele]
      })
      return {
        ...state,
        listObject: newNotes
      }
    },
    [types.SET_NOTE_TO_DELETE]: (state, { payload }) => {
      return {
        ...state,
        noteToDelete: payload
      }
    }
  },
  defaultState
)
