import React from "react";
import { ENV_ENVIRONMENT_VERSION_CODE } from "@configs/environment";
import { logout } from "@src/Modules/Authenticate/helpers";

const useNewVersion = () => {
  React.useEffect(() => {
    const versionCode = localStorage.getItem("VERSION");
    if (!versionCode || versionCode !== ENV_ENVIRONMENT_VERSION_CODE) {
      Object.entries(localStorage).forEach(([key, val]) => {
        if (!key.includes("persist:common") && !key.includes("VERSION")) {
          localStorage.removeItem(key);
        }
      });
      localStorage.setItem("VERSION", ENV_ENVIRONMENT_VERSION_CODE);
      window.location.reload();
    }
  }, []);
};
export default useNewVersion;
