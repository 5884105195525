const namespace = "Home";
import {
  ChattingShapeNode,
  CloudShapeNode,
  CloudSmoothShapeNode,
  CurlyBracketShapeNode,
  DiscShapeNode,
  MathBracketShapeNode,
} from "../assets/svg/nodeShape";
import Stickers from "../assets/svg/sticker";

//API STATUS
export const SUCCESS = 200;
export const BAD_REQUEST = 400;
export const SESSION_EXPIRED = 401;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;
export const POST_SUCCESS = 201;
export const DELETE_SUCCESS = 204;
export const RETCODE_SUCCESS = 0;

// PARAM QUERY
export const PAGE_DEFAULT = 1;
export const LIMIT_DEFAULT = 12;

// PARAM QUERY

// ROUTES
export const ROUTES = {
  PRODUCT: "PRODUCT",
  PRODUCT_DETAIL: "PRODUCT_DETAIL",
  ORDER: "ORDER",
  SELECT_BRANCH: "SELECT_BRANCH",
};

//ACTION SHEET
export const CANCEL_INDEX = 0;
export const PICK_IMAGE_OPTIONS = [
  "Huỷ",
  "Chọn từ bộ sưu tập ảnh",
  "Chụp hình",
];

// export const PAYMENT_STEP_LIST = [
//   {
//     title: "order",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryOrderDetails.name
//   },
//   {
//     title: "payment_method",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryPaymentMethod.name
//   },
//   {
//     title: "payment_finish",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryPaymentFinish.name
//   }
// ]

export const CLASS = [
  { name: "Lớp mầm", code: "1" },
  { name: "Lớp chồi", code: "2" },
  { name: "Lớp lá", code: "3" },
];
export const CLOTHES_SIZE = [
  { name: "S", code: "1" },
  { name: "M", code: "2" },
  { name: "L", code: "3" },
  { name: "XL", code: "4" },
  { name: "XXL", code: "5" },
  { name: "XXL", code: "6" },
];
export const GENDER = [
  { name: "Nam", code: "1" },
  { name: "Nữ", code: "2" },
];

export const HOT_LINE = "0939 279 868";
export const WEBSITE = "https://sieutrinhohocduong.com";
export const PRIVACY_POLICY = "https://sieutrinhohocduong.com/Policy/privacy";
export const NEWS_EVENT = "https://tamtriluc.com/category/tin-su-kiens/";

export const VIDEO_ALLOW = "Allow";
export const VIDEO_DENIED = "AccessDenied";
export const ORDER_CODE = "OTHER";

export const TRIAL_CODE = "TRIAL";
export const OTP_ERROR_CODE = 7;

export const SCREEN_TYPES = {
  JOURNEY_365: "JOURNEY_365",
  DRAW_SKETCH_NOTE: "DRAW_SKETCH_NOTE",
  JOURNEY_TO_CONQUER_STAR: "JOURNEY_TO_CONQUER_STAR",
};

export const BANNER_APP_LINK = {
  MuaTaiKhoan: "/pricing",
  DangKyTraiNghiemKhoaHoc: "/accounts/active_free_account/0",
  DangKyHocSTNHD: "/signup",
  KichHoatTaiKhoan: "/accounts/active/0",
  VaoTrangEbook: "/Book",
  VaoTrangVideoKTGN: "/memorization-video",
  VaoTrangVideoBGSK: "/lectures/1",
  VaoTrangMindmap: "/lectures/2",
  VaoTrangDienDan: "/forum",
};
export const TEXT_ALIGN_MODE = {
  CENTER: "CENTER",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};
export const TEXT_ALIGN_VERTICAL_MODE = {
  TOP: "TOP",
  MIDDLE: "MIDDLE",
  BOTTOM: "BOTTOM",
};
export const LINE_SHAPES = {
  TAPERED_LINE: "tapered-line-shape",
  DOT_LINE: "dot-line-shape",
  DASH_LINE: "dash-line-shape",
  STRAIGHT_LINE: "straight-line-shape",
  STRAIGHT_LINE_DOT: "straight-dot-line-shape",
  ARROW_LINE: "arrow-line-shape",
  ARROW_LINE_TWO_WAY: "arrow-line-two-way-shape",
};
export const EDGE_COLORS = [
  "#A300FF",
  "#0038FF",
  "#33B7FF",
  "#27A600",
  "#9CEF00",
  "#F5008D",
  "#F20513",
  "#F97D00",
  "#FCC500",
  "#FFF800",
  "#8137FA",
  "#8E5EFB",
  "#B697FC",
];
export const EDGE_COLORS_RANDOM = [
  "#27A600",
  "#F97D00",
  "#33B7FF",
  "#A300FF",
  "#FCC500",
  "#0038FF",
  "#F20513",
  "#8137FA",
  "#9CEF00",
  "#F5008D",
  "#A27AFC",
  "#8E5EFB",
  "#B697FC",
  "#B697FC",
];
export const NODE_SHAPE = {
  DISC_NODE: "disc-node",
  CLOUD_SMOOTH_NODE: "cloud-smooth-node",
  CLOUD_NODE: "cloud-node",
  CHATTING_CLOUD: "chatting-cloud",
  MATH_BRACKET_NODE: "math-bracket-node",
  CURLY_BRACKET_NODE: "curly-bracket-node",
};
export const CRUD_MODES = {
  COPY: "COPY",
  CUT: "CUT",
};
export const BACKGROUND_COLORS = [
  "#FFFFFF",
  "#FFF6DD",
  "#F0FAEA",
  "#EAF8FF",
  "#FFEAEB",
  "#F6F6F6",
];
export const KEYMAP = {
  SAVE: ["ctrl+s", "command+s"],
  UNDO: ["ctrl+z", "command+z"],
  REDO: ["ctrl+shift+z", "command+shift+z"],
  COPY: ["ctrl+c", "command+c"],
  CUT: ["ctrl+x", "command+x"],
  PASTE: ["ctrl+v", "command+v"],
  CREATE_NODE: ["enter"],
  CREATE_SUB_NODE: ["tab"],
  DELETE: ["Delete", "Backspace", "delete", "backspace", "Del", "del"],
  UP: ["up"],
  DOWN: ["down"],
  LEFT: ["left"],
  RIGHT: ["right"],
  SORT_UP: ["ctrl+up", "command+up"],
  SORT_DOWN: ["ctrl+down", "command+down"],
  RECORD: ["f9"],
  PAUSE_RECORD: ["f10"],
  PRESENT: ["f5"],
};
export const PAGE_SIZE = {
  A4: "A4",
  A3: "A3",
};
export const PAGE_SIZE_DIM = {
  A4: {
    width: 29.7,
    height: 21,
    rootMaxPercentWidth: 0.15,
  },
  A3: {
    width: 42,
    height: 29.7,
    rootMaxPercentWidth: 0.25,
  },
};
export const FONT_FAMILY = [
  "Roboto",
  "Arial, sans-serif",
  "Times New Roman, serif",
  "American Typewriter, serif",
  "Andale Mono, monospace",
  "Courier New, monospace",
  "DejaVu Sans Mono, monospace",
  "Comic Sans MS, Comic Sans, cursive",
  "Apple Chancery, cursive",
  "Bradley Hand, cursive",
  "Brush Script MT, Brush Script Std, cursive",
  "Snell Roundhand, cursive",
  "URW Chancery L, cursive",
  "Impact, fantasy",
  "Luminari, fantasy",
  "Chalkduster, fantasy",
  "Stencil Std, fantasy",
  "Marker Felt, fantasy",
  "Trattatello, fantasy",
];
export const LIST_SHAPE = [
  {
    name: "disc-node",
    component: DiscShapeNode,
  },
  {
    name: "cloud-smooth-node",
    component: CloudSmoothShapeNode,
  },
  {
    name: "cloud-node",
    component: CloudShapeNode,
  },
  {
    name: "chatting-cloud",
    component: ChattingShapeNode,
  },
  {
    name: "math-bracket-node",
    component: MathBracketShapeNode,
  },
  {
    name: "curly-bracket-node",
    component: CurlyBracketShapeNode,
  },
];

export const LIST_STICKER = (() =>
  Object.keys(Stickers).map((item) => ({
    name: item,
    component: Stickers[item],
  })))();
export const MIN_ZOOM = 0.1;
export const MAX_ZOOM = 2;

export const MIN_TRANSFORM_DISTANCE = 150;
export const CENTER_IMAGE_CATEGORY_ID = 1;
export const BRANCH_IMAGE_CATEGORY_ID = 2;
export const DEFAULT_BRANCH_THICHNESS = 20;
export const DEFAULT_IMAGE_NODE_HEIGHT = 100;
export const DEFAULT_IMAGE_NODE_WIDTH = 150;
export const MINDMAP_TYPE = {
  ORGANIC: "Organic",
  SPEED_MAP: "Speed Map",
};
export const IMAGE_RESIZE_MODE = {
  FIT_TO_SHAPE: "Fit To Shape",
  FILL_TO_SHAPE: "Fill Shape",
  STRETCH: "Stretch",
};
export const IMAGE_POSITION = {
  COVER: "Cover",
  LEFT: "Left",
  RIGHT: "Right",
  TOP: "Top",
  BOTTOM: "Bottom",
};
export const IMAGE_SIZE = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
  GIANT: "Giant",
};
export const BOX_SHAPE_GENERATOR = {
  BOX_PATH: "generateBoxPath",
  ROUNDED_PATH: "generateRoundedPath",
  BULGE_PATH: "generateBulgePath",
  DISC_PATH: "generateDiscPath",
  TILTED_PATH: "generateTiltedPath",
  BRACKETS_PATH: "generateBracketsPath",
  CURLY_BRACKETS_PATH: "generateCurlyBracketsPath",
  MATH_BRACKETS_PATH: "generateMathBracketsPath",
  PARALLELOGRAM_PATH: "generateParallelogramPath",
  FLAG_PATH: "generateFlagPath",
  OVAL_PATH: "generateOvalPath",
  CLOUD_PATH: "generateCloudPath",
  SPEECH_PATH: "generateSpeechPath",
  STADIUM_PATH: "generateStadiumPath",
};
export const LIST_BOX_SHAPE = [
  {
    key: "BOX_PATH",
    func: "generateBoxPath",
  },
  {
    key: "ROUNDED_PATH",
    func: "generateRoundedPath",
  },
  {
    key: "BULGE_PATH",
    func: "generateBulgePath",
  },
  {
    key: "DISC_PATH",
    func: "generateDiscPath",
  },
  {
    key: "TILTED_PATH",
    func: "generateTiltedPath",
  },
  {
    key: "BRACKETS_PATH",
    func: "generateBracketsPath",
  },
  {
    key: "CURLY_BRACKETS_PATH",
    func: "generateCurlyBracketsPath",
  },
  {
    key: "MATH_BRACKETS_PATH",
    func: "generateMathBracketsPath",
  },
  {
    key: "PARALLELOGRAM_PATH",
    func: "generateParallelogramPath",
  },
  {
    key: "FLAG_PATH",
    func: "generateFlagPath",
  },
  {
    key: "OVAL_PATH",
    func: "generateOvalPath",
  },
  {
    key: "CLOUD_PATH",
    func: "generateCloudPath",
  },
  {
    key: "SPEECH_PATH",
    func: "generateSpeechPath",
  },
  {
    key: "STADIUM_PATH",
    func: "generateStadiumPath",
  },
];
export const NAVIGATION_KEY_NAME = {
  UP: "UP",
  DOWN: "DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};
export const SORT_DIRECTION = {
  UP: "UP",
  DOWN: "DOWN",
};
export const HEADER_HEIGHT = 50;
export const MOUSE_COLORS = [
  "#F24E54",
  "#B80000",
  "#008B02",
  "#ff0b9c",
  "#ba00ff",
  "#084fff",
  "#fc2a1a",
  "#5300EB",
  "#EB144C",
  "#9900EF",
  "#FF6900",
  "#006B76",
];
export const MINDMAP_ACCESS_ROLE_TYPE = {
  PUBLIC_VIEW: "PUBLIC_VIEW",
  PUBLIC_EDIT: "PUBLIC_EDIT",
  PRIVATE: "PRIVATE",
};
export const START_RECORD_TYPE_SCREEN = {
  statusHandle: "START",
  video: false,
  screen: true,
  audio: true,
  downloadRecordingPath: "Screen_Recording",
  downloadRecordingType: "mp4",
};
export const START_RECORD_TYPE_AUDIO = {
  statusHandle: "START",
  video: false,
  screen: false,
  audio: true,
  downloadRecordingPath: "Audio_Recording",
  downloadRecordingType: "mp3",
};
export const RECORDING_TYPE = {
  AUDIO: "AUDIO",
  SCREEN: "SCREEN",
};
