export default [
  //Dynamic module
  { key: "Chat" },
  { key: "Folder" },
  { key: "Mine" },
  { key: "Mindmap" },
  { key: "MindmapPublic" },
  { key: "Notification" },
  { key: "Pricing" },
  { key: "Payment" },
  { key: "Share" },
  { key: "UpgradeAccount" },
  { key: "Pattern" },
  { key: "Profile" },
  { key: "Template" },
  { key: "MindmapThumbnail" },
  //Static module
  { key: "ComingSoon" },
  { key: "Authenticate" },
  { key: "Home" },
  { key: "Favorite" },
  { key: "MyDelete" },
  { key: "Groups" },
];
