import { createAction, handleActions } from "redux-actions";
import { generateListReduxWithoutPaging } from "@utility/StoreMethod";
const name = "Collections";
const reduxGenerate = generateListReduxWithoutPaging(name);

export const types = {
  ...reduxGenerate.types,
  DELETE_MINDMAP: "DELETE_MINDMAP",
  DUPLICATE_MINDMAP: "DUPLICATE_MINDMAP",
  UPDATE_LIST_MINDMAP: "UPDATE_LIST_MINDMAP",
};

export const actions = {
  ...reduxGenerate.actions,
  deleteMindmap: createAction(types.DELETE_MINDMAP),
  duplicateMindmap: createAction(types.DUPLICATE_MINDMAP),
  updateListMindMap: createAction(types.UPDATE_LIST_MINDMAP),
};

const defaultState = {
  ...reduxGenerate.defaultState,
};

export default handleActions(
  {
    ...reduxGenerate.handleActions,
    [types.DELETE_MINDMAP]: (state, { payload }) => {
      let ids = payload;
      let newList = [];
      if (ids?.length && typeof ids === "object") {
        newList = state?.list?.filter((item) => {
          let idx = ids?.findIndex((ele) => ele === item.id);
          return idx === -1;
        });
      } else {
        newList = state?.list?.filter((item) => item.id !== ids);
      }

      return {
        ...state,
        list: newList,
      };
    },

    [types.DUPLICATE_MINDMAP]: (state, { payload }) => {
      return {
        ...state,
        list: [...state.list, payload],
      };
    },

    [types.UPDATE_LIST_MINDMAP]: (state, { payload }) => {
      return {
        ...state,
        list: payload,
      };
    },
  },
  defaultState
);
