import API from "@configs/api";
import apiMethod from "@utility/ApiMethod";

export const getListService = ({ payload }) => {
  return apiMethod.post(
    API.GET_LIST_IMAGE + `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};
export const postUploadImage = (payload) => {
  return apiMethod.post(API.POST_UPLOAD_IMAGE, payload);
};
export const getListImageCategory = (payload) => {
  return apiMethod.post(API.GET_LIST_IMAGE_CATEGORY, payload);
};

export const putUpdateImage = (payload) => {
  return apiMethod.put(API.PUT_UPDATE_IMAGE, payload);
};

export const deleteImage = (payload) => {
  return apiMethod.delete(API.DELETE_IMAGE, {
    data: payload,
  });
};
