import API from "@configs/api";
import apiMethod from "@utility/ApiMethod";

export const getListService = ({ payload }) => {
  const { page, limit, userId, ...rest } = payload;
  return apiMethod.post(
    `${API.GET_LIST_MINDMAP_FILTERS}?limit=${limit}&page=${page}`,
    {
      userId: userId,
    }
  );
};

export const postCreateMindmap = (payload) => {
  return apiMethod.post(API.CREATE_MINDMAP, payload);
};

export const postCreateMindmapInfo = (payload) => {
  return apiMethod.post(API.CREATE_MINDMAP_INFO, payload);
};

export const putUpdateMindmap = (payload) => {
  return apiMethod.put(API.PUT_UPDATE_MINDMAP, payload);
};

export const putUpdateMindmapInfo = (payload) => {
  return apiMethod.put(API.UPDATE_MINDMAP_INFO, payload);
};

export const getMindmapInfo = (payload) => {
  return apiMethod.get(API.GET_DETAIL_MINDMAP_INFO + "/" + payload?.id);
};

export const getMindmapDetail = (payload) => {
  if (!payload?.userId && payload?.userId !== 0) {
    return;
  }
  return apiMethod.get(
    API.GET_DETAIL_MINDMAP + "/" + payload?.id + `?userId=${payload?.userId}`
  );
};

export const postDeleteMindmap = (payload) => {
  return apiMethod.delete(API.DELETE_MINDMAP, {
    data: {
      ...payload,
    },
  });
};

export const getListMindmapCategory = (payload) => {
  return apiMethod.post(API.GET_LIST_MINDMAP_CATEGORY, payload);
};

export const postCreateMindmapCategory = (payload) => {
  return apiMethod.post(API.POST_CREATE_MINDMAP_CATEGORY, payload);
};

export const postFilterListMindmap = (payload) => {
  return apiMethod.post(API.POST_FILTER_MINDMAP, payload);
};

export const updateMindmapCategory = (payload) => {
  return apiMethod.put(API.UPDATE_CATEGORY_INFO + `/${payload?.id}`, payload);
};

export const deleteMindmapCategory = (payload) => {
  return apiMethod.delete(API.DELETE_MINDMAP_CATEGORY, {
    data: payload,
  });
};
