import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

export default {
  name: "Chat",
  dir: "Chat",
  pathRoot: "Chat",
  routes: [
    {
      url: "",
      component: "Page/Chat",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Trò chuyện",
        titleI18n: "Chat:titleTab",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
