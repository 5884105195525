import hightlightElements from "./hightlightElements/reducer";
import elementInteraction from "./elementInteraction/reducer";
import elementHistory from "./elementHistory/reducer";
import elements from "./elements/reducer";
import mouseUsers from "./mouseUsers/reducer";
import mindmap from "./mindmap/reducer";
import autoLayout from "./autoLayout/reducer";
import collections from "./collections/reducer";
import mindmapCollections from "./mindmapCollections/reducer";
import pictureCollections from "./pictureCollections/reducer";
import notes from "./notes/reducer";
import comment from "./comment/reducer";
import present from "./present/reducer";
import realtimeInteraction from "./realtimeInteraction/reducer";
import pattern from "./patterns/reducer";

export default {
  HightlightElements: hightlightElements,
  ElementsInteraction: elementInteraction,
  ElementsHistory: elementHistory,
  Elements: elements,
  MouseUsers: mouseUsers,
  MindMap: mindmap,
  AutoLayout: autoLayout,
  MindMapCollections: collections,
  Collections: mindmapCollections,
  PictureCollections: pictureCollections,
  Notes: notes,
  Comments: comment,
  Present: present,
  RealtimeInteraction: realtimeInteraction,
  MindmapPatterns: pattern,
};
