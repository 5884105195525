import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import common from "./Store/common/reducer";

export default {
  name: "Home",
  dir: "Home",
  pathRoot: "",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "LeftPanelLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Buzan Mindmap | Trang chủ",
        titleI18n: "Home:title",
        exact: true,
      },
    },
    {
      url: "search",
      component: "Page/SearchPage",
      layout: "LeftPanelLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Buzan Mindmap | Trang chủ",
        titleI18n: "Home:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: {
    HomeCommon: common,
  },
};
