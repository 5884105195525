import { createAction, handleActions } from "redux-actions";

export const types = {
  SET_DRAGGING_ELEMENT: "SET_DRAGGING_ELEMENT",
  SET_ROTATING_ELEMENT: "SET_ROTATING_ELEMENT",
  SET_ROOT_ACTIVE: "SET_ROOT_ACTIVE",
  SET_EDGE_EDITABLE: "SET_EDGE_EDITABLE",
  SET_CREATE_NODE_MODE: "SET_CREATE_NODE_MODE",
  SET_JUST_CREATED_NODE: "SET_JUST_CREATED_NODE",
  SET_IS_SAVE_ELEMENT_FLAG: "SET_IS_SAVE_ELEMENT_FLAG",
  SET_IS_COPY_ELEMENT_FLAG: "SET_IS_COPY_ELEMENT_FLAG",
  SET_IS_DELETE_ELEMENT_FLAG: "SET_IS_DELETE_ELEMENT_FLAG",
  SET_IS_PASTE_ELEMENT_FLAG: "SET_IS_PASTE_ELEMENT_FLAG",
  SET_ELEMENT_TO_SELECT: "SET_ELEMENT_TO_SELECT",
  SET_EDGE_TO_MERGE: "SET_EDGE_TO_MERGE",
  SET_CREATE_EDGE_TYPE: "SET_CREATE_EDGE_TYPE",
  SET_TEMP_SOURCE_NODE: "SET_TEMP_SOURCE_NODE",
  SET_IS_CUT_ELEMENT_FLAG: "SET_IS_CUT_ELEMENT_FLAG",
  SET_NODE_WILL_HIDE_CHILD: "SET_NODE_WILL_HIDE_CHILD",
  SET_NODE_WILL_SHOW_CHILD: "SET_NODE_WILL_SHOW_CHILD",
  SET_SHOW_NOTES: "SET_SHOW_NOTES",
  SET_SHOW_CREATE_TEMPLATE_MODAL: "SET_SHOW_CREATE_TEMPLATE_MODAL",
  CLEAR_INTERACTION_REDUX: "CLEAR_INTERACTION_REDUX",
  SET_SHOW_COMMENT: "SET_SHOW_COMMENT",
  SET_NAVIGATION_KEY: "SET_NAVIGATION_KEY",
  SET_SORT_DIRECTION: "SET_SORT_DIRECTION",
  SET_START_DRAG: "SET_START_DRAG",
  SET_CLEAN_UP_MINDMAP: "SET_CLEAN_UP_MINDMAP",
  SET_DISABLE_NODE_ENTER: "SET_DISABLE_NODE_ENTER",
  SET_SHOW_MODAL_ADD_FILE: "SET_SHOW_MODAL_ADD_FILE",
  SET_SHOW_MODAL_LIST_FILE: "SET_SHOW_MODAL_LIST_FILE",
  SET_IS_SHOW_IMAGE_EDITOR_EMOJI: "SET_IS_SHOW_IMAGE_EDITOR_EMOJI",
  SET_IS_SHOW_EDITOR_EMOJI: "SET_IS_SHOW_EDITOR_EMOJI",
  SET_IS_SHOW_EDGE_EDITOR: "SET_IS_SHOW_EDGE_EDITOR",
  SET_DATA_RECORD_AUDIO: "SET_DATA_RECORD_AUDIO",
};

export const actions = {
  setDraggingElement: createAction(types.SET_DRAGGING_ELEMENT),
  setRotatingElement: createAction(types.SET_ROTATING_ELEMENT),
  setRootActive: createAction(types.SET_ROOT_ACTIVE),
  setEdgeEditable: createAction(types.SET_EDGE_EDITABLE),
  setCreateNodeMode: createAction(types.SET_CREATE_NODE_MODE),
  setJustCreatedNode: createAction(types.SET_JUST_CREATED_NODE),
  setIsSaveElementFlag: createAction(types.SET_IS_SAVE_ELEMENT_FLAG),
  setIsCopyElementFlag: createAction(types.SET_IS_COPY_ELEMENT_FLAG),
  setIsDeleteElementFlag: createAction(types.SET_IS_DELETE_ELEMENT_FLAG),
  setIsPasteElementFlag: createAction(types.SET_IS_PASTE_ELEMENT_FLAG),
  setElementToSelect: createAction(types.SET_ELEMENT_TO_SELECT),
  setEdgeToMerge: createAction(types.SET_EDGE_TO_MERGE),
  setCreateEdgeType: createAction(types.SET_CREATE_EDGE_TYPE),
  setTempSourceNode: createAction(types.SET_TEMP_SOURCE_NODE),
  setIsCutElementFlag: createAction(types.SET_IS_CUT_ELEMENT_FLAG),
  setNodeWillHideChild: createAction(types.SET_NODE_WILL_HIDE_CHILD),
  setNodeWillShowChild: createAction(types.SET_NODE_WILL_SHOW_CHILD),
  setShowNotes: createAction(types.SET_SHOW_NOTES),
  setShowCreateTemplateModal: createAction(
    types.SET_SHOW_CREATE_TEMPLATE_MODAL
  ),
  clearInteractionRedux: createAction(types.CLEAR_INTERACTION_REDUX),
  setShowComment: createAction(types.SET_SHOW_COMMENT),
  setNavigationKey: createAction(types.SET_NAVIGATION_KEY),
  setSortDirection: createAction(types.SET_SORT_DIRECTION),
  setStartDrag: createAction(types.SET_START_DRAG),
  setCleanupMindmap: createAction(types.SET_CLEAN_UP_MINDMAP),
  setDisableNodeEnter: createAction(types.SET_DISABLE_NODE_ENTER),
  setShowModalAddFile: createAction(types.SET_SHOW_MODAL_ADD_FILE),
  setShowModalListFile: createAction(types.SET_SHOW_MODAL_LIST_FILE),
  setIsShowImageEditorEmoji: createAction(types.SET_IS_SHOW_IMAGE_EDITOR_EMOJI),
  setIsShowEditorEmoji: createAction(types.SET_IS_SHOW_EDITOR_EMOJI),
  setIsShowEdgeEditor: createAction(types.SET_IS_SHOW_EDGE_EDITOR),
  setDataRecordAudio: createAction(types.SET_DATA_RECORD_AUDIO),
};

const defaultState = {
  draggingELement: null,
  rotatingElement: null,
  rootActive: false,
  edgeEditable: "",
  mode: "",
  justCreatedNode: null,
  isSaveFlag: false,
  isDeleteFlag: false,
  isCopyFlag: false,
  isPasteFlag: false,
  isCutFlag: false,
  elementToSelect: null,
  edgeToMerge: null,
  createEdgeType: null,
  tempSourceNode: null,
  nodeWillHideChild: null,
  nodeWillShowChild: null,
  showNotes: false,
  showCreateTemplateModal: false,
  showComment: false,
  navigationKey: null,
  sortDirection: null,
  isStartDrag: false,
  isCleanupMindmap: false,
  disableNodeEnter: false,
  showModalAddFile: false,
  showModalListFile: false,
  isShowImageEditorEmoji: false,
  isShowEditorEmoji: false,
  isShowEdgeEditor: false,
  recordAudioData: {
    isShowModalAudioRecord:false,
    isShowModalListAudioFile:false,
  },
};

export default handleActions(
  {
    [types.SET_DRAGGING_ELEMENT]: (state, { payload }) => {
      return {
        ...state,
        draggingELement: payload,
      };
    },
    [types.SET_ROTATING_ELEMENT]: (state, { payload }) => {
      return {
        ...state,
        rotatingElement: payload,
      };
    },
    [types.SET_ROOT_ACTIVE]: (state, { payload }) => {
      return {
        ...state,
        rootActive: payload,
      };
    },
    [types.SET_EDGE_EDITABLE]: (state, { payload }) => {
      return {
        ...state,
        edgeEditable: payload,
      };
    },
    [types.SET_CREATE_NODE_MODE]: (state, { payload }) => {
      return {
        ...state,
        mode: payload,
      };
    },
    [types.SET_JUST_CREATED_NODE]: (state, { payload }) => {
      return {
        ...state,
        justCreatedNode: payload,
      };
    },
    [types.SET_IS_SAVE_ELEMENT_FLAG]: (state, { payload }) => {
      return {
        ...state,
        isSaveFlag: payload,
      };
    },
    [types.SET_IS_COPY_ELEMENT_FLAG]: (state, { payload }) => {
      return {
        ...state,
        isCopyFlag: payload,
      };
    },
    [types.SET_IS_DELETE_ELEMENT_FLAG]: (state, { payload }) => {
      return {
        ...state,
        isDeleteFlag: payload,
      };
    },
    [types.SET_IS_PASTE_ELEMENT_FLAG]: (state, { payload }) => {
      return {
        ...state,
        isPasteFlag: payload,
      };
    },
    [types.SET_ELEMENT_TO_SELECT]: (state, { payload }) => {
      return {
        ...state,
        elementToSelect: payload,
      };
    },
    [types.SET_EDGE_TO_MERGE]: (state, { payload }) => {
      return {
        ...state,
        edgeToMerge: payload,
      };
    },
    [types.SET_CREATE_EDGE_TYPE]: (state, { payload }) => {
      return {
        ...state,
        createEdgeType: payload,
      };
    },
    [types.SET_TEMP_SOURCE_NODE]: (state, { payload }) => {
      return {
        ...state,
        tempSourceNode: payload,
      };
    },
    [types.SET_IS_CUT_ELEMENT_FLAG]: (state, { payload }) => {
      return {
        ...state,
        isCutFlag: payload,
      };
    },
    [types.SET_NODE_WILL_HIDE_CHILD]: (state, { payload }) => {
      return {
        ...state,
        nodeWillHideChild: payload,
      };
    },
    [types.SET_NODE_WILL_SHOW_CHILD]: (state, { payload }) => {
      return {
        ...state,
        nodeWillShowChild: payload,
      };
    },
    [types.SET_SHOW_NOTES]: (state, { payload }) => {
      return {
        ...state,
        showNotes: payload,
      };
    },
    [types.SET_SHOW_CREATE_TEMPLATE_MODAL]: (state, { payload }) => {
      return {
        ...state,
        showCreateTemplateModal: payload,
      };
    },
    [types.CLEAR_INTERACTION_REDUX]: (state, { payload }) => {
      return defaultState;
    },
    [types.SET_SHOW_COMMENT]: (state, { payload }) => {
      return {
        ...state,
        showComment: payload,
      };
    },
    [types.SET_NAVIGATION_KEY]: (state, { payload }) => {
      return {
        ...state,
        navigationKey: payload,
      };
    },
    [types.SET_SORT_DIRECTION]: (state, { payload }) => {
      return {
        ...state,
        sortDirection: payload,
      };
    },
    [types.SET_START_DRAG]: (state, { payload }) => {
      return {
        ...state,
        isStartDrag: payload,
      };
    },
    [types.SET_CLEAN_UP_MINDMAP]: (state, { payload }) => {
      return {
        ...state,
        isCleanupMindmap: payload,
      };
    },
    [types.SET_DISABLE_NODE_ENTER]: (state, { payload }) => {
      return {
        ...state,
        disableNodeEnter: payload,
      };
    },
    [types.SET_SHOW_MODAL_ADD_FILE]: (state, { payload }) => {
      return {
        ...state,
        showModalAddFile: payload,
      };
    },
    [types.SET_SHOW_MODAL_LIST_FILE]: (state, { payload }) => {
      return {
        ...state,
        showModalListFile: payload,
      };
    },
    [types.SET_IS_SHOW_IMAGE_EDITOR_EMOJI]: (state, { payload }) => {
      return {
        ...state,
        isShowImageEditorEmoji: payload,
      };
    },
    [types.SET_IS_SHOW_EDITOR_EMOJI]: (state, { payload }) => {
      return {
        ...state,
        isShowEditorEmoji: payload,
      };
    },
    [types.SET_IS_SHOW_EDGE_EDITOR]: (state, { payload }) => {
      return {
        ...state,
        isShowEdgeEditor: payload,
      };
    },
    [types.SET_DATA_RECORD_AUDIO]: (state, { payload }) => {
      return {
        ...state,
        recordAudioData: payload,
      };
    },
  },
  defaultState
);
