import API from "@Modules/Notification/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getNotificationList = async (payload) => {
  try {
    const { data } = await apiMethod.get(`${API.NOTIFICATION_LIST}`, {
      params: {
        ...payload,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDetailNotification = async (id) => {
  try {
    const { data } = await apiMethod.get(
      `${API.NOTIFICATION_DETAIL}/${id}`,
      {}
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateNotification = async (payload) => {
  try {
    const { id, ...rest } = payload;
    const { data } = await apiMethod.put(
      `${API.NOTIFICATION_UPDATE}/${id}`,
      rest
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const countNotification = async (userid) => {
  try {
    const { data } = await apiMethod.get(`${API.NOTIFICATION_COUNT}`, {
      params: { userid },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const markReadAllNotification = async (userId) => {
  try {
    const { data } = await apiMethod.put(
      `${API.NOTIFICATION_MARK_READ_ALL}/${userId}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
