import { createAction, handleActions } from "redux-actions"
import { generateListReduxWithoutPaging } from "@utility/StoreMethod"
const name = "PictureCollections"
const reduxGenerate = generateListReduxWithoutPaging(name)

export const types = {
  ...reduxGenerate.types
}

export const actions = {
  ...reduxGenerate.actions
}

const defaultState = {
  ...reduxGenerate.defaultState
}

export default handleActions(
  {
    ...reduxGenerate.handleActions
  },
  defaultState
)
