export const SET_LANGUAGE = "common/SET_LANGUAGE";
export const GET_DISTRICT = "common/GET_DISTRICT";
export const GET_PROVICE = "common/GET_PROVICE";
export const GET_SHARE_INFO_REQUEST = "GET_SHARE_INFO_REQUEST";
export const GET_SHARE_INFO_SUCCESS = "GET_SHARE_INFO_SUCCESS";
export const GET_SHARE_INFO_FAILED = "GET_SHARE_INFO_FAILED";

export const GET_LEFT_PANEL_FOLDER_REQUEST = "GET_LEFT_PANEL_FOLDER_REQUEST";
export const GET_LEFT_PANEL_FOLDER_SUCCESS = "GET_LEFT_PANEL_FOLDER_SUCCESS";
export const GET_LEFT_PANEL_FOLDER_FAILED = "GET_LEFT_PANEL_FOLDER_FAILED";
export const REFRESH_LEFT_PANEL_FOLDER_REQUEST =
  "REFRESH_LEFT_PANEL_FOLDER_REQUEST";
export const REFRESH_LEFT_PANEL_FOLDER_SUCCESS =
  "REFRESH_LEFT_PANEL_FOLDER_SUCCESS";
export const REFRESH_LEFT_PANEL_FOLDER_FAILED =
  "REFRESH_LEFT_PANEL_FOLDER_FAILED";

export const SET_PREVIOUS_SHOW_EXPIRIED_REMAINING_POPUP_TIME =
  "SET_PREVIOUS_SHOW_EXPIRIED_REMAINING_POPUP_TIME";

export const SET_SETTING_APP = "SET_SETTING_APP";
export const SET_MOBILE_PANEL_TO_SHOW = "SET_MOBILE_PANEL_TO_SHOW";
export const API_KEY_PAYMENT =
  "VMindmap2022FE1D9FE1-1F07-4364-A65F-D8FB23C8C82E";
export const SET_MSG_NOTI = "SET_MSG_NOTI";
export const SET_DEFAULT_VIDEO_QUALITY = "SET_DEFAULT_VIDEO_QUALITY";
export const SET_MINDMAP_DATA_TO_SHARE = "SET_MINDMAP_DATA_TO_SHARE";
export const SET_LIST_MINDMAP_SHARE_SHOW_TYPE =
  "SET_LIST_MINDMAP_SHARE_SHOW_TYPE";
