import { isNode } from "../../../../components/ReactFlow/dist/ReactFlow.esm";
import { createAction, handleActions } from "redux-actions";

export const types = {
  ADD_HIGHTLIGHT_ELEMENTS: "ADD_HIGHTLIGHT_ELEMENTS",
  REMOVE_HIGHTLIGHT_ELEMENTS: "REMOVE_HIGHTLIGHT_ELEMENTS",
  CLEAR_HIGHTLIGHT_ELEMENTS: "CLEAR_HIGHTLIGHT_ELEMENTS",
};

export const actions = {
  addHightlightElements: createAction(types.ADD_HIGHTLIGHT_ELEMENTS),
  removeHightlightElements: createAction(types.REMOVE_HIGHTLIGHT_ELEMENTS),
  clearHightlightElements: createAction(types.CLEAR_HIGHTLIGHT_ELEMENTS),
};

const defaultState = {
  hightlightElements: [],
  hightlightElementsObject: {},
};

export default handleActions(
  {
    [types.ADD_HIGHTLIGHT_ELEMENTS]: (state, { payload }) => {
      let newElements = [...state?.hightlightElements];
      let newHightlightElementsObject = { ...state.hightlightElementsObject };
      let idx = state?.hightlightElements?.findIndex(
        (item) => item?.id === payload?.id
      );
      if (idx === -1) {
        newElements.push({ ...payload });
        newHightlightElementsObject[payload?.id] = payload;
      }
      return {
        ...state,
        hightlightElements: newElements,
        hightlightElementsObject: newHightlightElementsObject,
      };
    },

    [types.REMOVE_HIGHTLIGHT_ELEMENTS]: (state, { payload }) => {
      let newElements = [...state?.hightlightElements];
      let newHightlightElementsObject = { ...state.hightlightElementsObject };
      if (isNode(payload || {})) {
        let idxEdge = state?.hightlightElements?.findIndex(
          (item) => item?.target === payload?.id
        );
        let edgeId = state?.hightlightElements[idxEdge]?.id;
        newElements.splice(idxEdge, 1);
        let idxNode = state?.hightlightElements?.findIndex(
          (item) => item?.id === payload?.id
        );
        newElements.splice(idxNode, 1);
        delete newHightlightElementsObject[edgeId];
        delete newHightlightElementsObject[payload?.id];
      } else {
        let idxNode = state?.hightlightElements?.findIndex(
          (item) => item?.id === payload?.target
        );
        let idNode = state?.hightlightElements[idxNode]?.id;
        newElements.splice(idxNode, 1);
        let idxEdge = state?.hightlightElements?.findIndex(
          (item) => item?.id === payload?.id
        );
        newElements.splice(idxEdge, 1);
        delete newHightlightElementsObject[idNode];
        delete newHightlightElementsObject[payload?.id];
      }
      return {
        ...state,
        hightlightElements: newElements,
        hightlightElementsObject: newHightlightElementsObject,
      };
    },
    [types.CLEAR_HIGHTLIGHT_ELEMENTS]: (state, { payload }) => {
      return {
        ...state,
        hightlightElements: [],
        hightlightElementsObject: {},
      };
    },
  },
  defaultState
);
