import dagre from "dagre";
import { DEFAULT_BOXNODE_DIM } from "../../../configs/contants";
import { findAllChildNodeV2, prepareData } from "./autoLayoutV2";
import { flatternArray } from "./flowUtils";
import MindmapLayouts from "mindmap-layouts";
const LEFT_DIRECTION = "RL";
const RIGHT_DIRECTION = "LR";

export const createTreeDataStructure = (
  curNode,
  elementsObject = {},
  isExport = false
) => {
  if (!curNode) {
    return null;
  }
  let nextChildEdge = elementsObject?.[`SOURCE_${curNode?.id}`];
  if (!nextChildEdge || !nextChildEdge?.length) {
    return {
      ...curNode,
      children: [],
    };
  }
  let allChildNode = nextChildEdge?.map((ele) => elementsObject[ele.target]);
  if (!allChildNode || !allChildNode?.length) {
    return {
      ...curNode,
      children: [],
    };
  }
  if (!allChildNode || !allChildNode.length) {
    return {
      ...curNode,
      children: [],
    };
  }
  return {
    ...curNode,
    children: allChildNode
      .map((child) => createTreeDataStructure(child, elementsObject))
      ?.filter((ele) => ele)
      .sort((a, b) => {
        if (isExport) {
          if (a?.data?.isLeft && b?.data?.isLeft) {
            return b.position.y - a.position.y;
          }
          if (!a?.data?.isLeft && !b?.data?.isLeft) {
            return a.position.y - b.position.y;
          }
          if (a?.data?.isLeft && !b?.data?.isLeft) {
            return 1;
          }
          return -1;
        }
        return a.position.y - b.position.y;
      }),
  };
};
const flatternTreeData = (node) => {
  return flatternArray([
    ...node?.children?.map((n) => flatternTreeData(n)),
    {
      ...node,
      children: null,
    },
  ]);
};

const getNodeWithPositionDargeLayout = (
  nodes = [],
  edges = [],
  direction,
  removeRoot = false,
  rootPos = { x: 0, y: 0 },
  inpNodeSep = 0
) => {
  if (!nodes || !nodes.length) {
    return [];
  }
  const g = new dagre.graphlib.Graph()
    .setGraph({
      rankdir: direction,
      nodesep: inpNodeSep || 20,
      ranksep: 140,
      edgesep: 20,
    })
    .setDefaultEdgeLabel(() => ({}));

  nodes.forEach((item) => {
    g.setNode(item.id, {
      width: item?.__rf?.width || DEFAULT_BOXNODE_DIM.width,
      height: item?.__rf?.height || DEFAULT_BOXNODE_DIM.height,
    });
    let edgeIndex = edges.findIndex((ele) => ele.target === item.id);
    if (edgeIndex !== -1) {
      g.setEdge(edges[edgeIndex].source, edges[edgeIndex].target);
    }
  });
  dagre.layout(g);

  let root = g.node("root");

  return nodes
    .map((item) => {
      if (removeRoot) {
        if (item.id === "root") {
          return null;
        }
        return {
          ...item,
          position: {
            x:
              g.node(item.id).x -
              g.node(item.id).width * (direction === LEFT_DIRECTION ? 1 : 0) +
              rootPos.x -
              root.x,
            y:
              g.node(item.id).y -
              g.node(item.id).height / 2 +
              (item.id !== "root" ? rootPos.y : 0) -
              root.y,
          },
          __rf: {
            ...item?.__rf,
            isDragging: false,
          },
        };
      } else {
        return {
          ...item,
          position: {
            x: g.node(item.id).x + (item.id !== "root" ? rootPos.x : 0),
            y: g.node(item.id).y + (item.id !== "root" ? rootPos.y : 0),
          },
          __rf: {
            ...item?.__rf,
            isDragging: false,
          },
        };
      }
    })
    ?.filter((item) => item);
};

const getNodeWithPositionXMindLayout = (nodes = [], edges = [], direction) => {
  const elementsObject = prepareData(nodes, edges);
  let rootPos = elementsObject["root"].__rf.position;
  let rootNode = elementsObject["root"];
  let dataTree = createTreeDataStructure(rootNode, elementsObject);
  const layout = new MindmapLayouts[
    direction === LEFT_DIRECTION ? "LeftLogical" : "RightLogical"
  ](dataTree, {
    getHeight(node) {
      return node?.__rf?.height || node?.style?.height;
    },
    getWidth(node) {
      return node?.__rf?.width || node?.style?.width;
    },
    getHGap(node) {
      if (node.id === "root") {
        return 100;
      }
      return 40;
    },
    getVGap() {
      return 7;
    },
  });
  let data = layout.doLayout();
  let dataFlattern = flatternTreeData(data);
  let layoutedNodesObject = {};
  dataFlattern.forEach((node) => (layoutedNodesObject[node.id] = node));
  return nodes
    .map((node) => {
      let lX = layoutedNodesObject[node.id].x;
      let lY = layoutedNodesObject[node.id].y;
      if (node.id === "root") {
        return null;
      }
      return {
        ...node,
        position: {
          x:
            direction === RIGHT_DIRECTION
              ? rootPos.x + lX
              : lX + rootPos.x - layoutedNodesObject["root"].x - 100,
          y: rootPos.y + lY - layoutedNodesObject["root"].y,
        },
        __rf: {
          ...node.__rf,
          position: {
            x:
              direction === RIGHT_DIRECTION
                ? rootPos.x + lX
                : lX + rootPos.x - layoutedNodesObject["root"].x - 100,
            y: rootPos.y + lY - layoutedNodesObject["root"].y,
          },
        },
      };
    })
    ?.filter((item) => item);
};

export const sortNodesByLevelAndYPosition = (nodes = []) => {
  if (!nodes || !nodes.length) {
    return [];
  }
  return nodes.sort((a, b) => {
    if (a?.data?.level !== b?.data?.level) {
      let lvA = a?.data?.level || 0;
      let lvB = b?.data?.level || 0;
      return lvA - lvB;
    }
    return a.position.y - b.position.y;
  });
};
const getElementsInSpeedLayoutingArea = (
  nodePush,
  edges = [],
  nodes = [],
  elementsObject
) => {
  let rootNode,
    rootPos,
    isLeft,
    nodesResults,
    edgesResults,
    parentNodePushId,
    parentNodePush;

  parentNodePushId = elementsObject[`NODE_TRAVEL_${nodePush.id}`]?.find(
    (ele) => ele.road?.source === "root"
  )?.road?.target;
  if (!parentNodePushId) {
    return {
      nodesResults: {},
      edgesResults: {},
      isLeft: false,
    };
  }

  parentNodePush = elementsObject[parentNodePushId];
  rootNode = elementsObject["root"];
  rootPos = {
    x: rootNode.__rf.position.x + (rootNode.__rf.width || 0) / 2,
    y: rootNode.__rf.position.y + (rootNode.__rf.height || 0) / 2,
  };
  isLeft = parentNodePush.position.x <= rootPos.x;
  if (isLeft) {
    nodesResults = {};
    nodes.forEach((node) => {
      if (!node?.id?.includes("invisible")) {
        if (node.__rf.position.x < rootPos.x && node.id !== "root") {
          let allChildNode = findAllChildNodeV2(node, elementsObject, true);

          allChildNode.forEach((item) => {
            if (!item?.id?.includes("invisible")) {
              nodesResults[item.id] = {
                ...item,
                data: {
                  ...item.data,
                  isLeft: true,
                },
              };
            }
          });
          nodesResults[node.id] = {
            ...node,
            data: {
              ...node.data,
              isLeft: true,
            },
          };
        } else if (node.id === "root") {
          nodesResults["root"] = node;
        }
      }
    });
  } else {
    nodesResults = {};
    nodes.forEach((node) => {
      if (!node?.id?.includes("invisible")) {
        if (node.__rf.position.x >= rootPos.x && node.id !== "root") {
          let allChildNode = findAllChildNodeV2(node, elementsObject, true);
          allChildNode.forEach((item) => {
            if (!item?.id?.includes("invisible")) {
              nodesResults[item.id] = {
                ...item,
                data: {
                  ...item.data,
                  isLeft: false,
                },
              };
            }
          });
          nodesResults[node.id] = {
            ...node,
            data: {
              ...node.data,
              isLeft: false,
            },
          };
        } else if (node.id === "root") {
          nodesResults["root"] = node;
        }
      }
    });
  }

  edgesResults = edges.filter(
    (ele) =>
      Object.values(nodesResults || {}).findIndex(
        (node) =>
          node.id === ele.target ||
          (node.id === ele.source && node.id !== "root")
      ) !== -1
  );
  return {
    nodesResults: Object.values(nodesResults || {}),
    edgesResults,
    isLeft,
  };
};

export const autoSpeedLayoutEachSide = (
  nodeChange,
  listNode = [],
  listEdge = []
) => {
  let listImgNode =
    listNode?.filter((node) => node.id?.includes("image")) || [];
  let listImgEdges =
    listEdge?.filter((edge) => edge.id?.includes("image")) || [];
  const elementsObject = prepareData(
    listNode?.filter((node) => !node.id?.includes("image")),
    listEdge?.filter((edge) => !edge.id?.includes("image"))
  );
  let rootNode = elementsObject["root"];
  let rootPos = {
    x: rootNode?.position?.x + (rootNode?.__rf?.width || 0) / 2,
    y: rootNode?.position?.y + (rootNode?.__rf?.height || 0) / 2,
  };
  const { nodesResults, edgesResults, isLeft } =
    getElementsInSpeedLayoutingArea(
      nodeChange,
      listEdge?.filter((edge) => !edge.id?.includes("image")),
      listNode?.filter((node) => !node.id?.includes("image")),
      elementsObject
    );

  let listNodeHaveNewPosition = getNodeWithPositionXMindLayout(
    sortNodesByLevelAndYPosition(nodesResults),
    edgesResults,
    isLeft ? LEFT_DIRECTION : RIGHT_DIRECTION,
    true,
    rootPos
  );
  let edgeWithOldPosition = edgesResults.map((item) => {
    let previousEdgePosition = {
      sourceX: 0,
      sourceY: 0,
      targetX: 0,
      targetY: 0,
      controlPoints: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
      ],
    };

    const sNode = elementsObject[item.source];
    const tNode = elementsObject[item.target];
    let sx = sNode.position.x;
    let tx = tNode.position.x;
    if (sx < tx) {
      previousEdgePosition.sourceX = sNode.position.x + (sNode.__rf.width || 0);
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
      previousEdgePosition.targetX = tNode.position.x;
      previousEdgePosition.targetY =
        tNode.position.y + (tNode.__rf?.height || 0) / 2;
    } else {
      previousEdgePosition.sourceX = sNode.position.x;
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
      previousEdgePosition.targetX = tNode.position.x + (tNode.__rf.width || 0);
      previousEdgePosition.targetY =
        tNode.position.y + (tNode.__rf?.height || 0) / 2;
    }
    if (sNode.id === "root") {
      previousEdgePosition.sourceX =
        sNode.position.x +
        (sNode.position.x < tNode.position.x ? sNode.__rf?.width : 0);
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
    }
    return {
      ...item,
      data: {
        ...item?.data,
        previousEdgePosition,
      },
    };
  });
  return {
    nodes: [...listNodeHaveNewPosition, ...listImgNode, rootNode],
    edges: edgeWithOldPosition,
  };
};

export const autoSpeedLayout = (
  nodes = [],
  edges = [],
  inpNodeSep = 0,
  allowImageNode = false
) => {
  const eleObj = prepareData(nodes, edges);
  const elementsObject = _.cloneDeep(eleObj);
  let centerPos = { x: 0, y: 0 };
  let leftChild = [],
    rightChild = [];

  let rootNode = elementsObject["root"];
  if (rootNode) {
    centerPos.y = rootNode?.position?.y;
    centerPos.x = rootNode?.position?.x;
  }
  let rootPos = {
    x: rootNode?.position?.x + (rootNode?.__rf?.width || 0) / 2,
    y: rootNode?.position?.y + (rootNode?.__rf?.height || 0) / 2,
  };
  nodes.map((item) => {
    if (item.id === "root") {
      leftChild.push(item);
      rightChild.push(item);
      return item;
    }
    if (
      !(
        item.id?.includes("invisible") ||
        (!allowImageNode && item.id?.includes("image"))
      )
    ) {
      let rootNodeTravel = elementsObject[`NODE_TRAVEL_${item.id}`].find(
        (ele) => ele.road.source === "root"
      );
      let parentNode = elementsObject[rootNodeTravel?.road?.target];
      if (item.position.x < centerPos.x) {
        if (parentNode.id !== "root") {
          if (parentNode.position.x < centerPos.x) {
            leftChild.push({
              ...item,
              data: {
                ...item.data,
                isLeft: true,
              },
            });
          } else {
            rightChild.push({
              ...item,
              data: {
                ...item.data,
                isLeft: false,
              },
            });
          }
        } else {
          leftChild.push({
            ...item,
            data: {
              ...item.data,
              isLeft: true,
            },
            position: {
              ...item.position,
              x: centerPos.x - 100,
            },
          });
        }
      } else {
        if (parentNode.id !== "root") {
          if (parentNode.position.x < centerPos.x) {
            leftChild.push({
              ...item,
              data: {
                ...item.data,
                isLeft: true,
              },
            });
          } else {
            rightChild.push({
              ...item,
              data: {
                ...item.data,
                isLeft: false,
              },
            });
          }
        } else {
          rightChild.push({
            ...item,
            data: {
              ...item.data,
              isLeft: false,
            },
          });
        }
      }
    }
    return item;
  });
  let leftNodeWidthPos = getNodeWithPositionXMindLayout(
    sortNodesByLevelAndYPosition(leftChild),
    edges,
    LEFT_DIRECTION,
    true,
    rootPos,
    inpNodeSep
  );
  let rightNodeWidthPos = getNodeWithPositionXMindLayout(
    sortNodesByLevelAndYPosition(rightChild),
    edges,
    RIGHT_DIRECTION,
    true,
    rootPos,
    inpNodeSep
  );
  let edgeWithOldPosition = edges.map((item) => {
    if (item?.id?.includes("image")) {
      return item;
    }
    let previousEdgePosition = {
      sourceX: 0,
      sourceY: 0,
      targetX: 0,
      targetY: 0,
      controlPoints: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
      ],
    };
    const sNode = nodes.find((ele) => ele.id === item.source);
    const tNode = nodes.find((ele) => ele.id === item.target);
    if (!sNode || !tNode) {
      return item;
    }

    let sx = sNode.position.x;
    let tx = tNode.position.x;
    if (sx < tx) {
      previousEdgePosition.sourceX = sNode.position.x + (sNode.__rf.width || 0);
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
      previousEdgePosition.targetX = tNode.position.x;
      previousEdgePosition.targetY =
        tNode.position.y + (tNode.__rf?.height || 0) / 2;
    } else {
      previousEdgePosition.sourceX = sNode.position.x;
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
      previousEdgePosition.targetX = tNode.position.x + (tNode.__rf.width || 0);
      previousEdgePosition.targetY =
        tNode.position.y + (tNode.__rf?.height || 0) / 2;
    }
    if (sNode.id === "root") {
      previousEdgePosition.sourceX =
        sNode.position.x +
        (sNode.position.x < tNode.position.x ? sNode.__rf?.width : 0);
      previousEdgePosition.sourceY =
        sNode.position.y + (sNode.__rf?.height || 0) / 2;
    }

    return {
      ...item,
      data: {
        ...item?.data,
        previousEdgePosition,
      },
    };
  });

  return {
    nodes: [...leftNodeWidthPos, ...rightNodeWidthPos, rootNode],
    edges: edgeWithOldPosition,
  };
};
export const insertOldEdgeData = (edges = []) => {
  return edges.map((item) => {
    return {
      ...item,
      data: {
        ...item.data,
        previousEdgePosition: {
          ...item.data,
        },
      },
    };
  });
};
