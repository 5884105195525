import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

export default {
  name: "Share",
  dir: "Share",
  pathRoot: "shared-with-me",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "LeftPanelLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chia sẻ với tôi | Buzan Mindmap",
        titleI18n: "Share:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
