let ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
  "https://daotao-apivmindmap.stnhd.com/api";

let ENV_ENVIRONMENT_MONGO_URL_API_CONFIG = "http://192.168.1.9:3700/api";

let PIXABAY_API_URL = "https://pixabay.com/api/";
let PIXABAY_API_KEY = "25191544-c3d061a239a902c0c428cc1ad";
let VERSION_CODE = "1.0.0";

let ENV_ENVIRONMENT_awsKeys = {
  accessKeyID: "mC0h9xAJAjbBIcOgUc2F",
  secretKeyID: "lypBWLSauv592PKUgjB49j9a765wzyN0Vu5TqvFw",
  endpoint: "https://s3.5ptb.com",
  bucket: "mindmap",
};

let PrefixChanelRoom = "DEV";

switch (process.env.NODE_ENV) {
  case "testing":
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://daotao-apivmindmap.stnhd.com/api";
    ENV_ENVIRONMENT_MONGO_URL_API_CONFIG = "http://192.168.1.9:3700/api";
    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "mC0h9xAJAjbBIcOgUc2F",
      secretKeyID: "lypBWLSauv592PKUgjB49j9a765wzyN0Vu5TqvFw",
      endpoint: "https://s3.5ptb.com",
      bucket: "mindmap",
    };
    PrefixChanelRoom = "TEST";
    break;
  case "production":
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://daotao-apivmindmap.stnhd.com/api";
    ENV_ENVIRONMENT_MONGO_URL_API_CONFIG = "http://192.168.1.9:3700/api";
    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "mC0h9xAJAjbBIcOgUc2F",
      secretKeyID: "lypBWLSauv592PKUgjB49j9a765wzyN0Vu5TqvFw",
      endpoint: "https://s3.5ptb.com",
      bucket: "mindmap",
    };

    PrefixChanelRoom = "PRODUCTION";
    break;
  default:
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://daotao-apivmindmap.stnhd.com/api";
    ENV_ENVIRONMENT_MONGO_URL_API_CONFIG = "http://192.168.1.9:3700/api";
    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "mC0h9xAJAjbBIcOgUc2F",
      secretKeyID: "lypBWLSauv592PKUgjB49j9a765wzyN0Vu5TqvFw",
      endpoint: "https://s3.5ptb.com",
      bucket: "mindmap",
    };

    PrefixChanelRoom = "DEV";
    break;
}

export const ENV_ENVIRONMENT_BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API_CONFIG;
export const ENV_ENVIRONMENT_PIXABAY_API = PIXABAY_API_URL;
export const ENV_ENVIRONMENT_PIXABAY_API_KEY = PIXABAY_API_KEY;
export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;
export const ENV_ENVIRONMENT_VERSION_CODE = VERSION_CODE;
export const ENVIRONMENT_STNHD_BASE_URL_API = "https://apidev.stnhd.com/api";
export const PREVENT_LOGIN_PRODUCTION = false;
export const ENV_ENVIRONMENT_SOCKET_URL =
  "https://socket.vmindmap.com.vn/socket";
// export const ENV_ENVIRONMENT_SOCKET_URL =
//   "http://localhost:3500/"
export const ENV_PREFIX_SOCKET = PrefixChanelRoom;
export const KEY_MAP = "AIzaSyAP2EDHv1qETgUfjOys1ov_PlAAUt-H05Y";
export const CHAT_SECRET_KEY = "SUNSHINE";
export const ENV_ENVIRONMENT_MONGO_API_URL =
  ENV_ENVIRONMENT_MONGO_URL_API_CONFIG;
export const ENV_ENVIRONMENT_BASE_SOCKET_URL =
  "https://ttlsocketchat.sunshine.software";
// export const ENV_ENVIRONMENT_BASE_SOCKET_URL = "http://192.168.1.15:3600"
export const ENV_SOCKET_BASE_URL = "https://socket.vmindmap.com.vn";
export const ENV_SOCKET_PATH = "/socket/socket.io";
export const ENV_MM_THUMBNAIL_SSR = "https://thumbnail-vmm.5ptb.com";
export const ENV_VMM_AGENCY_API =
  "https://staging_apiagency.vmindmap.com.vn/api";

export const ENV_VMM_FIRE_BASE_CONFIG = {
  apiKey: "AIzaSyBLHUn_Q1PHZc8WsKuc9y45Bz5_iT--2ao",
  authDomain: "tamtriluc-76b54.firebaseapp.com",
  databaseURL:
    "https://tamtriluc-76b54-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tamtriluc-76b54",
  storageBucket: "tamtriluc-76b54.appspot.com",
  messagingSenderId: "465366344602",
  appId: "1:465366344602:web:c83ab3e6f4217ee2d6d023",
  measurementId: "G-Y2EFTL9G4H",
};
export const WEBSITE_URL = "https://app-vmindmap.sunshine.software/";
