import { isNode } from "../../../../components/ReactFlow/dist/ReactFlow.esm"
import { createAction, handleActions } from "redux-actions"
import equal from "fast-deep-equal"

export const types = {
  SET_ELEMENTS: "SET_ELEMENTS",
  SAVE_ELEMENTS: "SAVE_ELEMENTS",
  UNDO_ELEMENTS: "UNDO_ELEMENTS",
  REDO_ELEMENTS: "REDO_ELEMENTS",
  SET_UNDO_REDO_MODE: "SET_UNDO_REDO_MODE",
  CLEAR_ELEMENTS: "CLEAR_ELEMENTS",
  SET_EDGES_DEPTH: "SET_EDGE_DEPTH"
}

export const actions = {
  setElements: createAction(types.SET_ELEMENTS),
  saveElements: createAction(types.SAVE_ELEMENTS),
  undo: createAction(types.UNDO_ELEMENTS),
  redo: createAction(types.REDO_ELEMENTS),
  setUndoRedoMode: createAction(types.SET_UNDO_REDO_MODE),
  clearElements: createAction(types.CLEAR_ELEMENTS),
  setEdgesDepth: createAction(types.SET_EDGES_DEPTH)
}

const defaultState = {
  elements: null,
  elementsObject: null,
  elementHistory: [],
  elementFuture: [],
  currentIndex: -1,
  currentPosition: null,
  currentZoom: 0,
  canRedo: false,
  canUndo: false,
  currentPageSize: "Free Size",
  UDRDMode: null,
  mapType: "",
  edgesDepth: null
}

export default handleActions(
  {
    [types.SET_ELEMENTS]: (state, { payload }) => {
      let elementsNew = []
      let elementsNewObject = {}
      payload.map((item) => {
        elementsNewObject[item?.id] = JSON.parse(JSON.stringify({ ...item }))
        if (item?.id !== "root") {
          let newObj = JSON.parse(JSON.stringify({ ...item }))
          delete newObj.__rf
          elementsNew.push(newObj)
        } else {
          elementsNew.push(item)
        }
      })
      return {
        ...state,
        elements: elementsNew,
        elementsObject: elementsNewObject
      }
    },
    [types.SAVE_ELEMENTS]: (state, { payload }) => {
      if (
        equal(payload, state.elementHistory[state.elementHistory.length - 1])
      ) {
        return {
          ...state
        }
      }
      let history = [...state.elementHistory, payload]
      return {
        ...state,
        elements: payload?.elements,
        elementHistory: history,
        canRedo: false,
        canUndo: history.length > 1,
        currentIndex: 1,
        elementFuture: [],
        currentPageSize: payload?.pageSize,
        mapType: payload?.mapType
      }
    },
    [types.UNDO_ELEMENTS]: (state, { payload }) => {
      if (!state.canUndo) {
        return { ...state }
      }
      let newHistory = [...state.elementHistory]
      let previous = JSON.parse(
        JSON.stringify(newHistory[newHistory.length - 1])
      )
      if (
        !state.canRedo &&
        equal(newHistory[newHistory.length - 1].elements, state.elements)
      ) {
        previous = JSON.parse(JSON.stringify(newHistory[newHistory.length - 2]))
      }
      if (
        !state.canRedo &&
        equal(newHistory[newHistory.length - 1].elements, state.elements)
      ) {
        newHistory = newHistory.slice(0, newHistory.length - 2)
      } else {
        newHistory = newHistory.slice(0, newHistory.length - 1)
      }
      let flow = previous
      const [x = 0, y = 0] = flow?.position || []

      if (flow.elements.length) {
        return {
          ...state,
          elementHistory: newHistory,
          elementFuture: [
            {
              elements: state.elements,
              position: state.currentPosition,
              zoom: state.currentZoom,
              pageSize: state.currentPageSize,
              mapType: state?.mapType
            },
            ...state.elementFuture
          ],
          elements: flow.elements,
          canRedo: true,
          canUndo: newHistory.length >= 1,
          currentIndex: 1,
          currentPosition: [x, y],
          currentZoom: flow?.zoom || 0,
          pageSize: flow.pageSize || "Free Size",
          UDRDMode: "UNDO",
          mapType: flow?.mapType
        }
      }
      return {
        ...state
      }
    },
    [types.REDO_ELEMENTS]: (state, { payload }) => {
      if (!state.canRedo) {
        return { ...state }
      }
      const next = state.elementFuture[0]
      const newFuture = state.elementFuture.slice(1)
      let flow = next
      const [x = 0, y = 0] = flow?.position ? flow?.position : [0, 0]
      return {
        ...state,
        elements: next.elements,
        elementFuture: newFuture,
        elementHistory: [
          ...state.elementHistory,
          {
            elements: state.elements,
            position: state.currentPosition,
            zoom: state.currentZoom,
            pageSize: state.currentPageSize,
            mapType: state?.mapType
          }
        ],
        canRedo: newFuture.length >= 1,
        canUndo: true,
        currentIndex: 1,
        currentPosition: [x, y],
        currentZoom: flow?.zoom || 0,
        pageSize: flow.pageSize || "Free Size",
        UDRDMode: "REDO",
        mapType: flow?.mapType
      }
    },
    [types.SET_UNDO_REDO_MODE]: (state, { payload }) => {
      return {
        ...state,
        UDRDMode: payload
      }
    },
    [types.CLEAR_ELEMENTS]: (state, { payload }) => {
      return defaultState
    },
    [types.SET_EDGES_DEPTH]: (state, { payload }) => {
      return {
        ...state,
        edgesDepth: payload
      }
    }
  },
  defaultState
)
