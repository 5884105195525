import {
  DEFAULT_BRANCH_THICHNESS,
  MINDMAP_TYPE,
} from "../../configs/constants";

import { createAction, handleActions } from "redux-actions";

export const types = {
  SET_BACKGROUND: "SET_BACKGROUND",
  SET_DARKMODE: "SET_DARKMODE",
  SET_EDITABLE_MODE: "SET_EDITABLE_MODE",
  SET_MINDMAP_NAME: "SET_MINDMAP_NAME",
  SET_MINDMAP_DESCRIPTION: "SET_MINDMAP_DESCRIPTION",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  SET_MINDMAP_DATA: "SET_MINDMAP_DATA",
  SET_SHOW_ADVANCE_STYLE: "SET_SHOW_ADVANCE_STYLE",
  SET_STRIGGER_ANIM_FLAG: "SET_STRIGGER_ANIM_FLAG",
  CLEAR_MINDMAP_DATA: "CLEAR_MINDMAP_DATA",
  SET_IS_PRESENT_MODE: "SET_IS_PRESENT_MODE",
  SET_DATA_RECORD_SCREEN_MODE: "SET_DATA_RECORD_SCREEN_MODE",
  SET_DATA_GUIDE_MINDMAP: "SET_DATA_GUIDE_MINDMAP",
  SET_IS_AUTO_LAYOUT: "SET_IS_AUTO_LAYOUT",
  SET_SHOW_CAMERA: "SET_SHOW_CAMERA",
  SET_SHOW_CAMERA_EDITOR: "SET_SHOW_CAMERA_EDITOR",
  SET_CAMERA_EDITOR_DATA: "SET_CAMERA_EDITOR_DATA",
  SET_LIST_FILE_WAS_UPLOAD: "SET_LIST_FILE_WAS_UPLOAD",
};

export const actions = {
  setBackground: createAction(types.SET_BACKGROUND),
  setDarkMode: createAction(types.SET_DARKMODE),
  setEditableMode: createAction(types.SET_EDITABLE_MODE),
  setMindmapName: createAction(types.SET_MINDMAP_NAME),
  setMindmapDescription: createAction(types.SET_MINDMAP_DESCRIPTION),
  setPageSize: createAction(types.SET_PAGE_SIZE),
  setMindmapData: createAction(types.SET_MINDMAP_DATA),
  setShowAdvanceStyle: createAction(types.SET_SHOW_ADVANCE_STYLE),
  setTriggerAnimStart: createAction(types.SET_STRIGGER_ANIM_FLAG),
  clearMindmapData: createAction(types.CLEAR_MINDMAP_DATA),
  setIsPresentMode: createAction(types.SET_IS_PRESENT_MODE),
  setDataRecordScreenMode: createAction(types.SET_DATA_RECORD_SCREEN_MODE),
  setDataTourGuide: createAction(types.SET_DATA_GUIDE_MINDMAP),
  setIsAutoLayout: createAction(types.SET_IS_AUTO_LAYOUT),
  setShowCamera: createAction(types.SET_SHOW_CAMERA),
  setShowCameraEditor: createAction(types.SET_SHOW_CAMERA_EDITOR),
  setCameraEditorData: createAction(types.SET_CAMERA_EDITOR_DATA),
  setListFileWasUpload: createAction(types.SET_LIST_FILE_WAS_UPLOAD),
};

const defaultState = {
  background: "#fff",
  darkmode: false,
  editable: true,
  name: "",
  description: "",
  pageSize: "Free Size",
  showAdvanceStyle: false,
  defaultFontFamily: "Arial, sans-serif",
  generalInfo: null,
  autoLayout: true,
  branchThickness: DEFAULT_BRANCH_THICHNESS,
  startAnimFlag: false,
  elementsQueueToStartAnim: {
    elements: [],
    queueLength: 0,
  },
  mindmapType: MINDMAP_TYPE.ORGANIC,
  showSpeedKey: false,
  autoFocus: false,
  isPresentMode: false,
  disablePresentButton: false,
  hideHeader: false,
  hideToolBar: false,
  creator: false,
  mindmapTourGuide: {
    run: false,
    stepIndex: 0,
    isProgress: false,
  },
  dataRecordScreenMode: {},
  recordingType: null,
  disableFocusBranch: false,
  isShowCamera: false,
  isShowCameraEditor: false,
  cameraEditorData: {},
  listFileWasUpload: {},
};

export default handleActions(
  {
    [types.SET_BACKGROUND]: (state, { payload }) => {
      return {
        ...state,
        background: payload,
      };
    },
    [types.SET_DARKMODE]: (state, { payload }) => {
      return {
        ...state,
        darkmode: payload,
      };
    },
    [types.SET_EDITABLE_MODE]: (state, { payload }) => {
      return {
        ...state,
        editable: payload,
      };
    },
    [types.SET_MINDMAP_NAME]: (state, { payload }) => {
      return {
        ...state,
        name: payload,
      };
    },
    [types.SET_MINDMAP_DESCRIPTION]: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    [types.SET_PAGE_SIZE]: (state, { payload }) => {
      return {
        ...state,
        pageSize: payload,
      };
    },
    [types.SET_MINDMAP_DATA]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [types.SET_SHOW_ADVANCE_STYLE]: (state, { payload }) => {
      return {
        ...state,
        showAdvanceStyle: payload,
      };
    },
    [types.SET_STRIGGER_ANIM_FLAG]: (state, { payload }) => {
      if (!payload?.queueLength) {
        if (state.startAnimFlag) {
          return {
            ...state,
            startAnimFlag: false,
            elementsQueueToStartAnim: { elements: [], queueLength: 0 },
          };
        }
        return state;
      } else {
        let elementQueueIdx =
          state.elementsQueueToStartAnim?.elements?.findIndex(
            (item) => item === payload.elementWillAnimatedId
          ) || -1;
        if (elementQueueIdx !== -1) {
          return state;
        } else {
          if (
            state.elementsQueueToStartAnim?.elements?.length ===
            payload?.queueLength - 1
          ) {
            return {
              ...state,
              startAnimFlag: true,
              elementsQueueToStartAnim: {
                queueLength: payload?.queueLength,
                elements: [
                  ...(state.elementsQueueToStartAnim.elements || []),
                  payload.elementWillAnimatedId,
                ],
              },
            };
          } else {
            return {
              ...state,
              elementsQueueToStartAnim: {
                queueLength: payload?.queueLength,
                elements: [
                  ...(state.elementsQueueToStartAnim.elements || []),
                  payload.elementWillAnimatedId,
                ],
              },
            };
          }
        }
      }
    },
    [types.CLEAR_MINDMAP_DATA]: (state, { payload }) => {
      return defaultState;
    },
    [types.SET_IS_PRESENT_MODE]: (state, { payload }) => {
      return {
        ...state,
        isPresentMode: payload,
      };
    },
    [types.SET_DATA_RECORD_SCREEN_MODE]: (state, { payload }) => {
      return {
        ...state,
        dataRecordScreenMode: payload,
      };
    },
    [types.SET_DATA_GUIDE_MINDMAP]: (state, { payload }) => {
      return {
        ...state,
        mindmapTourGuide: { ...state.mindmapTourGuide, ...payload },
      };
    },
    [types.SET_IS_AUTO_LAYOUT]: (state, { payload }) => {
      return {
        ...state,
        autoLayout: payload,
      };
    },
    [types.SET_SHOW_CAMERA]: (state, { payload }) => {
      return {
        ...state,
        isShowCamera: payload,
      };
    },
    [types.SET_SHOW_CAMERA_EDITOR]: (state, { payload }) => {
      return {
        ...state,
        isShowCameraEditor: payload,
      };
    },
    [types.SET_CAMERA_EDITOR_DATA]: (state, { payload }) => {
      return {
        ...state,
        cameraEditorData: payload,
      };
    },
    [types.SET_LIST_FILE_WAS_UPLOAD]: (state, { payload }) => {
      return {
        ...state,
        listFileWasUpload: payload,
      };
    },
  },
  defaultState
);
