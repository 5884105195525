import { createAction, handleActions } from "redux-actions";

export const types = {
  GET_LIST_MESSAGE: "GET_LIST_MESSAGE",
  SET_LIST_MESSAGE: "SET_LIST_MESSAGE",
  GET_LIST_ROOM: "GET_LIST_ROOM",
  SET_LIST_ROOM: "SET_LIST_ROOM",
  GET_IS_TYPING: "GET_IS_TYPING",
  SET_IS_TYPING: "SET_IS_TYPING",
  GET_CURRENT_ROOM: "GET_CURRENT_ROOM",
  SET_CURRENT_ROOM: "SET_CURRENT_ROOM",
  GET_HAS_NEW_MESSAGE: "GET_HAS_NEW_MESSAGE",
  SET_HAS_NEW_MESSAGE: "SET_HAS_NEW_MESSAGE",
  GET_IS_SHOW_CHAT: "GET_IS_SHOW_CHAT",
  SET_IS_SHOW_CHAT: "SET_IS_SHOW_CHAT",
  GET_PIN_MESSAGE: "GET_PIN_MESSAGE",
  SET_PIN_MESSAGE: "SET_PIN_MESSAGE",
  GET_IS_CLOSE_CHAT_FRAME: "GET_IS_CLOSE_CHAT_FRAME",
  SET_IS_CLOSE_CHAT_FRAME: "SET_IS_CLOSE_CHAT_FRAME",
  GET_MESSAGE_EMOJI: "GET_MESSAGE_EMOJI",
  SET_MESSAGE_EMOJI: "SET_MESSAGE_EMOJI",
  CREATE_NEW_ROOM_GROUP: "CREATE_NEW_ROOM_GROUP",
  UPDATE_ROOM_DATA: "UPDATE_ROOM_DATA",
  SET_CURRENT_ROOM_V2: "SET_CURRENT_ROOM_v2",
};

export const actions = {
  setListMessage: createAction(types.SET_LIST_MESSAGE),
  getListMessage: createAction(types.GET_LIST_MESSAGE),
  setListRoom: createAction(types.SET_LIST_ROOM),
  getListRoom: createAction(types.GET_LIST_ROOM),
  setIsTyping: createAction(types.SET_IS_TYPING),
  getIsTyping: createAction(types.GET_IS_TYPING),
  setCurrentRoom: createAction(types.SET_CURRENT_ROOM),
  getCurrentRoom: createAction(types.GET_CURRENT_ROOM),
  setHasNewMessage: createAction(types.SET_HAS_NEW_MESSAGE),
  getHasNewMessage: createAction(types.GET_HAS_NEW_MESSAGE),
  setIsShowChat: createAction(types.SET_IS_SHOW_CHAT),
  getIsShowChat: createAction(types.GET_IS_SHOW_CHAT),
  setPinMessage: createAction(types.SET_PIN_MESSAGE),
  getPinMessage: createAction(types.GET_PIN_MESSAGE),
  setIsCloseChatFrame: createAction(types.SET_IS_CLOSE_CHAT_FRAME),
  getIsCloseChatFrame: createAction(types.GET_IS_CLOSE_CHAT_FRAME),
  setMessageEmoji: createAction(types.SET_MESSAGE_EMOJI),
  getMessageEmoji: createAction(types.GET_MESSAGE_EMOJI),
  createNewRoomGroup: createAction(types.CREATE_NEW_ROOM_GROUP),
  updateRoom: createAction(types.UPDATE_ROOM_DATA),
  setCurrentRoomV2: createAction(types.SET_CURRENT_ROOM_V2),
};

const defaultState = {
  listMessage: [],
  listRoom: [],
  isTyping: {},
  currentRoom: { roomID: null },
  hasNewMessage: false,
  isShowChat: false,
  pinMessage: null,
  isCloseChatFrame: false,
  messageEmoji: null,
  currentRoomV2: null,
};

const UserReducer = handleActions(
  {
    [types.CREATE_NEW_ROOM_GROUP]: (state, { payload }) => {
      const isExistRoom = state.listMessage.some((messageItem) => {
        return messageItem.room._id === payload.room._id;
      });
      if (!isExistRoom) {
        return {
          ...state,
          listMessage: [...state.listMessage, payload],
        };
      }
      return state;
    },
    [types.SET_LIST_MESSAGE]: (state, { payload }) => {
      if (!Array.isArray(payload) && !payload?.room?.isGroup) {
        if (state.listRoom?.every((room) => room?._id !== payload?.room?._id)) {
          state.listRoom = [...state.listRoom, payload.room];
          state.listMessage = [
            ...state.listMessage,
            {
              room: payload.room,
              messages: [],
            },
          ];
        }
      }

      if (Array.isArray(payload)) {
        return { ...state, listMessage: [...payload] };
      }

      console.log(state.listMessage);

      const newListMessage = state.listMessage.map((messageItem) => {
        if (messageItem?.room?._id === payload?.room?._id) {
          if (messageItem.messages && !Array.isArray(payload?.messages)) {
            state.listRoom = state.listRoom.map((room) => {
              if (room._id === payload.room._id) {
                return {
                  ...room,
                  lastMessage: payload.message,
                };
              }

              return room;
            });

            return {
              ...messageItem,
              messages: [
                ...messageItem.messages,
                { ...payload.messages, author: payload.messages.author._id },
              ],
            };
          }
        }

        return messageItem;
      });

      return {
        ...state,
        listMessage: newListMessage,
      };
    },
    [types.SET_LIST_ROOM]: (state, { payload }) => {
      return {
        ...state,
        listRoom: [...payload],
      };
    },

    [types.SET_IS_TYPING]: (state, { payload }) => {
      return {
        ...state,
        isTyping: payload,
      };
    },

    [types.SET_CURRENT_ROOM]: (state, { payload }) => {
      return {
        ...state,
        currentRoom: payload,
      };
    },

    [types.SET_HAS_NEW_MESSAGE]: (state, { payload }) => {
      return {
        ...state,
        hasNewMessage: payload,
      };
    },

    [types.SET_IS_SHOW_CHAT]: (state, { payload }) => {
      return {
        ...state,
        isShowChat: payload,
      };
    },

    [types.SET_PIN_MESSAGE]: (state, { payload }) => {
      return {
        ...state,
        pinMessage: payload,
      };
    },

    [types.SET_IS_CLOSE_CHAT_FRAME]: (state, { payload }) => {
      return {
        ...state,
        isCloseChatFrame: payload,
      };
    },

    [types.SET_MESSAGE_EMOJI]: (state, { payload }) => {
      return {
        ...state,
        messageEmoji: payload,
      };
    },
    [types.UPDATE_ROOM_DATA]: (state, { payload }) => {
      let newRoom = payload;
      return {
        ...state,
        listRoom: state.listRoom.map((room) =>
          room?._id === newRoom?._id
            ? { ...newRoom, unreadCount: room?.unreadCount }
            : room
        ),
        currentRoomV2:
          state.currentRoomV2?._id === newRoom?._id
            ? { ...newRoom, unreadCount: state.currentRoomV2?.unreadCount }
            : state.currentRoomV2,
      };
    },
    [types.SET_CURRENT_ROOM_V2]: (state, { payload }) => {
      return {
        ...state,
        currentRoomV2: payload,
      };
    },
  },
  defaultState
);

export default UserReducer;
