import API from "../../configs/api";
import apiMethod from "../../../../utility/ApiMethod";
import { API_KEY_PAYMENT } from "@src/store/common/constants";
import { BASE_AGENCY_API_URL } from "@src/configs/api";

export const formPaymentCallMethod = ({ payload, codeLanguage }) => {
  return apiMethod.post(BASE_AGENCY_API_URL + API.INVOICE_CREATE, {
    apiKey: API_KEY_PAYMENT,
    userId: parseInt(payload.userId),
    userName: payload.userName,
    customerName: payload.customerName,
    customerEmail: payload.customerEmail,
    customerPhone: payload.customerPhone,
    description: payload.description,
    cityId: parseInt(payload.cityId),
    districtId: parseInt(payload.districtId),
    address: payload.address,
    itemCode: payload.itemCode,
    totalAmount: payload.totalAmount,
    cardList: payload.cardList,
    urlResponseOnepay: payload?.urlResponseOnepay,
    durationCode: payload?.durationCode,
    durationName: payload?.durationName,
    itemName: payload?.itemName,
    isRelative: payload?.isBuyForOthers == "true" ? true : false,
    relativeEmail: payload?.receiverEmail,
    relativePhone: payload?.receiverPhone,
    relativeFullName: payload?.receiverName,
    culture: payload?.culture || "vi-VN",
    levelCode: payload?.levelCode,
  });
};

export const formPaymentUpdateMethod = ({ payload, codeLanguage }) => {
  return apiMethod.post(BASE_AGENCY_API_URL + API.INVOICE_UPDATE, {
    apiKey: API_KEY_PAYMENT,
    code: payload.code,
    statusCode: payload.statusCode,
    reasonName: payload.reasonName,
  });
};
