import { createAction, handleActions } from "redux-actions";

export const types = {
  SET_LIST_PRESENT: "SET_LIST_PRESENT",
  SET_IS_PLAYING: "SET_IS_PLAYING",
  SET_IS_REVIEW: "SET_IS_REVIEW",
  SET_IS_EDIT: "SET_IS_EDIT",
  SET_STEP_ACTIVE: "SET_STEP_ACTIVE",
  SET_PRESENT_STEP_NAVIGATE_DIRECTION: "SET_PRESENT_STEP_NAVIGATE_DIRECTION",
  SET_LIST_ELEMENT_AT_ALL_STEP: "SET_LIST_ELEMENT_AT_ALL_STEP",
  SET_LIST_ELEMENT_AT_STEP: "SET_LIST_ELEMENT_AT_STEP",
  SET_PRESENT_DATA: "SET_PRESENT_DATA",
  CLEAR_PRESENT_DATA: "CLEAR_PRESENT_DATA",
  SET_MINIMIZE_CONTROLS: "SET_MINIMIZE_CONTROLS",
};

export const actions = {
  setListPresent: createAction(types.SET_LIST_PRESENT),
  setIsPlaying: createAction(types.SET_IS_PLAYING),
  setIsReview: createAction(types.SET_IS_REVIEW),
  setIsEditMode: createAction(types.SET_IS_EDIT),
  setStepActive: createAction(types.SET_STEP_ACTIVE),
  setPresentStepNavigate: createAction(
    types.SET_PRESENT_STEP_NAVIGATE_DIRECTION
  ),
  setListElementAtAllStep: createAction(types.SET_LIST_ELEMENT_AT_ALL_STEP),
  setListElementAtStep: createAction(types.SET_LIST_ELEMENT_AT_STEP),
  setPresentData: createAction(types.SET_PRESENT_DATA),
  clearPresentData: createAction(types.CLEAR_PRESENT_DATA),
  setMinimizeControl: createAction(types.SET_MINIMIZE_CONTROLS),
};

const defaultState = {
  listPresent: [],
  stepActive: null,
  isPlayingMode: false,
  isReviewMode: false,
  isEditMode: false,
  presentStepNavigate: null,
  listElementAllStep: null,
  listElementAtStep: null,
  isLiveShare: false,
  ownerData: null,
  listMember: [],
  isStartWatching: false,
  flagTriggerSocketChange: null,
  isFollow: false,
  isMinimize: false,
};

export default handleActions(
  {
    [types.SET_LIST_PRESENT]: (state, { payload }) => {
      return {
        ...state,
        listPresent: payload,
      };
    },
    [types.SET_IS_PLAYING]: (state, { payload }) => {
      if (!payload) {
        return {
          ...state,
          isPlayingMode: payload,
          listElementAllStep: null,
          listElementAtStep: null,
        };
      }
      return {
        ...state,
        isPlayingMode: payload,
      };
    },
    [types.SET_IS_REVIEW]: (state, { payload }) => {
      return {
        ...state,
        isReviewMode: payload,
      };
    },
    [types.SET_IS_EDIT]: (state, { payload }) => {
      return {
        ...state,
        isEditMode: payload,
      };
    },
    [types.SET_STEP_ACTIVE]: (state, { payload }) => {
      return {
        ...state,
        stepActive: payload,
      };
    },
    [types.SET_PRESENT_STEP_NAVIGATE_DIRECTION]: (state, { payload }) => {
      return {
        ...state,
        presentStepNavigate: payload,
      };
    },
    [types.SET_LIST_ELEMENT_AT_ALL_STEP]: (state, { payload }) => {
      return {
        ...state,
        listElementAllStep: payload,
      };
    },
    [types.SET_LIST_ELEMENT_AT_STEP]: (state, { payload }) => {
      return {
        ...state,
        listElementAtStep: payload,
      };
    },
    [types.SET_PRESENT_DATA]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [types.SET_MINIMIZE_CONTROLS]: (state, { payload }) => {
      return {
        ...state,
        isMinimize: payload,
      };
    },
    [types.CLEAR_PRESENT_DATA]: (state, { payload }) => {
      return defaultState;
    },
  },
  defaultState
);
