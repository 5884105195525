import API from "../../configs/api";
import apiMethod from "../../utility/ApiMethod";
import apiMethodSTNHD from "../../utility/ApiMethodSTNHD";
import apiGetIp from "../../utility/ApiGetIp";

export const getListCity = async (payload) => {
  try {
    const { data } = await apiMethodSTNHD.get(API.GET_COMMON_GET_CITY, payload);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListDistrict = async (payload) => {
  try {
    const { data } = await apiMethodSTNHD.get(
      `${API.GET_COMMON_GET_DISTRICT}?ProvinceId=${payload}`
    );
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getShareInfoData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.GET_SHARE_INFO}`
    );
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getIpInfo = async () => {
  try {
    const { data } = await apiGetIp.get(`https://www.cloudflare.com${API.GET_IP}`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};
