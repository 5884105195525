import { createAction, handleActions } from "redux-actions";

export const types = {
  SET_DRAGGING_MINDMAP: "SET_DRAGGING_MINDMAP",
  SET_MINDMAP_TO_UPDATE: "SET_MINDMAP_TO_UPDATE",
  SET_CAN_DROP: "SET_CAN_DROP",
};

export const actions = {
  setDraggingMindmap: createAction(types.SET_DRAGGING_MINDMAP),
  setMindmapToUpdate: createAction(types.SET_MINDMAP_TO_UPDATE),
  setCanDrop: createAction(types.SET_CAN_DROP),
};

const defaultState = {
  draggingMindmap: null,
  mindmapToUpdate: null,
  canDrop: false,
};

export default handleActions(
  {
    [types.SET_DRAGGING_MINDMAP]: (state, { payload }) => {
      return {
        ...state,
        draggingMindmap: payload,
      };
    },
    [types.SET_MINDMAP_TO_UPDATE]: (state, { payload }) => {
      return {
        ...state,
        mindmapToUpdate: payload,
      };
    },
    [types.SET_CAN_DROP]: (state, { payload }) => {
      return {
        ...state,
        canDrop: payload,
      };
    },
  },
  defaultState
);
