import React from "react";
import { CRUD_MODES, LINE_SHAPES, PAGE_SIZE_DIM } from "../configs/constants";
import {
  Position,
  ArrowHeadType,
  Node,
  XYPosition,
  isNode,
  isEdge,
  getOutgoers,
  getConnectedEdges,
} from "../../../components/ReactFlow/dist/ReactFlow.esm";
import {
  getBezierDraggingLine,
  getCurveBezier,
  getRootBezierDraggingLine,
  getTaperedBezier,
} from "./curveUtils";
import { getDiffAlpha, getDistance } from "./mathUtils";
import { FolderIcon } from "@Modules/Home/Components/components/icon";
import {
  findAllChildNodeV2,
  prepareData,
  prepareDataForElement,
} from "./autoLayoutV2";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

function getNodeIntersection(intersectionNode, targetNode) {
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    position: intersectionNodePosition,
  } = intersectionNode.__rf;
  const targetPosition = targetNode.__rf.position;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.__rf.position, ...node.__rf };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);
  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createElements(updateMode = () => {}, setElements = () => {}) {
  const elements = [];

  let center = null;

  if (window) {
    center = { x: window.innerWidth / 2 - 50, y: window.innerHeight / 2 - 50 };
  } else {
    center = { x: 500, y: 500 };
  }

  elements.push({
    id: "root",
    data: { label: "root", id: "root", updateMode, setElements },
    position: center,
    dragHandle: ".custom-node-drag-area",
    type: "root_node",
    draggable: false,
  });

  return elements;
}

export const findAllChildNode = (parentNodeId, edges = []) => {
  let childrens = edges.map((item) => {
    if (item?.source == parentNodeId) {
      const childrenOfChildren = findAllChildNode(item?.target, edges);
      return [...childrenOfChildren, item?.target];
    }
    return [];
  });
  return [].concat.apply([], childrens);
};
export const findAllChildNodeWithData = (
  parentNodeId,
  edges = [],
  nodes = []
) => {
  let childrens = edges.map((item) => {
    if (item?.source === parentNodeId) {
      const childrenOfChildren = findAllChildNodeWithData(
        item?.target,
        edges,
        nodes
      );
      return [
        ...childrenOfChildren,
        nodes.find((ele) => ele.id === item?.target),
      ];
    }
    return [];
  });
  return [].concat.apply([], childrens);
};

export const findAllParentNode = (childNodeId, edges = []) => {
  let parents = edges.map((item, index) => {
    if (item?.target == childNodeId) {
      const parentsOfParent = findAllParentNode(item?.source, edges);

      return [...parentsOfParent, item?.source];
    }
    return [];
  });
  return [].concat.apply([], parents);
};
export const findAllChildElements = (parentNodeId, edges = [], nodes = []) => {
  let edgeId = edges.find((item) => item.target === parentNodeId)?.id || null;
  if (!edgeId) {
    return [];
  }
  let listChildNodeId = findAllChildNode(parentNodeId, edges);
  let listChildNode = nodes?.filter(
    (item) =>
      parentNodeId === item?.id ||
      listChildNodeId.findIndex((v) => v === item.id) !== -1
  );
  const allChildEdge = edges
    .map((item) =>
      item.id === edgeId
        ? item
        : listChildNodeId.findIndex((v) => v === item.target) === -1
        ? null
        : item
    )
    ?.filter((item) => item);
  return [...listChildNode, ...allChildEdge];
};

const getElementHeadId = (elementId = "") => {
  if (elementId === "root") return "root";
  if (elementId.includes("edge")) {
    if (elementId.includes("image")) {
      return "image-edge-";
    }
    if (elementId.includes("connect")) {
      return "edge-connect-";
    }
    return "edge-";
  } else {
    if (elementId.includes("image")) {
      return "image-node-";
    }
    if (elementId.includes("invisible")) {
      return "node-invisible-";
    }
    return "node-";
  }
};

export const generateCopyId = (elements = []) => {
  let randomNewId = uuidv4();
  return elements.map((item, index) => {
    let cloneItem = item;
    if (isEdge(cloneItem || {})) {
      let sourceIdx = elements.findIndex((ele) => ele.id === cloneItem.source);
      let targetIdx = elements.findIndex((ele) => ele.id === cloneItem.target);
      return {
        ...cloneItem,
        data: {
          ...cloneItem?.data,
          originId: cloneItem.id,
        },
        id: `${getElementHeadId(item.id)}${randomNewId + index}`,
        target: `${getElementHeadId(item.target)}${randomNewId + targetIdx}`,
        source:
          cloneItem.source === "root"
            ? "root"
            : `${getElementHeadId(item.source)}${randomNewId + sourceIdx}`,
      };
    } else {
      let newObj = {
        ...cloneItem,
        data: {
          ...cloneItem?.data,
          originId: cloneItem.id,
          id: `${getElementHeadId(item.id)}${randomNewId + index}`,
        },
        id: `${getElementHeadId(item.id)}${randomNewId + index}`,
      };
      delete newObj.__rf;
      return newObj;
    }
  });
};
export const reGenerateNewCopyId = (elements = []) => {
  let randomNewId = uuidv4();
  return elements?.map((item, index) => {
    let cloneItem = item;
    if (isEdge(cloneItem || {})) {
      let sourceIdx = elements.findIndex((ele) => ele.id === cloneItem.source);
      let targetIdx = elements.findIndex((ele) => ele.id === cloneItem.target);
      return {
        ...cloneItem,
        data: {
          ...cloneItem.data,
          originId: cloneItem.id,
        },
        id: `${getElementHeadId(item.id)}${randomNewId + index}`,
        target: `${getElementHeadId(item.target)}${randomNewId + targetIdx}`,
        source:
          cloneItem.source === "root"
            ? "root"
            : `${getElementHeadId(item.source)}${randomNewId + sourceIdx}`,
      };
    } else {
      let newObj = {
        ...cloneItem,
        id: `${getElementHeadId(item.id)}${randomNewId + index}`,
        data: {
          ...item.data,
          originId: item.id,
          id: `${getElementHeadId(item.id)}${randomNewId + index}`,
        },
      };
      delete newObj.__rf;
      return newObj;
    }
  });
};
export const getRootOfListElement = (elements = []) => {
  let edges = elements?.filter((item) => isEdge(item || {}));
  let nodes = elements?.filter((item) => isNode(item || {}));
  let root = edges.find((item) => {
    return nodes.findIndex((v) => v.id === item.source) === -1;
  });
  return root;
};
export const getFontSizeByLevel = (level = 0, isConnection = false) => {
  if (isConnection) {
    return 14;
  }
  if (!level || level === 1) return 35;
  if (level === 2) return 25;
  return 17;
};
export const pasteElement = (
  mode = "",
  sourceCopiedHistory,
  edges,
  nodes,
  savedEdges,
  savedNodes,
  targetNode,
  elementsCopied = [],
  sourceCutHistory,
  setElements = () => {},
  onDoneCb = () => {},
  savePrevEdgePosition
) => {
  let sourceHistory = sourceCopiedHistory;
  if (mode === CRUD_MODES.CUT) {
    sourceHistory = sourceCutHistory;
  }
  if (!sourceHistory) return;
  let modeEdges = edges;
  let modeNodes = nodes;
  const DELTA_LEVEL =
    (targetNode?.data?.level || 0) - (sourceCopiedHistory?.data?.level || 0);
  const parentColor =
    edges.find((item) => item.target === targetNode.id)?.data?.lineColor || "";
  const rootEdgesInCopiedElements = getRootOfListElement(elementsCopied);

  const parentNode = targetNode;
  const parentNodeHistory = {
    ...sourceHistory,
    id: targetNode.id,
  };

  const animNode = elementsCopied?.find(
    (ele) => ele.id === rootEdgesInCopiedElements.target
  );
  const sourceX =
    sourceHistory?.__rf?.position?.x + (sourceHistory?.__rf?.width || 0) / 2;
  const sourceY =
    sourceHistory?.__rf?.position?.y + (sourceHistory?.__rf?.height || 0) / 2;
  const targetX =
    targetNode?.__rf?.position?.x + (targetNode?.__rf?.width || 0) / 2;
  const targetY =
    targetNode?.__rf?.position?.y + (targetNode?.__rf?.height || 0) / 2;

  const deltaX = sourceX - targetX;
  const deltaY = sourceY - targetY;
  const whiteList = {};
  _.cloneDeep(elementsCopied).forEach((ele) => {
    if (ele.id !== "root") whiteList[ele.id] = ele;
  });
  const history = [
    ...modeNodes,
    ...modeEdges,
    ...elementsCopied.map((item) => {
      if (isEdge(item || {})) {
        if (item.id === rootEdgesInCopiedElements.id) {
          return {
            ...item,
            source: sourceHistory.id,
            data: {
              ...item?.data,
              lineColor: parentColor || item?.data?.lineColor,
              pattern: parentNode?.id === "root" ? item?.data?.pattern : null,
            },
          };
        }

        return {
          ...item,
          data: {
            ...item?.data,
            lineColor: parentColor || item?.data?.lineColor,
            pattern: parentNode?.id === "root" ? item?.data?.pattern : null,
          },
        };
      }
      return item;
    }),
  ];
  const newListHaveNotUpdatePositionYet = [
    ...modeNodes,
    ...modeEdges,
    ...elementsCopied.map((item) => {
      if (isEdge(item || {})) {
        if (item.id === rootEdgesInCopiedElements.id) {
          return {
            ...item,
            source: targetNode.id,
            data: {
              ...item?.data,
              lineColor: parentColor || item?.data?.lineColor,
              sourceLevel: (item?.data?.sourceLevel || 0) + DELTA_LEVEL,
              fontSize: item?.id?.includes("connect")
                ? item?.data?.fontSize
                : getFontSizeByLevel(
                    (item?.data?.sourceLevel || 0) + DELTA_LEVEL
                  ),
              pattern: parentNode?.id === "root" ? item?.data?.pattern : null,
            },
          };
        }
        return {
          ...item,
          data: {
            ...item?.data,
            lineColor: parentColor || item?.data?.lineColor,
            sourceLevel: (item?.data?.sourceLevel || 0) + DELTA_LEVEL,
            fontSize: item?.id?.includes("connect")
              ? item?.data?.fontSize
              : getFontSizeByLevel(
                  (item?.data?.sourceLevel || 0) + DELTA_LEVEL
                ),
            pattern: parentNode?.id === "root" ? item?.data?.pattern : null,
          },
        };
      } else {
        return {
          ...item,
          data: {
            ...item?.data,
            outlineColor: parentColor || item?.data?.outlineColor,
            level: (item?.data?.level || 0) + DELTA_LEVEL,
            fontSize: getFontSizeByLevel(
              (item?.data?.level || 0) + DELTA_LEVEL
            ),
          },
        };
      }
    }),
  ];

  const currentEdges = newListHaveNotUpdatePositionYet?.filter((item) =>
    isEdge(item || {})
  );
  const listUpdatedChildren = updateChildrenNodePosition(
    {
      ...parentNode,
      // position: {
      //   x: parentNode.position.x - deltaX,
      //   y: parentNode.position.y - deltaY
      // }
    },
    parentNodeHistory,
    newListHaveNotUpdatePositionYet,
    currentEdges,
    true,
    deltaX,
    deltaY,
    history,
    null,
    0,
    null,
    false,
    false,
    savePrevEdgePosition,
    whiteList
  );
  let newList = newListHaveNotUpdatePositionYet?.map((item) => {
    let updatedNode = listUpdatedChildren?.find((ele) => ele?.id == item?.id);
    if (updatedNode) {
      return updatedNode;
    }
    return item;
  });
  onDoneCb(animNode);
  setElements([...newList]);
};

export const getElementsAfterRemove = (edgeId, targetNode, nodes, edges) => {
  const allChildNode = findAllChildNode(targetNode.id, edges);
  const allChildEdge = edges.map((item) =>
    item.id === edgeId
      ? null
      : allChildNode.findIndex((v) => v === item.target) !== -1
      ? null
      : item
  );
  return {
    nodesAfterRemove: nodes?.filter((item) => {
      if (targetNode.id === item.id) {
        return false;
      }
      if (allChildNode.findIndex((v) => v === item.id) !== -1) {
        return false;
      }
      return true;
    }),
    edgesAfterRemove: allChildEdge?.filter((item) => item),
  };
};
export const handleChangePageSize = (
  pageSize,
  rootWidth = 150,
  rootHeight = 200,
  rootPos = { x: 0, y: 0 }
) => {
  const PIXEL_FROM_ONE_CM = 37.795276;
  if (!pageSize || pageSize === "Free Size") {
    if (!document.getElementById("paper-shadow")) {
      return;
    } else {
      let paperShadow = document.getElementById("paper-shadow");
      paperShadow.removeAttribute("style");
    }
    return;
  }

  const paperCenter = { x: 0, y: 0 };
  paperCenter.x =
    rootPos.x +
    rootWidth / 2 -
    (PAGE_SIZE_DIM[pageSize].width * PIXEL_FROM_ONE_CM) / 2;

  paperCenter.y =
    rootPos.y +
    rootHeight / 2 -
    (PAGE_SIZE_DIM[pageSize].height * PIXEL_FROM_ONE_CM) / 2;

  let element = document.getElementsByClassName("react-flow__nodes")[0];

  let paperShadow = null;
  if (!document.getElementById("paper-shadow")) {
    paperShadow = document.createElement("div");
    paperShadow.setAttribute(
      "style",
      `width: ${PAGE_SIZE_DIM[pageSize].width}cm; height: ${PAGE_SIZE_DIM[pageSize].height}cm; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); z-index:0; position:absolute; left: ${paperCenter.x}px; top: ${paperCenter.y}px;`
    );
    paperShadow.setAttribute("id", "paper-shadow");
    element.appendChild(paperShadow);
  } else {
    paperShadow = document.getElementById("paper-shadow");
    paperShadow.setAttribute(
      "style",
      `width: ${PAGE_SIZE_DIM[pageSize].width}cm; height: ${PAGE_SIZE_DIM[pageSize].height}cm; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); z-index:0; position:absolute; left: ${paperCenter.x}px; top: ${paperCenter.y}px;`
    );
  }
};

function Transform(k, x, y) {
  this.k = k;
  this.x = x;
  this.y = y;
}

Transform.prototype = {
  constructor: Transform,
  scale: function (k) {
    return k === 1 ? this : new Transform(this.k * k, this.x, this.y);
  },
  translate: function (x, y) {
    return (x === 0) & (y === 0)
      ? this
      : new Transform(this.k, this.x + this.k * x, this.y + this.k * y);
  },
  apply: function (point) {
    return [point[0] * this.k + this.x, point[1] * this.k + this.y];
  },
  applyX: function (x) {
    return x * this.k + this.x;
  },
  applyY: function (y) {
    return y * this.k + this.y;
  },
  invert: function (location) {
    return [(location[0] - this.x) / this.k, (location[1] - this.y) / this.k];
  },
  invertX: function (x) {
    return (x - this.x) / this.k;
  },
  invertY: function (y) {
    return (y - this.y) / this.k;
  },
  rescaleX: function (x) {
    return x.copy().domain(x.range().map(this.invertX, this).map(x.invert, x));
  },
  rescaleY: function (y) {
    return y.copy().domain(y.range().map(this.invertY, this).map(y.invert, y));
  },
  toString: function () {
    return "translate(" + this.x + "," + this.y + ") scale(" + this.k + ")";
  },
};

export const getTransformPosition = (transform, width, height) => {
  const { x, y, zoom } = transform;
  let nextZoom = zoom;
  const centerX = width / 2 - x * nextZoom;
  const centerY = height / 2 - y * nextZoom;
  let identity = new Transform(1, 0, 0);
  let transformData = identity.translate(centerX, centerY).scale(nextZoom);
  return transformData;
};

export const groupImageCategoryByParentId = (
  input = [],
  currentLevel = 1,
  parentId = 0
) => {
  const maxLevel = Math.max.apply(
    Math,
    input.map(function (a) {
      return a.level;
    })
  );
  if (currentLevel > maxLevel) return [];
  return input.reduce((prev, next) => {
    if (parentId === next?.parentId) {
      return [
        ...prev,

        {
          ...next,
          title: next.title || next.name,
          // icon: <FolderIcon />,
          children: groupImageCategoryByParentId(
            input,
            currentLevel + 1,
            next.id
          ),
        },
      ];
    }
    return prev;
  }, []);
};

export const updateChildrenNodePosition = (
  parentNode,
  parentHistory,
  currentListElement,
  currentEdges,
  isNear,
  deltaX,
  deltaY,
  listHistory,
  updateRf,
  level,
  sourceRf,
  isReverseX,
  isReverseY,
  savePrevEdgePosition,
  whiteList
) => {
  if (!parentNode || !parentHistory) return;
  let listChildren = getOutgoers(parentNode, currentListElement);
  const listEdge = getConnectedEdges([parentNode], currentEdges)?.filter(
    (item) => {
      if (isNear) {
        return item?.source === parentNode?.id;
      }

      return (
        item?.source === parentNode?.id ||
        (level === 1 && item.target === parentNode.id)
      );
    }
  );

  listChildren = listChildren.concat(listEdge)?.filter((ele) => {
    if (!whiteList) {
      return true;
    }
    return whiteList[ele.id];
  });

  let newListChildren = [];
  newListChildren = listChildren.map((item) => {
    const itemHistory = listHistory?.find((ele) => ele?.id == item?.id);
    let newX =
      itemHistory?.position?.x -
      (parentHistory?.position?.x - parentNode?.position?.x);

    if (isNear) {
      newX =
        (itemHistory.__rf?.position.x || itemHistory?.position?.x) - deltaX;
    }
    let newY =
      itemHistory?.position?.y -
      (parentHistory?.position?.y - parentNode?.position?.y);
    if (isNear) {
      newY =
        (itemHistory.__rf?.position.y || itemHistory?.position?.y) - deltaY;
    }
    let button1 = { x: 0, y: 0 };
    let button2 = { x: 0, y: 0 };
    let newControlPoints = [];
    let newShowPoints = [];
    let tempPoint = listHistory.find((ele) => ele.id === item.source)
      ?.position || { x: 0, y: 0 };
    // console.log("SOURCE RF", sourceRf)
    let centerPoint = {
      x: tempPoint.x + (sourceRf?.width / 2 || 0),
      y: tempPoint.y + (sourceRf?.height / 2 || 0),
    };

    let distanceFromDragNodeToCenterOfArc = getDistance(
      parentNode.position,
      centerPoint
    );
    let distanceFromDragNodeHistoryToCenterOfArc = getDistance(
      parentHistory.position,
      centerPoint
    );
    let ratioDragDistance =
      distanceFromDragNodeToCenterOfArc /
      distanceFromDragNodeHistoryToCenterOfArc;

    const deltaAlpha = getDiffAlpha(
      parentHistory.position,
      parentNode.position,
      {
        x: centerPoint.x,
        y: centerPoint.y,
      }
    );
    let oldSourceNode = listHistory.find((ele) => ele.id === item?.source);
    let oldTargetNode = listHistory.find((ele) => ele.id === item?.target);
    if (isEdge(item || {})) {
      let oldControlPoints = itemHistory?.data?.controlPoints;
      let oldShowPoints = itemHistory?.data?.showPoints;

      if (
        item?.data?.isLinear &&
        level === 1 &&
        item?.target === parentNode?.id
      ) {
        const isFromRoot = item?.source === "root";
        let { button1, button2 } = getBezierDraggingLine(
          {
            x: centerPoint.x,
            y: centerPoint.y,
          },
          parentNode.position
        );

        if (isFromRoot) {
          // console.log("IS FROM ROOT", tempPoint, centerPoint)
          let rootData = getRootBezierDraggingLine(
            {
              x: centerPoint.x,
              y: centerPoint.y,
            },
            parentNode.position
          );
          button1 = rootData.button1;
          button2 = rootData.button2;
        }
        newControlPoints = [...(item?.data?.controlPoints || [])].map(
          (ele, index, arr) => {
            return {
              x: index === 0 ? button1.x : button2.x,
              y: index === 0 ? button1.y : button2.y,
            };
          }
        );
        newShowPoints = [...(item?.data?.showPoints || [])].map(
          (ele, index, arr) => {
            const newBezierData =
              item?.type === LINE_SHAPES.TAPERED_LINE
                ? getTaperedBezier(
                    centerPoint.x,
                    centerPoint.y,
                    parentNode.position.x,
                    parentNode.position.y,
                    button1,
                    button2,
                    1,
                    1
                  )
                : getCurveBezier(
                    centerPoint.x,
                    centerPoint.y,
                    parentNode.position.x,
                    parentNode.position.y,
                    button1,
                    button2,
                    1,
                    1
                  );

            return {
              x:
                index === 0
                  ? newBezierData.controlPointStart.x
                  : newBezierData.controlPointEnd.x,
              y:
                index === 0
                  ? newBezierData.controlPointStart.y
                  : newBezierData.controlPointEnd.y,
            };
          }
        );
      } else {
        if (
          (isReverseX || isReverseY) &&
          !item?.data?.visibleTarget &&
          item.source === parentNode.id
        ) {
          //FLIP CONTROL POINTS
          newControlPoints = [...(item?.data?.controlPoints || [])].map(
            (ele, index, arr) => {
              return {
                x: isReverseX
                  ? parentNode.position.x +
                    (parentHistory.position.x - oldControlPoints[index].x)
                  : oldControlPoints[index].x -
                    (parentHistory?.position?.x - parentNode?.position?.x),
                y: isReverseY
                  ? parentNode.position.y +
                    (parentHistory.position.y - oldControlPoints[index].y)
                  : oldControlPoints[index].y -
                    (parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
          // FLIP SHOW POINTS
          newShowPoints = [...(item?.data?.showPoints || [])].map(
            (ele, index, arr) => {
              return {
                x: isReverseX
                  ? parentNode.position.x +
                    (parentHistory.position.x - oldShowPoints[index].x)
                  : oldShowPoints[index].x -
                    (parentHistory?.position?.x - parentNode?.position?.x),
                y: isReverseY
                  ? parentNode.position.y +
                    (parentHistory.position.y - oldShowPoints[index].y)
                  : oldShowPoints[index].y -
                    (parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
          // newShowPoints = newControlPoints
        } else {
          newControlPoints = [...(item?.data?.controlPoints || [])].map(
            (ele, index, arr) => {
              let diffX = oldControlPoints[index]?.x - centerPoint.x;
              let diffY = oldControlPoints[index]?.y - centerPoint.y;
              let alpha = Math.atan2(diffY, diffX) + deltaAlpha;
              let distanceFromControlPointToCenterOfArc =
                getDistance(
                  {
                    x: oldControlPoints[index].x,
                    y: oldControlPoints[index].y,
                  },
                  centerPoint
                ) * ratioDragDistance;
              let newX =
                distanceFromControlPointToCenterOfArc * Math.cos(alpha);
              let newY =
                distanceFromControlPointToCenterOfArc * Math.sin(alpha);
              if (item?.target === parentNode?.id) {
                return {
                  x: newX + centerPoint.x,
                  y: newY + centerPoint.y,
                };
              }
              return {
                x:
                  oldControlPoints[index]?.x -
                  (isNear
                    ? deltaX
                    : parentHistory?.position?.x - parentNode?.position?.x),
                y:
                  oldControlPoints[index]?.y -
                  (isNear
                    ? deltaY
                    : parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
          newShowPoints = [...(item?.data?.showPoints || [])].map(
            (ele, index, arr) => {
              let diffX = oldShowPoints[index]?.x - centerPoint.x;
              let diffY = oldShowPoints[index]?.y - centerPoint.y;
              let alpha = Math.atan2(diffY, diffX) + deltaAlpha;

              let distanceFromControlPointToCenterOfArc =
                getDistance(
                  {
                    x: oldShowPoints[index].x,
                    y: oldShowPoints[index].y,
                  },
                  centerPoint
                ) * ratioDragDistance;
              let newX =
                distanceFromControlPointToCenterOfArc * Math.cos(alpha);
              let newY =
                distanceFromControlPointToCenterOfArc * Math.sin(alpha);
              if (item?.target === parentNode?.id && level === 1) {
                return {
                  x: newX + centerPoint.x,
                  y: newY + centerPoint.y,
                };
              }
              return {
                x:
                  oldShowPoints[index]?.x -
                  (isNear
                    ? deltaX
                    : parentHistory?.position?.x - parentNode?.position?.x),
                y:
                  oldShowPoints[index]?.y -
                  (isNear
                    ? deltaY
                    : parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
        }
      }
    }

    let updatedElement = null;
    if (isNode(item || {})) {
      updatedElement = {
        ...item,
        position: {
          x: newX,
          y: newY,
        },
        __rf: {
          ...item?.__rf,
          position: {
            x: newX,
            y: newY,
          },
        },
        data: {
          ...item?.data,
        },
      };
    } else {
      const isFromRoot = item?.source === "root";
      updatedElement = {
        ...item,
        data: {
          ...item?.data,
          previousEdgePosition: !savePrevEdgePosition
            ? null
            : {
                ...itemHistory?.data,
                targetX: oldTargetNode.position.x,
                targetY: oldTargetNode.position.y,
                sourceX:
                  oldSourceNode.position.x +
                  (isFromRoot ? oldSourceNode?.__rf?.width || 150 : 0) / 2,
                sourceY:
                  oldSourceNode.position.y +
                  (isFromRoot ? oldSourceNode?.__rf?.height || 100 : 0) / 2,
              },
          bezier_start_point: button1,
          bezier_end_point: button2,
          controlPoints: newControlPoints,
          showPoints: newShowPoints,
          targetX: parentNode.position.x,
          targetY: parentNode.position.y,
          sourceX: centerPoint.x,
          sourceY: centerPoint.y,
        },
      };
    }

    const listChildrenOfChildren = getOutgoers(item, currentListElement);
    let updatedChildrenOfChildren = [];
    if (listChildrenOfChildren?.length && isNode(item || {})) {
      updatedChildrenOfChildren.push(
        ...updateChildrenNodePosition(
          {
            ...item,
            position: {
              x: newX,
              y: newY,
            },
          },
          itemHistory,
          currentListElement,
          currentEdges,
          isNear,
          deltaX,
          deltaY,
          listHistory,
          updateRf,
          level + 1,
          null,
          isReverseX,
          isReverseY,
          savePrevEdgePosition
        )
      );
    }
    return [
      {
        ...updatedElement,
      },
      ...updatedChildrenOfChildren,
    ];
  });

  return [].concat.apply([], newListChildren);
};
export const flatternArray = (multiDimensionMatrix = []) =>
  [].concat.apply([], multiDimensionMatrix);

const addHideParentElement = (
  elementsObject = {},
  currentNode,
  listParentObjects = {},
  removeRoot = true
) => {
  if (!currentNode) {
    return [];
  }
  let clonedCurrent = _.cloneDeep(currentNode);
  let allChildEdge = [...(elementsObject[`SOURCE_${currentNode.id}`] || [])];
  if (elementsObject[`VISIBLE_TARGET_${currentNode.id}`]) {
    allChildEdge.push(elementsObject[`VISIBLE_TARGET_${currentNode.id}`]);
  }
  let sourceEdge = elementsObject[`TARGET_${currentNode.id}`];
  if (allChildEdge && allChildEdge?.length) {
    if (!removeRoot) {
      return [
        {
          ...clonedCurrent,
          data: {
            ...clonedCurrent?.data,
            parentWasHide: {
              ...listParentObjects,
              ...(clonedCurrent?.data?.parentWasHide || {}),
            },
            // isCollapsed: true
          },
        },
        {
          ...sourceEdge,
          data: {
            ...sourceEdge.data,
            parentWasHide: {
              ...listParentObjects,
              ...(sourceEdge?.data?.parentWasHide || {}),
            },
            // isCollapsed: true
          },
        },
        ...flatternArray(
          allChildEdge.map((edge) => {
            return addHideParentElement(
              elementsObject,
              elementsObject[edge.target],
              {
                ...listParentObjects,
                // [clonedCurrent.id]: clonedCurrent
              },
              false
            );
          })
        ),
      ];
    }
    return flatternArray(
      allChildEdge.map((edge) => {
        return addHideParentElement(
          elementsObject,
          elementsObject[edge.target],
          {
            ...listParentObjects,
            // [clonedCurrent.id]: clonedCurrent
          },
          false
        );
      })
    );
  } else {
    return [
      {
        ...clonedCurrent,
        data: {
          ...clonedCurrent?.data,
          parentWasHide: {
            ...listParentObjects,
            ...(clonedCurrent?.data?.parentWasHide || {}),
          },
          // isCollapsed: true
        },
      },
      {
        ...sourceEdge,
        data: {
          ...sourceEdge.data,
          parentWasHide: {
            ...listParentObjects,
            ...(sourceEdge?.data?.parentWasHide || {}),
          },
          // isCollapsed: true
        },
      },
    ];
  }
};

const removeHideElementParent = (elementsObject = {}, nodeParent) => {
  let allChildNode = findAllChildNodeV2(nodeParent, elementsObject, true);
  let allChildEdge = allChildNode.map(
    (node) => elementsObject[`TARGET_${node.id}`]
  );

  return flatternArray(
    allChildEdge?.map((item) => {
      let targetNode = elementsObject[`${item.target}`];
      let connectEdge = null;
      let newConnectEdgeParentWasHideObj = null;
      if (elementsObject[`VISIBLE_TARGET_${targetNode.id}`]) {
        connectEdge = elementsObject[`VISIBLE_TARGET_${targetNode.id}`];
        newConnectEdgeParentWasHideObj = {
          ...connectEdge?.data?.parentWasHide,
        };
        if (newConnectEdgeParentWasHideObj?.[nodeParent?.id]) {
          delete newConnectEdgeParentWasHideObj?.[nodeParent?.id];
        }
      }
      let newItemParentWasHideObj = {
        ...item.data?.parentWasHide,
      };
      let newTargetNodeParentWasHideObj = {
        ...targetNode.data?.parentWasHide,
      };
      if (newItemParentWasHideObj?.[nodeParent?.id]) {
        delete newItemParentWasHideObj?.[nodeParent?.id];
      }
      if (newTargetNodeParentWasHideObj?.[nodeParent?.id]) {
        delete newTargetNodeParentWasHideObj?.[nodeParent?.id];
      }
      return connectEdge
        ? [
            {
              ...connectEdge,
              data: {
                ...connectEdge?.data,
                parentWasHide: newConnectEdgeParentWasHideObj,
              },
            },
            {
              ...item,
              data: {
                ...item?.data,
                parentWasHide: newItemParentWasHideObj,
              },
            },
            {
              ...targetNode,
              data: {
                ...targetNode?.data,
                parentWasHide: newItemParentWasHideObj,
              },
            },
          ]
        : [
            {
              ...item,
              data: {
                ...item?.data,
                parentWasHide: newItemParentWasHideObj,
              },
            },
            {
              ...targetNode,
              data: {
                ...targetNode?.data,
                parentWasHide: newItemParentWasHideObj,
              },
            },
          ];
    }) || []
  );
};
const removeAllHideElementParent = (elementsObject = {}, nodeParent) => {
  const allChildNode = findAllChildNodeV2(nodeParent, elementsObject, true);
  let allChildEdge = allChildNode.map(
    (ele) => elementsObject[`TARGET_${ele.id}`]
  );

  return flatternArray(
    allChildEdge?.map((item) => {
      let targetNode = elementsObject[`${item.target}`];
      let connectEdge = null;
      if (elementsObject[`VISIBLE_TARGET_${targetNode.id}`]) {
        connectEdge = elementsObject[`VISIBLE_TARGET_${targetNode.id}`];
      }
      return connectEdge
        ? [
            {
              ...connectEdge,
              data: {
                ...connectEdge?.data,
                parentWasHide: {},
              },
            },
            {
              ...item,
              data: {
                ...item?.data,
                parentWasHide: {},
              },
            },
            {
              ...targetNode,
              data: {
                ...targetNode?.data,
                parentWasHide: {},
              },
            },
          ]
        : [
            {
              ...item,
              data: {
                ...item?.data,
                parentWasHide: {},
              },
            },
            {
              ...targetNode,
              data: {
                ...targetNode?.data,
                parentWasHide: {},
              },
            },
          ];
    }) || []
  );
};

export const getElementsWillHide = (parentNode, edges = [], nodes = []) => {
  if (!parentNode) return [];
  const elementsObject = {};
  nodes.forEach((item) => {
    elementsObject[item.id] = {
      ...item,
    };
  });
  edges.forEach((item) => {
    let mappingTargetID = `TARGET_${item.target}`;
    let mappingSourceID = `SOURCE_${item.source}`;
    elementsObject[item.id] = item;
    elementsObject[mappingTargetID] = {
      ...item,
      data: {
        ...item?.data,
      },
    };
    if (elementsObject?.[mappingSourceID]) {
      elementsObject[mappingSourceID] = [
        ...elementsObject[mappingSourceID],
        {
          ...item,
          data: {
            ...item?.data,
          },
        },
      ];
    } else {
      elementsObject[mappingSourceID] = [
        {
          ...item,
          data: {
            ...item?.data,
          },
        },
      ];
    }
    // if (item.data?.visibleTarget) {
    //   elementsObject[`VISIBLE_TARGET_${item.data?.visibleTarget}`] = {
    //     ...item,
    //     data: {
    //       ...item?.data,
    //     },
    //   };
    // }
  });
  let clonedParent = _.cloneDeep(parentNode);
  return [
    ...flatternArray(
      addHideParentElement(elementsObject, parentNode, {
        [parentNode.id]: parentNode,
      })
    ),
    {
      ...clonedParent,
      data: {
        ...clonedParent.data,
        isCollapsed: true,
      },
    },
  ];
};

export const getElementsWillShow = (
  parentNode,
  edges = [],
  nodes = [],
  showAll = false
) => {
  if (!parentNode) return [];
  const elementsObject = {};
  nodes.forEach((item) => {
    elementsObject[item.id] = {
      ...item,
      data: {
        ...item?.data,
        parentWasHide: {},
      },
    };
  });
  edges.forEach((item) => {
    let mappingTargetID = `TARGET_${item.target}`;
    let mappingSourceID = `SOURCE_${item.source}`;
    elementsObject[item.id] = item;
    elementsObject[mappingTargetID] = {
      ...item,
    };
    if (elementsObject?.[mappingSourceID]) {
      elementsObject[mappingSourceID] = [
        ...elementsObject[mappingSourceID],
        {
          ...item,
        },
      ];
    } else {
      elementsObject[mappingSourceID] = [
        {
          ...item,
        },
      ];
    }
    if (item.data?.visibleTarget) {
      elementsObject[`VISIBLE_TARGET_${item.data?.visibleTarget}`] = {
        ...item,
      };
    }
  });

  return [
    ...flatternArray(removeHideElementParent(elementsObject, parentNode)),
    {
      ...parentNode,
      data: {
        ...parentNode.data,
        isCollapsed: false,
      },
    },
  ];
};

export const updateChildrenNodePositionV2 = (
  parentNode,
  parentHistory,
  currentListElement,
  currentEdges,
  isNear,
  deltaX,
  deltaY,
  listHistory,
  updateRf,
  level,
  sourceRf,
  isReverseX,
  isReverseY,
  savePrevEdgePosition,
  inputElementsObject,
  inputHistoryElementsObject
) => {
  let elementsObject = null;
  let historyElementsObject = null;
  if (Object.keys(inputElementsObject || {}).length) {
    elementsObject = inputElementsObject;
  } else {
    elementsObject = prepareDataForElement(currentListElement);
  }
  if (Object.keys(inputHistoryElementsObject || {}).length) {
    historyElementsObject = inputHistoryElementsObject;
  } else {
    historyElementsObject = prepareDataForElement(listHistory);
  }
  if (!parentNode || !parentHistory) return;
  let edgeChild = elementsObject[`SOURCE_${parentNode?.id}`] || [];
  let nodeChild = edgeChild.map((item) => elementsObject[item.target]);
  let listChildren = [
    ...edgeChild,
    ...nodeChild,
    elementsObject[`TARGET_${parentNode.id}`],
  ];
  if (!listChildren?.length) {
    return [];
  }
  let newListChildren = [];
  newListChildren = listChildren.map((item) => {
    const itemHistory = historyElementsObject[item?.id];
    let newX =
      itemHistory?.position?.x -
      (parentHistory?.position?.x - parentNode?.position?.x);
    if (isNear) {
      newX = itemHistory?.position?.x - deltaX;
    }
    let newY =
      itemHistory?.position?.y -
      (parentHistory?.position?.y - parentNode?.position?.y);
    if (isNear) {
      newY = itemHistory?.position?.y - deltaY;
    }
    let button1 = { x: 0, y: 0 };
    let button2 = { x: 0, y: 0 };
    let newControlPoints = [];
    let newShowPoints = [];
    let tempPoint = _.cloneDeep(
      historyElementsObject[item.source]?.position || {
        x: 0,
        y: 0,
      }
    );
    let centerPoint = {
      x: tempPoint.x + (sourceRf?.width / 2 || 0),
      y: tempPoint.y + (sourceRf?.height / 2 || 0),
    };

    let distanceFromDragNodeToCenterOfArc = getDistance(
      parentNode.position,
      centerPoint
    );
    let distanceFromDragNodeHistoryToCenterOfArc = getDistance(
      parentHistory.position,
      centerPoint
    );
    let ratioDragDistance =
      distanceFromDragNodeToCenterOfArc /
      distanceFromDragNodeHistoryToCenterOfArc;

    const deltaAlpha = getDiffAlpha(
      parentHistory.position,
      parentNode.position,
      {
        x: centerPoint.x,
        y: centerPoint.y,
      }
    );
    let oldSourceNode = null;

    let oldTargetNode = null;
    if (item?.source && item?.target) {
      oldSourceNode = historyElementsObject[`${item?.source}`];
      oldTargetNode = historyElementsObject[`${item?.target}`];
      if (!oldSourceNode?.position) {
        oldSourceNode = {
          position: {
            x: 0,
            y: 0,
          },
        };
      }
    }

    if (isEdge(item || {})) {
      let oldControlPoints = itemHistory?.data?.controlPoints;
      let oldShowPoints = itemHistory?.data?.showPoints;
      if (
        item?.data?.isLinear &&
        level === 1 &&
        item?.target === parentNode?.id
      ) {
        const isFromRoot = item?.source === "root";
        let { button1, button2 } = getBezierDraggingLine(
          {
            x: centerPoint.x,
            y: centerPoint.y,
          },
          parentNode.position
        );

        if (isFromRoot) {
          // console.log("IS FROM ROOT", tempPoint, centerPoint)
          let rootData = getRootBezierDraggingLine(
            {
              x: centerPoint.x,
              y: centerPoint.y,
            },
            parentNode.position
          );
          button1 = rootData.button1;
          button2 = rootData.button2;
        }
        newControlPoints = [...(item?.data?.controlPoints || [])].map(
          (ele, index, arr) => {
            return {
              x: index === 0 ? button1.x : button2.x,
              y: index === 0 ? button1.y : button2.y,
            };
          }
        );
        newShowPoints = [...(item?.data?.showPoints || [])].map(
          (ele, index, arr) => {
            const newBezierData =
              item?.type === LINE_SHAPES.TAPERED_LINE
                ? getTaperedBezier(
                    centerPoint.x,
                    centerPoint.y,
                    parentNode.position.x,
                    parentNode.position.y,
                    button1,
                    button2,
                    1,
                    1
                  )
                : getCurveBezier(
                    centerPoint.x,
                    centerPoint.y,
                    parentNode.position.x,
                    parentNode.position.y,
                    button1,
                    button2,
                    1,
                    1
                  );

            return {
              x:
                index === 0
                  ? newBezierData.controlPointStart.x
                  : newBezierData.controlPointEnd.x,
              y:
                index === 0
                  ? newBezierData.controlPointStart.y
                  : newBezierData.controlPointEnd.y,
            };
          }
        );
      } else {
        {
          newControlPoints = [...(item?.data?.controlPoints || [])].map(
            (ele, index, arr) => {
              let diffX = oldControlPoints[index]?.x - centerPoint.x;
              let diffY = oldControlPoints[index]?.y - centerPoint.y;
              let alpha = Math.atan2(diffY, diffX) + deltaAlpha;
              let distanceFromControlPointToCenterOfArc =
                getDistance(
                  {
                    x: oldControlPoints[index].x,
                    y: oldControlPoints[index].y,
                  },
                  centerPoint
                ) * ratioDragDistance;
              let newX =
                distanceFromControlPointToCenterOfArc * Math.cos(alpha);
              let newY =
                distanceFromControlPointToCenterOfArc * Math.sin(alpha);
              if (item?.target === parentNode?.id) {
                return {
                  x: newX + centerPoint.x,
                  y: newY + centerPoint.y,
                };
              }
              return {
                x:
                  oldControlPoints[index]?.x -
                  (isNear
                    ? deltaX
                    : parentHistory?.position?.x - parentNode?.position?.x),
                y:
                  oldControlPoints[index]?.y -
                  (isNear
                    ? deltaY
                    : parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
          newShowPoints = [...(item?.data?.showPoints || [])].map(
            (ele, index, arr) => {
              let diffX = oldShowPoints[index]?.x - centerPoint.x;
              let diffY = oldShowPoints[index]?.y - centerPoint.y;
              let alpha = Math.atan2(diffY, diffX) + deltaAlpha;
              let distanceFromControlPointToCenterOfArc =
                getDistance(
                  {
                    x: oldShowPoints[index].x,
                    y: oldShowPoints[index].y,
                  },
                  centerPoint
                ) * ratioDragDistance;
              let newX =
                distanceFromControlPointToCenterOfArc * Math.cos(alpha);
              let newY =
                distanceFromControlPointToCenterOfArc * Math.sin(alpha);
              if (item?.target === parentNode?.id && level === 1) {
                return {
                  x: newX + centerPoint.x,
                  y: newY + centerPoint.y,
                };
              }
              return {
                x:
                  oldShowPoints[index]?.x -
                  (isNear
                    ? deltaX
                    : parentHistory?.position?.x - parentNode?.position?.x),
                y:
                  oldShowPoints[index]?.y -
                  (isNear
                    ? deltaY
                    : parentHistory?.position?.y - parentNode?.position?.y),
              };
            }
          );
        }
      }
    }

    let updatedElement = null;
    if (isNode(item || {})) {
      updatedElement = {
        ...item,
        position: {
          x: isReverseX
            ? parentNode.position.x - (newX - parentNode.position.x)
            : newX,
          y: isReverseY
            ? parentNode.position.y - (newY - parentNode.position.y)
            : newY,
        },
        data: {
          ...item?.data,
        },
      };
    } else {
      const isFromRoot = item?.source === "root";
      updatedElement = {
        ...item,
        data: {
          ...item?.data,
          previousEdgePosition: !savePrevEdgePosition
            ? null
            : {
                ...itemHistory?.data,
                targetX: oldTargetNode.position.x,
                targetY: oldTargetNode.position.y,
                sourceX:
                  oldSourceNode?.position?.x +
                  (isFromRoot ? oldSourceNode?.__rf?.width || 150 : 0) / 2,
                sourceY:
                  oldSourceNode?.position?.y +
                  (isFromRoot ? oldSourceNode?.__rf?.height || 100 : 0) / 2,
              },
          bezier_start_point: button1,
          bezier_end_point: button2,
          controlPoints: newControlPoints,
          showPoints: newShowPoints,
          targetX: parentNode.position.x,
          targetY: parentNode.position.y,
          sourceX: centerPoint.x,
          sourceY: centerPoint.y,
        },
      };
    }

    let listChildrenOfChildren = [];
    if (isNode(item || {})) {
      listChildrenOfChildren = elementsObject[`SOURCE_${item.id}`]?.map(
        (edge) => elementsObject[edge.target]
      );
    }

    let updatedChildrenOfChildren = [];
    if (listChildrenOfChildren?.length && isNode(item || {})) {
      updatedChildrenOfChildren.push(
        ...updateChildrenNodePositionV2(
          {
            ...item,
            position: {
              x: isReverseX
                ? parentNode.position.x - (newX - parentNode.position.x)
                : newX,
              y: isReverseY
                ? parentNode.position.y - (newY - parentNode.position.y)
                : newY,
            },
          },
          itemHistory,
          currentListElement,
          currentEdges,
          isNear,
          deltaX,
          deltaY,
          listHistory,
          updateRf,
          level + 1,
          null,
          isReverseX,
          isReverseY,
          savePrevEdgePosition,
          elementsObject,
          historyElementsObject
        )
      );
    }
    return [
      {
        ...updatedElement,
      },
      ...updatedChildrenOfChildren,
    ];
  });
  return flatternArray(newListChildren);
};
export const cloneMindmap = (nodes = [], edges = []) => {
  let newNodes = nodes.map((item) =>
    item.id === "root"
      ? {
          ...item,
        }
      : {
          ...item,
          id: getElementHeadId(item.id) + uuidv4(),
        }
  );
  let newEdges = edges.map((edge) => {
    let sourceIdx = nodes.findIndex((item) => item.id === edge.source);
    let targetIdx = nodes.findIndex((item) => item.id === edge.target);
    return {
      ...edge,
      id: getElementHeadId(edge.id) + uuidv4(),
      source: newNodes[sourceIdx].id,
      target: newNodes[targetIdx].id,
    };
  });
  return {
    nodes: newNodes,
    edges: newEdges,
  };
};
export function getHandlePosition(position, node) {
  const x = node.__rf.position.x;
  const y = node.__rf.position.y;

  const width = node.__rf.width;
  const height = node.__rf.height;
  if (node.type === "branch_node" || node.id === "root") {
    return {
      x: x + width / 2,
      y: y + height / 2,
    };
  } else {
    if (position === Position.Left) {
      return {
        x: x,
        y: y + height / 2,
      };
    } else {
      return {
        x: x + width,
        y: y + height / 2,
      };
    }
  }
}
export const getEdgePositions = (sourceNode, targetNode) => {
  let sPos = null;
  let tPos = null;

  let sx = sourceNode.__rf.position.x;
  let tx = targetNode.__rf.position.x;
  if (sx < tx) {
    sPos = Position.Right;
    tPos = Position.Left;
  } else {
    sPos = Position.Left;
    tPos = Position.Right;
  }

  const sourceHandlePos = getHandlePosition(sPos, sourceNode);
  const targetHandlePos = getHandlePosition(tPos, targetNode);

  if (sx === tx || Math.abs(sx - tx) <= 4) {
    sourceHandlePos.x = sx;
    targetHandlePos.x = sx;
  }

  return {
    sourceX: sourceHandlePos.x,
    sourceY: sourceHandlePos.y,
    targetX: targetHandlePos.x,
    targetY: targetHandlePos.y,
  };
};
export const rectToBox = ({ x, y, width, height }) => ({
  x,
  y,
  x2: x + width,
  y2: y + height,
});
export const checkNodeInside = (node, rect, [tx, ty, tScale] = [0, 0, 1]) => {
  const rBox = rectToBox({
    x: (rect.x - tx) / tScale,
    y: (rect.y - ty) / tScale,
    width: rect.width / tScale,
    height: rect.height / tScale,
  });
  let { position, width, height } = node.__rf;
  let nPosition = { ...position };
  width = 400;
  height = 400;
  nPosition.x -= 200;
  nPosition.y -= 200;
  const nBox = rectToBox({ ...nPosition, width, height });
  const xOverlap =
    (Math.min(rBox.x2, nBox.x2) - Math.max(rBox.x, nBox.x) - 200) *
    (nBox.x > rBox.x + 200 ? -1 : 1);
  const yOverlap =
    (Math.min(rBox.y2, nBox.y2) - Math.max(rBox.y, nBox.y) - 200) *
    (nBox.y > rBox.y + 200 ? -1 : 1);

  return {
    xOverlap,
    yOverlap,
  };
};

const rotateEdge = (
  sourceNode,
  targetNode,
  edge,
  elementsObject = {},
  deltaX = 0,
  deltaY = 0,
  saveOldPosition,
  colorToChange,
  deltaLevel = 0,
  willChangeFontSize = false
) => {
  let targetNodeHistory,
    targetNodeCurrent,
    centerPoint,
    distanceFromDragNodeToCenterOfArc,
    distanceFromDragNodeHistoryToCenterOfArc,
    ratioDragDistance,
    deltaAlpha,
    newControlPoints,
    newShowPoints;

  targetNodeHistory = _.cloneDeep(targetNode);
  targetNodeHistory = {
    ...targetNodeHistory,
    position: {
      x: targetNodeHistory?.__rf?.position?.x || targetNodeHistory.position.x,
      y: targetNodeHistory?.__rf?.position?.y || targetNodeHistory.position.y,
    },
  };
  targetNodeCurrent = _.cloneDeep(targetNode);
  targetNodeCurrent = {
    ...targetNodeCurrent,
    position: {
      x: targetNodeCurrent.position.x + deltaX,
      y: targetNodeCurrent.position.y + deltaY,
    },
    __rf: {
      ...targetNodeCurrent?.__rf,
      position: {
        x:
          (targetNodeCurrent?.__rf?.position?.x ||
            targetNodeCurrent.position.x) + deltaX,
        y:
          (targetNodeCurrent?.__rf?.position?.y ||
            targetNodeCurrent.position.y) + deltaY,
      },
    },
  };
  centerPoint = {
    x:
      (sourceNode.__rf.position.x || sourceNode.position.x) +
      (sourceNode.__rf?.width || 0) / 2,
    y:
      (sourceNode.__rf.position.y || sourceNode.position.y) +
      (sourceNode.__rf?.height || 0) / 2,
  };
  distanceFromDragNodeToCenterOfArc = getDistance(
    targetNodeCurrent.position,
    centerPoint
  );
  distanceFromDragNodeHistoryToCenterOfArc = getDistance(
    targetNodeHistory.position,
    centerPoint
  );
  ratioDragDistance =
    distanceFromDragNodeToCenterOfArc /
    distanceFromDragNodeHistoryToCenterOfArc;

  deltaAlpha = getDiffAlpha(
    targetNodeHistory.position,
    targetNodeCurrent.position,
    centerPoint
  );
  newControlPoints = (edge.data?.controlPoints || [])?.map((item) => {
    let diffX = item?.x - centerPoint.x;
    let diffY = item?.y - centerPoint.y;
    let alpha = Math.atan2(diffY, diffX) + deltaAlpha;
    let distanceFromControlPointToCenterOfArc =
      getDistance(
        {
          x: item.x,
          y: item.y,
        },
        centerPoint
      ) * ratioDragDistance;
    let newX = distanceFromControlPointToCenterOfArc * Math.cos(alpha);
    let newY = distanceFromControlPointToCenterOfArc * Math.sin(alpha);
    return {
      x: newX + centerPoint.x,
      y: newY + centerPoint.y,
    };
  });

  newShowPoints = (edge.data?.showPoints || [])?.map((item) => {
    let diffX = item?.x - centerPoint.x;
    let diffY = item?.y - centerPoint.y;
    let alpha = Math.atan2(diffY, diffX) + deltaAlpha;
    let distanceFromControlPointToCenterOfArc =
      getDistance(
        {
          x: item.x,
          y: item.y,
        },
        centerPoint
      ) * ratioDragDistance;
    let newX = distanceFromControlPointToCenterOfArc * Math.cos(alpha);
    let newY = distanceFromControlPointToCenterOfArc * Math.sin(alpha);
    return {
      x: newX + centerPoint.x,
      y: newY + centerPoint.y,
    };
  });
  return {
    ...edge,
    data: {
      ...edge.data,
      previousEdgePosition: !saveOldPosition
        ? null
        : {
            ...edge.data,
            targetX: targetNodeHistory.position.x,
            targetY: targetNodeHistory.position.y,
            sourceX:
              sourceNode?.position?.x + (sourceNode?.__rf?.width || 0) / 2,
            sourceY:
              sourceNode?.position?.y + (sourceNode?.__rf?.height || 0) / 2,
          },
      showPoints: newShowPoints,
      controlPoints: newControlPoints,
      lineColor: colorToChange ? colorToChange : edge?.data?.lineColor,
      color: colorToChange ? colorToChange : edge?.data?.color,
      fontSize: edge?.id?.includes("connect")
        ? edge.data?.fontSize
        : willChangeFontSize
        ? getFontSizeByLevel((edge.data?.sourceLevel || 0) + deltaLevel)
        : edge.data?.fontSize,
      sourceLevel: (edge.data?.sourceLevel || 0) + deltaLevel,
    },
  };
};
const getDeltaXAfterFlip = (isFlip = false, deltaX, flipCenterX, nodeX) => {
  // if (!isFlip) {
  // return nodeX
  return nodeX + deltaX;
  // }
  // console.log("FLIP")
  // return nodeX + deltaX + (nodeX + deltaX - flipCenterX)
};
export const updateAllChildPositionV3 = (
  elementsObject,
  parentNode,
  deltaX = 0,
  deltaY = 0,
  saveOldPosition = false,
  colorToChange,
  deltaLevel = 0,
  willChangeFontSize = false,
  isFlip
) => {
  let allChildNode,
    edgesResult,
    nodesResult,
    sourceEdgeParentNode,
    sourceNodeOfSourceEdge,
    edgeRotated,
    edgesResultObject,
    nodesResultObject,
    parentNodeUpdated;

  allChildNode = findAllChildNodeV2(parentNode, elementsObject, true);

  edgesResult = [];
  nodesResult = [];
  edgesResultObject = {};
  nodesResultObject = {};

  parentNodeUpdated = _.cloneDeep(parentNode);
  parentNodeUpdated = {
    ...parentNodeUpdated,
    data: {
      ...parentNodeUpdated.data,
      fontSize: parentNodeUpdated?.id?.includes("visible")
        ? parentNodeUpdated.data?.fontSize
        : willChangeFontSize
        ? getFontSizeByLevel((parentNodeUpdated?.data?.level || 0) + deltaLevel)
        : parentNodeUpdated.data?.fontSize,
      level: (parentNodeUpdated?.data?.level || 0) + deltaLevel,
      outlineColor: colorToChange
        ? colorToChange
        : parentNodeUpdated?.data?.outlineColor,
      color: colorToChange ? colorToChange : parentNodeUpdated?.data?.color,
    },
    position: {
      x: parentNodeUpdated.position.x + deltaX,
      y: parentNodeUpdated.position.y + deltaY,
    },
    __rf: {
      ...parentNodeUpdated?.__rf,
      position: {
        x:
          (parentNodeUpdated?.__rf?.position?.x ||
            parentNodeUpdated.position.x) + deltaX,
        y:
          (parentNodeUpdated?.__rf?.position?.y ||
            parentNodeUpdated.position.y) + deltaY,
      },
    },
  };

  allChildNode.forEach((item) => {
    let nodeUpdated = {
      ...item,
      data: {
        ...item.data,
        fontSize: item?.id?.includes("visible")
          ? item.data?.fontSize
          : willChangeFontSize
          ? getFontSizeByLevel((item?.data?.level || 0) + deltaLevel)
          : item.data?.fontSize,
        level: item.data?.level + deltaLevel,
        outlineColor: colorToChange ? colorToChange : item?.data?.outlineColor,
        color: colorToChange ? colorToChange : item?.data?.color,
      },
      position: {
        x: getDeltaXAfterFlip(
          isFlip,
          deltaX,
          (parentNode?.__rf?.position?.x || parentNode.position.x) + deltaX,
          item.position.x
        ),
        y: item.position.y + deltaY,
      },
      __rf: {
        ...item?.__rf,
        position: {
          x: (item?.__rf?.position?.x || item.position.x) + deltaX,
          y: (item?.__rf?.position?.y || item.position.y) + deltaY,
        },
      },
    };

    let edgeUpdated = _.cloneDeep(elementsObject[`TARGET_${item.id}`]);
    let tNode = elementsObject[item.id];
    let sNode = elementsObject[edgeUpdated.source];
    edgeUpdated = {
      ...edgeUpdated,
      data: {
        ...edgeUpdated?.data,
        previousEdgePosition: !saveOldPosition
          ? null
          : {
              controlPoints: edgeUpdated?.data?.controlPoints,
              showPoints: edgeUpdated?.data?.showPoints,
              targetX: tNode.position.x,
              targetY: tNode.position.y,
              sourceX: sNode?.position?.x + (sNode?.__rf?.width || 0) / 2,
              sourceY: sNode?.position?.y + (sNode?.__rf?.height || 0) / 2,
            },
        showPoints: edgeUpdated?.data?.showPoints?.map((ele) => ({
          x: ele.x + deltaX,
          y: ele.y + deltaY,
        })),
        controlPoints: edgeUpdated?.data?.controlPoints?.map((ele) => ({
          x: ele.x + deltaX,
          y: ele.y + deltaY,
        })),
        fontSize: edgeUpdated?.id?.includes("connect")
          ? edgeUpdated.data?.fontSize
          : willChangeFontSize
          ? getFontSizeByLevel(edgeUpdated.data.sourceLevel + deltaLevel)
          : edgeUpdated.data?.fontSize,
        sourceLevel: (edgeUpdated?.data?.sourceLevel || 0) + deltaLevel,
        lineColor:
          colorToChange && edgeUpdated?.type !== LINE_SHAPES.ARROW_LINE_TWO_WAY
            ? colorToChange
            : edgeUpdated?.data?.lineColor,
        color:
          colorToChange && edgeUpdated?.type !== LINE_SHAPES.ARROW_LINE_TWO_WAY
            ? colorToChange
            : edgeUpdated?.data?.color,
      },
    };
    nodesResult.push(nodeUpdated);
    edgesResult.push(edgeUpdated);
    edgesResultObject[edgeUpdated.id] = edgeUpdated;
    nodesResultObject[nodeUpdated.id] = nodeUpdated;
  });

  sourceEdgeParentNode = elementsObject[`TARGET_${parentNode.id}`];

  if (sourceEdgeParentNode) {
    sourceNodeOfSourceEdge = elementsObject[sourceEdgeParentNode.source];
    edgeRotated = rotateEdge(
      sourceNodeOfSourceEdge,
      parentNode,
      sourceEdgeParentNode,
      elementsObject,
      deltaX,
      deltaY,
      saveOldPosition,
      colorToChange,
      deltaLevel,
      willChangeFontSize
    );

    edgesResultObject[edgeRotated.id] = edgeRotated;
    nodesResultObject[parentNodeUpdated.id] = parentNodeUpdated;

    return {
      listResults: [
        ...nodesResult,
        ...edgesResult,
        edgeRotated,
        parentNodeUpdated,
      ],
      edgesResultObject,
      nodesResultObject,
    };
  }
  nodesResultObject[parentNodeUpdated.id] = parentNodeUpdated;
  return {
    listResults: [...nodesResult, ...edgesResult, parentNodeUpdated],
    edgesResultObject,
    nodesResultObject,
  };
};

export const resetFocusElements = (nodes = [], edges = []) => {
  let listLevel1Node = nodes?.filter(
    (ele) => ele.data?.level === 1 && ele.id !== "root"
  );
  let listChildWillHide = [];
  listLevel1Node.forEach((ele) => {
    let child = getElementsWillHide(ele, edges, nodes).map((item) =>
      item.id !== ele.id && item?.target !== ele?.id
        ? item
        : {
            ...item,
            data: {
              ...item.data,
              isCollapsed: true,
              parentWasHide: {},
            },
          }
    );
    listChildWillHide = listChildWillHide.concat(child);
  });
  return listChildWillHide;
};
export const focusElements = (
  selectedElements = [],
  nodes = [],
  edges = []
) => {
  if (!selectedElements || !selectedElements.length) return [];
  let mappingSelectedNode = selectedElements
    .map((ele) => {
      if (isNode(ele || {})) return nodes.find((item) => item.id === ele.id);
      return null;
    })
    .filter((ele) => ele);
  let childToShow = [];
  mappingSelectedNode.forEach((ele) => {
    if (isNode(ele || {})) {
      let child = getElementsWillShow(ele, edges, nodes).map((item) =>
        item.id !== ele.id && item?.target !== ele?.id
          ? item
          : {
              ...item,
              data: {
                ...item.data,
                isCollapsed: false,
                parentWasHide: {},
              },
            }
      );
      childToShow = childToShow.concat(child);
    }
  });
  return childToShow;
};

export const hidePrevElement = (
  prev = [],
  next = [],
  elementObject,
  nodes = [],
  edges = []
) => {
  if (!prev || !prev.length) {
    return [];
  }
  let mappingNewPrev = prev.map(
    (
      ele // GET RF OF NODE
    ) => nodes.find((item) => item.id === ele.id)
  );
  // mappingNewPrev = mappingNewPrev.filter(function (item, pos) {
  //   return mappingNewPrev.indexOf(item) == pos
  // })
  let listWillHide = [];
  mappingNewPrev.forEach((ele) => {
    let ignoreEle = false;
    next.forEach((itemNext) => {
      elementObject[`NODE_TRAVEL_${itemNext.id}`]?.forEach((r) => {
        if (r.road.source === ele?.id || r.road.target === ele?.id) {
          ignoreEle = true;
        }
      });
    });
    if (!ignoreEle) {
      let child = getElementsWillHide(ele, edges, nodes).map((item) =>
        item.id !== ele.id && item?.target !== ele?.id
          ? item
          : {
              ...item,
              data: {
                ...item.data,
                isCollapsed: true,
                parentWasHide: {},
              },
            }
      );
      listWillHide = listWillHide.concat(child);
    }
  });
  return listWillHide;
};
