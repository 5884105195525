import * as Actions from "../constants"
import storage from "redux-persist/lib/storage/session"
import { persistReducer } from "redux-persist"

const initState = {
  folderData: {},
  bannerData: [],
  loading: false,
  error: ""
}

const FolderReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_DATA_HOME_REQUEST:
      return { ...state, loading: true }
    case Actions.GET_DATA_HOME_SUCCESS:
      return {
        ...state,
        folderData: action.payload,
        loading: false
      }

    case Actions.GET_DATA_HOME_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    case Actions.GET_ASO_BANNER_REQUEST:
      return { ...state, loading: true }
    case Actions.GET_ASO_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: action.payload,
        loading: false
      }
    case Actions.GET_ASO_BANNER_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    default: {
      return state
    }
  }
}

const persistConfig = {
  key: "Folder",
  storage,
  blacklist: ["loading", "error"]
}

export default persistReducer(persistConfig, FolderReducers)
