import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "Mindmap",
  dir: "Mindmap",
  pathRoot: "mindmap",
  routes: [
    {
      url: ":id",
      component: "Page/index",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      isAuthenticate: true,
      props: {
        title: "Trang chủ | Tâm Trí Lực",
        titleI18n: "Mindmap:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
  persistBlacklist: [
    "AutoLayout",
    "MouseUsers",
    "Elements",
    "HightlightElements",
    "ElementsInteraction",
    "ElementsHistory",
    "MindMap",
    "MindMapCollections",
    "Collections",
    "PictureCollections",
    "Message",
    "RealtimeInteraction",
    "Present",
  ],
};
