const sticker03 = require("./03.svg").default
const sticker04 = require("./04.svg").default
const sticker05 = require("./05.svg").default
const sticker06 = require("./06.svg").default
const sticker07 = require("./07.svg").default
const sticker08 = require("./08.svg").default
const sticker09 = require("./09.svg").default
const sticker10 = require("./10.svg").default
const sticker11 = require("./11.svg").default
const sticker13 = require("./13.svg").default
const sticker14 = require("./14.svg").default
const sticker15 = require("./15.svg").default
const sticker16 = require("./16.svg").default
const sticker17 = require("./17.svg").default
const sticker18 = require("./18.svg").default
const sticker19 = require("./19.svg").default
const sticker20 = require("./20.svg").default
const sticker21 = require("./21.svg").default
const sticker22 = require("./22.svg").default
const sticker23 = require("./23.svg").default

export default {
  sticker03,
  sticker04,
  sticker05,
  sticker06,
  sticker07,
  sticker08,
  sticker09,
  sticker10,
  sticker11,
  sticker13,
  sticker14,
  sticker15,
  sticker16,
  sticker17,
  sticker18,
  sticker19,
  sticker20,
  sticker21,
  sticker22,
  sticker23
}
