import {
  getBezierDraggingLine,
  getCurveBezierData,
  getRootBezierDraggingLine,
} from "./curveUtils";

export const getDistance = (p1 = { x: 0, y: 0 }, p2 = { x: 0, y: 0 }) => {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
};

export const getCosAlpha = (radius = 0, pX = 0) => pX / radius;
export const getSinAlpha = (radius = 0, pY = 0) => pY / radius;

export const getAngleFromTwoPoint = (
  p1 = { x: 0, y: 0 },
  p2 = { x: 0, y: 0 }
) => {
  let diffX = p1.x - p2.x;
  let diffY = p1.y - p2.y;
  return Math.atan2(diffY, diffX);
};

export const getOffsetY = (sX, sY, tX, tY, D) => {
  let radius = getDistance({ x: sX, y: sY }, { x: tX, y: tY });
  return getSinAlpha(radius, tY) * D;
};

export const getOffsetX = (sX, sY, tX, tY, D) => {
  let radius = getDistance({ x: sX, y: sY }, { x: tX, y: tY });
  return getCosAlpha(radius, tX) * D;
};

export const getDiffAlpha = (oldPoint, newPoint, center) => {
  let oldDiffX = oldPoint.x - center.x;
  let oldDiffY = oldPoint.y - center.y;
  let newDiffX = newPoint.x - center.x;
  let newDiffY = newPoint.y - center.y;
  let oldAngel = Math.atan2(oldDiffY, oldDiffX);
  let newAngel = Math.atan2(newDiffY, newDiffX);
  return newAngel - oldAngel;
};

export const getConnectionBezierPoint = (sx, sy, tx, ty) => {
  let centerX = (sx + tx) / 2;
  let centerY = (ty + sy) / 2;
  const d = getDistance({ x: sx, y: sy }, { x: tx, y: ty }) / 4;

  if (sx < tx) {
    if (sy < ty) {
      return {
        x: centerX - d,
        y: centerY + d,
      };
    } else {
      return {
        x: centerX - d,
        y: centerY,
      };
    }
  } else {
    if (sy < ty) {
      return {
        x: centerX + d,
        y: centerY + d,
      };
    } else {
      return {
        x: centerX + d,
        y: centerY,
      };
    }
  }
};
export const getTailCurvelineAngle = (sx, sy, tx, ty, ctpS, ctpE) => {
  let curve = new Bezier(sx, sy, ctpS.x, ctpS.y, ctpE.x, ctpE.y, tx, ty);
  let tailPoint = curve.get(0.9);
  let diffY = ty - tailPoint.y;
  let diffX = tx - tailPoint.x;
  return Math.atan2(diffY, diffX);
};

export function roundPathCorners(pathString, radius, useFractionalRadius) {
  function moveTowardsLength(movingPoint, targetPoint, amount) {
    var width = targetPoint.x - movingPoint.x;
    var height = targetPoint.y - movingPoint.y;

    var distance = Math.sqrt(width * width + height * height);

    return moveTowardsFractional(
      movingPoint,
      targetPoint,
      Math.min(1, amount / distance)
    );
  }
  function moveTowardsFractional(movingPoint, targetPoint, fraction) {
    return {
      x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
      y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
    };
  }

  // Adjusts the ending position of a command
  function adjustCommand(cmd, newPoint) {
    if (cmd.length > 2) {
      cmd[cmd.length - 2] = newPoint.x;
      cmd[cmd.length - 1] = newPoint.y;
    }
  }

  // Gives an {x, y} object for a command's ending position
  function pointForCommand(cmd) {
    return {
      x: parseFloat(cmd[cmd.length - 2]),
      y: parseFloat(cmd[cmd.length - 1]),
    };
  }

  // Split apart the path, handing concatonated letters and numbers
  var pathParts = pathString.split(/[,\s]/).reduce(function (parts, part) {
    var match = part.match("([a-zA-Z])(.+)");
    if (match) {
      parts.push(match[1]);
      parts.push(match[2]);
    } else {
      parts.push(part);
    }

    return parts;
  }, []);

  // Group the commands with their arguments for easier handling
  var commands = pathParts.reduce(function (commands, part) {
    if (parseFloat(part) == part && commands.length) {
      commands[commands.length - 1].push(part);
    } else {
      commands.push([part]);
    }

    return commands;
  }, []);

  // The resulting commands, also grouped
  var resultCommands = [];

  if (commands.length > 1) {
    var startPoint = pointForCommand(commands[0]);

    // Handle the close path case with a "virtual" closing line
    var virtualCloseLine = null;
    if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
      virtualCloseLine = ["L", startPoint.x, startPoint.y];
      commands[commands.length - 1] = virtualCloseLine;
    }

    // We always use the first command (but it may be mutated)
    resultCommands.push(commands[0]);

    for (var cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
      var prevCmd = resultCommands[resultCommands.length - 1];

      var curCmd = commands[cmdIndex];

      // Handle closing case
      var nextCmd =
        curCmd == virtualCloseLine ? commands[1] : commands[cmdIndex + 1];

      // Nasty logic to decide if this path is a candidite.
      if (
        nextCmd &&
        prevCmd &&
        prevCmd.length > 2 &&
        curCmd[0] == "L" &&
        nextCmd.length > 2 &&
        nextCmd[0] == "L"
      ) {
        // Calc the points we're dealing with
        var prevPoint = pointForCommand(prevCmd);
        var curPoint = pointForCommand(curCmd);
        var nextPoint = pointForCommand(nextCmd);

        // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
        var curveStart, curveEnd;

        if (useFractionalRadius) {
          curveStart = moveTowardsFractional(
            curPoint,
            prevCmd.origPoint || prevPoint,
            radius
          );
          curveEnd = moveTowardsFractional(
            curPoint,
            nextCmd.origPoint || nextPoint,
            radius
          );
        } else {
          curveStart = moveTowardsLength(curPoint, prevPoint, radius);
          curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
        }

        // Adjust the current command and add it
        adjustCommand(curCmd, curveStart);
        curCmd.origPoint = curPoint;
        resultCommands.push(curCmd);

        // The curve control points are halfway between the start/end of the curve and
        // the original point
        var startControl = moveTowardsFractional(curveStart, curPoint, 0.5);
        var endControl = moveTowardsFractional(curPoint, curveEnd, 0.5);

        // Create the curve
        var curveCmd = [
          "C",
          startControl.x,
          startControl.y,
          endControl.x,
          endControl.y,
          curveEnd.x,
          curveEnd.y,
        ];
        // Save the original point for fractional calculations
        curveCmd.origPoint = curPoint;
        resultCommands.push(curveCmd);
      } else {
        // Pass through commands that don't qualify
        resultCommands.push(curCmd);
      }
    }

    // Fix up the starting point and restore the close path if the path was orignally closed
    if (virtualCloseLine) {
      var newStartPoint = pointForCommand(
        resultCommands[resultCommands.length - 1]
      );
      resultCommands.push(["Z"]);
      adjustCommand(resultCommands[0], newStartPoint);
    }
  } else {
    resultCommands = commands;
  }

  return resultCommands.reduce(function (str, c) {
    return str + c.join(" ") + " ";
  }, "");
}
export const twoCircleIntersection = (x0, y0, r0, x1, y1, r1) => {
  let a, dx, dy, d, h, rx, ry;
  let x2, y2;
  dx = x1 - x0;
  dy = y1 - y0;
  d = Math.sqrt(dy * dy + dx * dx);
  if (d > r0 + r1) {
    return {
      p1: {
        x: 0,
        y: 0,
      },
      p2: {
        x: 0,
        y: 0,
      },
    };
  }
  if (d < Math.abs(r0 - r1)) {
    return {
      p1: {
        x: 0,
        y: 0,
      },
      p2: {
        x: 0,
        y: 0,
      },
    };
  }
  a = (r0 * r0 - r1 * r1 + d * d) / (2 * d);
  x2 = x0 + (dx * a) / d;
  y2 = y0 + (dy * a) / d;
  h = Math.sqrt(r0 * r0 - a * a);
  rx = -dy * (h / d);
  ry = dx * (h / d);
  let xi = x2 + rx;
  let xi_prime = x2 - rx;
  let yi = y2 + ry;
  let yi_prime = y2 - ry;
  return {
    p1: {
      x: xi,
      y: yi,
    },
    p2: {
      x: xi_prime,
      y: yi_prime,
    },
  };
};
export const getBoundsOfBoxes = (box1, box2) => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
});

export const rectToBox = ({ x, y, width, height }) => ({
  x,
  y,
  x2: x + width,
  y2: y + height,
});

export const boxToRect = ({ x, y, x2, y2 }) => ({
  x,
  y,
  width: x2 - x,
  height: y2 - y,
});

export const getRectOfNodes = (nodes = []) => {
  const box = nodes.reduce(
    (currBox, { __rf: { position, width, height } = {} }) =>
      getBoundsOfBoxes(currBox, rectToBox({ ...position, width, height })),
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );
  return boxToRect(box);
};
export const getOrganicBadgePosition = (
  edge,
  sourceNode,
  targetNode,
  buttonIndex = 0
) => {
  if (!sourceNode || !targetNode) {
    return null;
  }
  let curve = null,
    curveLength = 0,
    tValue = 0.5;
  let sx, sy, tx, ty, cps, cpe;

  const HEAD_PADDING = 20;
  const ITEM_WIDTH = 80;

  sx =
    (sourceNode?.__rf?.position?.x || sourceNode.position.x) +
    (sourceNode?.__rf?.width || 0) / 2;
  sy =
    (sourceNode?.__rf?.position?.y || sourceNode.position.y) +
    (sourceNode?.__rf?.height || 0) / 2;
  tx = targetNode?.__rf?.position?.x || targetNode.position.x;
  ty = targetNode?.__rf?.position?.y || targetNode.position.y;
  if (edge?.data?.isLinear) {
    const { button1, button2 } =
      edge.source === "root"
        ? getRootBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          )
        : getBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          );
    cps = button1;
    cpe = button2;
  } else {
    cps = edge.data?.controlPoints?.[0] || { x: 0, y: 0 };
    cpe = edge.data?.controlPoints?.[1] || { x: 0, y: 0 };
  }
  curve = getCurveBezierData(sx, sy, tx, ty, cps, cpe);
  curveLength = curve.length();
  tValue = Math.max(
    0.1,
    (curveLength - HEAD_PADDING - buttonIndex * ITEM_WIDTH) / curveLength
  );
  let p = curve.get(tValue);
  return p;
};
export const angleFromTwoLine = (
  a1x = 0,
  a1y = 0,
  a2x = 0,
  a2y = 0,
  b1x = 0,
  b1y = 0,
  b2x = 0,
  b2y = 0
) => {
  let dAx = a2x - a1x;
  let dAy = a2y - a1y;
  let dBx = b2x - b1x;
  let dBy = b2y - b1y;
  let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  // if (angle < 0) {
  //   angle = angle * -1;
  // }
  return angle * (180 / Math.PI);
};
