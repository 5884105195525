import * as types from "./constants"

export function runAutoLayout(payload) {
  return {
    type: types.RUN_AUTO_LAYOUT,
    payload
  }
}

export function runEndAutoLayout(payload) {
  return {
    type: types.RUN_END_AUTO_LAYOUT,
    payload
  }
}

export function runAutoLayoutDone(payload) {
  return {
    type: types.RUN_AUTO_LAYOUT_DONE,
    payload
  }
}
export function createSpeedLayout(payload) {
  return {
    type: types.CREATE_SPEED_LAYOUT,
    payload
  }
}
