import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

import listReducers from "./Store/reducer";
import listSagas from "./Store/sagas";

export default {
  name: "Pricing",
  dir: "Pricing",
  pathRoot: "buycourse",
  routes: [
    {
      url: "",
      component: "Page/BuyCourse",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Đăng ký học STNHĐ",
        titleI18n: "BuyCourse:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  sagas: [...listSagas],
  redux: { ...listReducers },
};
