import { put, takeLatest } from "redux-saga/effects"
import * as types from "./constants"
import * as actions from "./actions"

const runAutoLayout = function* ({ payload }) {
  const { nodes, edges, lastNodeChange } = payload
  console.log("SAGAS AUTO LAYOUT", nodes, edges, lastNodeChange)
  yield put(actions.runEndAutoLayout({ nodes, edges, lastNodeChange }))
}

export default function* () {
  // yield takeLatest(types.RUN_AUTO_LAYOUT, runAutoLayout)
}
