import { takeLatest, put, select, call } from "redux-saga/effects";
import { generateFormSubmitSagas } from "@utility/StoreMethod";
import { actions, types } from "./reducer";
import * as service from "./service";
import { RETCODE_SUCCESS, SUCCESS } from "@configs/contants";
import * as Actions from "./constants";
import { getCodeLanguage } from "@store/common/selectors";

const name = "formPayment";
const nameUp = name.toUpperCase();
const sagaListFunction = generateFormSubmitSagas(name, actions, service);

export const submitForm = sagaListFunction[`${name}CallMethod`];

function* updateInvoiceSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(service.formPaymentUpdateMethod, {
      payload,
      codeLanguage,
    });
    const { data } = res;
    if (data.data.statusCode === SUCCESS && data.retCode === RETCODE_SUCCESS) {
      yield put({ type: types[`${nameUp}_SUCCESS`], payload: data.data });
    } else {
      yield put({ type: types[`${nameUp}_ERROR`], error: data.retText });
    }
  } catch (e) {
    yield put({ type: types[`${nameUp}_ERROR`], error: e });
  }
}
export default function* () {
  yield takeLatest(types[`${nameUp}_METHOD`], submitForm);
  yield takeLatest(Actions.INVOICE_UPDATE, updateInvoiceSaga);
}
