import { TEXT_ALIGN_MODE } from "../configs/constants";

function createDummyElement(text, options) {
  const element = document.createElement("div");
  const textNode = document.createTextNode(text);

  element.appendChild(textNode);

  element.style.fontFamily = options.font;
  element.style.fontSize = options.fontSize;
  element.style.fontWeight = options.fontWeight;
  element.style.lineHeight = options.lineHeight;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.left = "-999px";
  element.style.top = "-999px";
  element.style.width = options.width;
  element.style.height = "auto";
  element.style.wordBreak = options.wordBreak;

  document.body.appendChild(element);

  return element;
}

function destroyElement(element) {
  element.parentNode.removeChild(element);
}

export const getTextSize = (text, options) => {
  // prepare options
  options.font = options.font || "Times";
  options.fontSize = options.fontSize || "16px";
  options.fontWeight = options.fontWeight || "normal";
  options.lineHeight = options.lineHeight || "normal";
  options.width = options.width || "auto";
  options.wordBreak = options.wordBreak || "normal";

  const element = createDummyElement(text, options);
  const size = {
    width: element.offsetWidth,
    height: element.offsetHeight,
  };
  destroyElement(element);

  return size;
};

export const getTextWidth = (text, fontSize, fontFamily = "arial") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `bold ${fontSize || 14}px ${fontFamily}`;
  return context.measureText(text).width;
};
export const getTextPosition = (
  position,
  targetX,
  sourceX,
  data,
  id,
  sourcePos,
  targetPos,
  sourceY,
  targetY,
  isFlatEdge,
  fontSize,
  rootWidth = 0,
  fontFamily = "arial",
  initLength = 0
) => {
  const isReverse = sourceX >= targetX;
  const textWidth = getTextWidth(data?.label, fontSize, fontFamily);
  const path = document.getElementById(id);
  if ((!path || !path?.getTotalLength) && !initLength) {
    return 0;
  }

  let pathLength = initLength ? initLength : path.getTotalLength();

  if (!isFlatEdge) {
    if (id?.includes("edge_connect")) {
      return pathLength / 2 - textWidth / 2;
    }
    if (!isReverse) {
      return pathLength - textWidth;
    } else {
      return pathLength - textWidth;
    }
  }
  pathLength /= 2;
  if (isReverse) {
    return pathLength + 15;
  }
  return pathLength - textWidth - rootWidth - 25;
};
