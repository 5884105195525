import { CRUD_MODES } from "../../configs/constants"
import { createAction, handleActions } from "redux-actions"
import { reGenerateNewCopyId } from "../../utils/flowUtils"

export const types = {
  COPY_ELEMENTS: "COPY_ELEMENTS",
  PASTE_ELEMENTS: "PASTE_ELEMENTS",
  SAVE_CUT_ELEMENTS: "SAVE_CUT_ELEMENTS"
}

export const actions = {
  copyElement: createAction(types.COPY_ELEMENTS),
  pasteElement: createAction(types.PASTE_ELEMENTS),
  saveCutElement: createAction(types.SAVE_CUT_ELEMENTS)
}

const defaultState = {
  copyElements: null,
  sourceRootCopiedElements: null,
  edgesBeforeCut: null,
  nodesBeforeCut: null,
  mode: "",
  sourceRootCutElements: null,
  nodeSelectedToCopied: null,
  multipleCopiedData: null,
  oldDirection: null
}

export default handleActions(
  {
    [types.COPY_ELEMENTS]: (state, { payload }) => {
      return {
        ...state,
        copyElements: payload?.listElements || [],
        sourceRootCopiedElements: payload?.root || null,
        mode: CRUD_MODES.COPY,
        nodeSelectedToCopied: payload?.select,
        multipleCopiedData: payload?.multipleElementCopied,
        oldDirection: payload?.oldDirection || null
      }
    },
    [types.PASTE_ELEMENTS]: (state, { payload }) => {
      if (Object.keys(state.multipleCopiedData || {}).length) {
        let newElements = reGenerateNewCopyId(state.copyElements)
        let newMultipleCopiedData = {}
        for (let key in state.multipleCopiedData) {
          let newEle = reGenerateNewCopyId(
            state.multipleCopiedData[key].listElements
          )
          newMultipleCopiedData[key] = {
            ...state.multipleCopiedData[key],
            listElements: newEle
          }
        }
        return {
          ...state,
          copyElements: newElements,
          multipleCopiedData: newMultipleCopiedData
        }
      } else {
        let newElements = reGenerateNewCopyId(state.copyElements)

        return {
          ...state,
          copyElements: newElements
        }
      }
    },
    [types.SAVE_CUT_ELEMENTS]: (state, { payload }) => {
      return {
        ...state,
        edgesBeforeCut: payload.edges || null,
        nodesBeforeCut: payload.nodes || null,
        sourceRootCutElements: payload.root,
        mode: CRUD_MODES.CUT,
        nodeSelectedToCopied: payload?.select
      }
    }
  },
  defaultState
)
