import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

export default {
  name: "MindmapPublic",
  dir: "MindmapPublic",
  pathRoot: "interval-mindmap",
  routes: [
    {
      url: ":id",
      component: "Page/index",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "MindmapPublic | Tâm Trí Lực",
        titleI18n: "MindmapPublic:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
};
