import API from "@Modules/Folder/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getFolderData = async (codeLanguage = "vi-VN") => {
	try {
		const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
		return data;
	} catch (e) {
		return Promise.reject(e);
	}
};

export const getBanner = async () => {
	try {
		const { data } = await apiMethod.get(`${API.GET_ASO_BANNER}`);
		return data;
	} catch (e) {
		return Promise.reject(e);
	}
};

export const getListMindmapCategory = (payload) => {
	return apiMethod.post(API.GET_LIST_MINDMAP_CATEGORY, payload);
};
export const deleteListMindmapCategory = (payload) => {
	return apiMethod.delete(API.DELETE_LIST_MINDMAP_CATEGORY, {
		data: payload,
	});
};

export const postFilterListMindmap = (payload) => {
	return apiMethod.post(API.POST_FILTER_MINDMAP, payload);
};
