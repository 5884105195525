import { createAction, handleActions } from "redux-actions";
import _ from "lodash";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
export const types = {
  SET_LIST_COMMENT: "SET_LIST_COMMENT",
  UPDATE_NODE_COMMENT: "UPDATE_NODE_COMMENT",
  SET_INITIAL_: "SET_INITIAL_",
  SET_LIST_FILE_TO_SHOW: "SET_LIST_FILE_TO_SHOW",
};

export const actions = {
  setListComment: createAction(types.SET_LIST_COMMENT),
  updateNodeComment: createAction(types.UPDATE_NODE_COMMENT),
  setListFileToShowModal: createAction(types.SET_LIST_FILE_TO_SHOW),
};

const defaultState = {
  listComment: null,
  fileToShow: null,
};

const reducer = handleActions(
  {
    [types.SET_LIST_COMMENT]: (state, { payload }) => {
      return {
        ...state,
        listComment: payload,
      };
    },
    [types.UPDATE_NODE_COMMENT]: (state, { payload }) => {
      let newList = _.cloneDeep(state.listComment);
      newList[payload.id] = payload.value;
      return {
        ...state,
        listComment: newList,
      };
    },
    [types.SET_LIST_FILE_TO_SHOW]: (state, { payload }) => {
      return {
        ...state,
        fileToShow: payload,
      };
    },
  },
  defaultState
);

const persistConfig = {
  key: "Comments",
  storage,
  blacklist: ["listComment", "fileToShow"],
};

export default persistReducer(persistConfig, reducer);
