import API from "@configs/api"
import apiMethod from "@utility/ApiMethod"

export const getListService = ({ payload }) => {
  const { page, limit, id } = payload
  return apiMethod.post(
    `${API.POST_GET_LIST_NOTES_BY_MINDMAPID}?limit=${limit}&page=${page}&infoMindmapId=${id}`
  )
}
export const postCreateNote = (payload) => {
  return apiMethod.post(API.POST_CREATE_NOTES, payload)
}
export const putUpdateNote = (payload) => {
  return apiMethod.put(API.PUT_UPDATE_NOTES, payload)
}
export const postDeleteNote = (payload) => {
  return apiMethod.delete(API.DELETE_NOTES, {
    data: {
      ...payload
    }
  })
}
