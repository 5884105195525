import { Bezier } from "./lib/Bezier";
import { ShapeInfo, Intersection } from "kld-intersections";
import {
  getBezierDraggingLine,
  getCurveBezier,
  getCurveBezierData,
  getRootBezierDraggingLine,
  isApproximately,
} from "./curveUtils";
import {
  findAllChildNode,
  findAllChildNodeWithData,
  updateChildrenNodePosition,
  flatternArray,
  updateChildrenNodePositionV2,
  updateAllChildPositionV3,
} from "./flowUtils";
import { getDistance } from "./mathUtils";
import { isEdge } from "../../../components/ReactFlow/dist/ReactFlow.esm";
import { getTextSize, getTextWidth } from "./curveTextUtils";
const getPushingNodeParent = (sourceNodeRoad = [], targetNodeRoad = []) => {
  let foundNodeId = null;
  for (let i = 0; i < sourceNodeRoad.length; i++) {
    let flag = false;
    for (let j = 0; j < targetNodeRoad.length; j++) {
      if (sourceNodeRoad[i]?.road.source === targetNodeRoad[j]?.road.source) {
        foundNodeId = {
          target: targetNodeRoad[j]?.road.target,
          clockNode: sourceNodeRoad[i]?.road.target,
          d: targetNodeRoad[j]?.d,
          source: targetNodeRoad[j]?.road.source,
        };
        flag = true;
      }
    }
    if (flag) {
      break;
    }
  }
  return foundNodeId;
};

const getIsNearEdge = (point, edge, sourceNode, targetNode) => {
  try {
    let sx, sy, tx, ty, cps, cpe;

    sx = sourceNode.position.x;
    sy = sourceNode.position.y;
    tx = targetNode.position.x;
    ty = targetNode.position.y;

    cps = edge?.data?.controlPoints?.[0] || edge?.data?.bezier_start_point;
    cpe = edge?.data?.controlPoints?.[1] || edge?.data?.bezier_end_point;
    if (edge.data?.isLinear) {
      const { button1, button2 } =
        edge.source === "root"
          ? getRootBezierDraggingLine(
              {
                x: sx + (sourceNode.__rf.width || 0) / 2,
                y: sy + (sourceNode.__rf.height || 0) / 2,
              },
              {
                x: tx,
                y: ty,
              }
            )
          : getBezierDraggingLine(
              {
                x: sx,
                y: sy,
              },
              {
                x: tx,
                y: ty,
              }
            );
      cps = button1;
      cpe = button2;
    }

    // if(isApproximately(sx,tx,4) )

    let curve = null;

    if (Math.abs(sx - cps?.x) <= 2 && Math.abs(sy - cps?.y) <= 2) {
      curve = new Bezier(sx, sy, cpe?.x, cpe?.y, tx, ty);
    } else {
      curve = new Bezier(sx, sy, cps?.x, cps?.y, cpe?.x, cpe?.y, tx, ty);
    }
    let cLength = curve.length();
    let textWidth =
      getTextWidth(
        edge?.data?.label || "",
        edge.data?.fontSize,
        edge.data?.fontFamily
      ) * 1.2 || 0;
    let minTValue = Math.max(0.1, (cLength - textWidth) / cLength);
    let p = curve.project(point);
    if (p.t >= minTValue) {
      return p;
    }
    return {
      d: 0,
    };
  } catch (err) {
    return {
      d: 0,
    };
  }
};

const getEdgePath = (edge, sourceNode, targetNode) => {
  let sx, sy, tx, ty, cps, cpe;

  sx = sourceNode.position.x;
  sy = sourceNode.position.y;
  tx = targetNode.position.x;
  ty = targetNode.position.y;
  if (edge.data?.isLinear) {
    const { button1, button2 } =
      sourceNode?.source === "root"
        ? getRootBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          )
        : getBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          );
    cps = button1;
    cpe = button2;
  } else {
    cps = edge?.data?.controlPoints?.[0] || edge?.data?.bezier_start_point;
    cpe = edge?.data?.controlPoints?.[1] || edge?.data?.bezier_end_point;
  }
  return getCurveBezier(sx, sy, tx, ty, cps, cpe);
};
const getCurve = (edge, sourceNode, targetNode) => {
  let sx, sy, tx, ty, cps, cpe;
  sx = sourceNode.position.x;
  sy = sourceNode.position.y;
  tx = targetNode.position.x;
  ty = targetNode.position.y;
  if (edge.data?.isLinear) {
    const { button1, button2 } =
      edge?.source === "root"
        ? getRootBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          )
        : getBezierDraggingLine(
            {
              x: sx,
              y: sy,
            },
            {
              x: tx,
              y: ty,
            }
          );
    cps = button1;
    cpe = button2;
  } else {
    cps = edge?.data?.controlPoints?.[0] || edge?.data?.bezier_start_point;
    cpe = edge?.data?.controlPoints?.[1] || edge?.data?.bezier_end_point;
  }
  return getCurveBezierData(sx, sy, tx, ty, cps, cpe);
};

export const getEdgeIntersections = (edge1, edge2, sn1, sn2, tn1, tn2) => {
  if (!sn1 || !sn2 || !tn1 || !tn2) {
    return [];
  }
  if (
    edge1.source === edge2.source ||
    edge1.source === edge2.target ||
    edge2.source === edge1.target
  ) {
    return [];
  }
  try {
    let path1 = ShapeInfo.path(getEdgePath(edge1, sn1, tn1).path);
    let path2 = ShapeInfo.path(getEdgePath(edge2, sn2, tn2).path);
    const intersections = Intersection.intersect(path1, path2);
    return intersections?.points || [];
  } catch (err) {
    return [];
  }
};
const getEdgeBoundingBox = (edge1, edge2, sn1, sn2, tn1, tn2) => {
  if (!sn1 || !sn2 || !tn1 || !tn2) {
    return [];
  }
  if (
    edge1.source === edge2.source ||
    edge1.source === edge2.target ||
    edge2.source === edge1.target
  ) {
    return [];
  }
  let curve1 = getCurve(edge1, sn1, tn1);
  let curve2 = getCurve(edge2, sn2, tn2);

  return {
    bb1: curve1.bbox(),
    bb2: curve2.bbox(),
  };
};
const isConnectionLine = (edge) =>
  edge?.id?.includes("connect") || edge?.id?.includes("image");
const blacklistNode = (node) =>
  node?.id?.includes("invisible") || node?.id?.includes("image");

const getIntersectionPushingOffset = (sy, ty, bb1, bb2) => {
  if (!bb1 || !bb2 || !sy || !ty) {
    return 0;
  }
  let b1min, b1max, b2min, b2max;
  b1min = bb1.y.min;
  b1max = bb1.y.max;
  b2min = bb2.y.min;
  b2max = bb2.y.max;

  if (
    Math.min(b1min, b1max, b2min, b2max) === b1min &&
    Math.max(b1min, b1max, b2min, b2max) === b1max
  ) {
    return {
      up: Math.abs(b1min - b2min) + Math.abs(b2max - b2min),
      down: Math.abs(b1max - b2max) + Math.abs(b2max - b2min),
      d:
        Math.abs(Math.max(Math.abs(b1min - b2min), Math.abs(b1max - b2max))) +
        Math.abs(b2max - b2min),
    };
  }
  if (
    Math.min(b1min, b1max, b2min, b2max) === b2min &&
    Math.max(b1min, b1max, b2min, b2max) === b2max
  ) {
    return {
      up: Math.abs(b1max - b2max) + Math.abs(b1max - b1min),
      down: Math.abs(b1min - b2min) + Math.abs(b1max - b1min),
      d:
        Math.abs(Math.max(Math.abs(b1min - b2min), Math.abs(b1max - b2max))) +
        Math.abs(b1max - b1min),
    };
  }
  if (Math.min(b1min, b1max, b2min, b2max) === b1min) {
    return { up: 0, down: 0, d: Math.abs(b2min - b1max) };
  }
  return { up: 0, down: 0, d: Math.abs(b1min - b2max) };
};
const getIsTopPushDirection = (sy1 = 0, ty1 = 0, sy2 = 0, ty2 = 0) => {
  console.log("PUSHHHHH", sy1, ty1, sy2, ty2);
  if (ty1 >= ty2) return true;
  return false;
};
const getDefaultDistance = (edge) => {
  if (!edge?.data?.sourceLevel) {
    return 35;
  }
  switch (edge?.data?.sourceLevel) {
    case 1: {
      return 60;
    }
    case 2: {
      return 40;
    }
    case 3: {
      return 25;
    }
    default: {
      return 25;
    }
  }
};
export const findNearestNode = (
  checkingNode,
  elementsObject,
  listNodes = [],
  blackList = [],
  whiteListElementObject
) => {
  let sourceEdge = elementsObject[`TARGET_${checkingNode.id}`];
  let sourceRoot = elementsObject[sourceEdge?.source];
  let defaultDistance = getDefaultDistance(sourceEdge);
  let foundItems = listNodes.map((item) => {
    if (
      whiteListElementObject &&
      Object.keys(whiteListElementObject || {}).length
    ) {
      if (!whiteListElementObject[item.id]) {
        return null;
      }
    }

    if (item.id === "root") {
      return null;
    }
    if (blackList.findIndex((ele) => ele?.id === item?.id) !== -1) {
      return null;
    }
    if (
      elementsObject[`NODE_TRAVEL_${checkingNode.id}`]?.some(
        (ele) => ele?.road?.source === item.id || ele?.road?.target === item.id
      )
    ) {
      return null;
    }
    if (blacklistNode(checkingNode) || blacklistNode(item)) {
      return null;
    }
    let edgeTarget = elementsObject[`TARGET_${item.id}`];
    let sourceNodeOfEdgeTarget = elementsObject[edgeTarget.source];
    let projectionOfPushNodeOnEdge = getIsNearEdge(
      checkingNode.position,
      edgeTarget,
      elementsObject[`${edgeTarget.source}`],
      elementsObject[`${edgeTarget.target}`]
    );
    let projectionOfCheckingNodeOnSourceEdge = getIsNearEdge(
      item.position,
      sourceEdge,
      elementsObject[`${sourceEdge.source}`],
      elementsObject[`${sourceEdge.target}`]
    );
    let distanceToEdge = isConnectionLine(edgeTarget)
      ? 0
      : projectionOfPushNodeOnEdge.d;

    let distanceFromSourceEdgeToCheckingNode = isConnectionLine(sourceEdge)
      ? 0
      : projectionOfCheckingNodeOnSourceEdge.d;
    let isNearEdge =
      distanceToEdge && Math.abs(distanceToEdge) < defaultDistance;
    let isNearNode =
      distanceFromSourceEdgeToCheckingNode &&
      Math.abs(distanceFromSourceEdgeToCheckingNode) < defaultDistance;

    let d = getDistance(checkingNode.position, item.position);
    if (d === 0) {
      d = 1;
    }
    let listPointIntersections =
      isConnectionLine(sourceEdge) || isConnectionLine(edgeTarget)
        ? []
        : getEdgeIntersections(
            sourceEdge,
            edgeTarget,
            sourceRoot,
            sourceNodeOfEdgeTarget,
            checkingNode,
            item
          );
    let distanceIntersectionPointsToEdge = 0;

    if (listPointIntersections.length) {
      const { bb1, bb2 } = getEdgeBoundingBox(
        sourceEdge,
        edgeTarget,
        sourceRoot,
        sourceNodeOfEdgeTarget,
        checkingNode,
        item
      );

      distanceIntersectionPointsToEdge = getIntersectionPushingOffset(
        sourceRoot.position.y,
        checkingNode.position.y,
        bb1,
        bb2
      );
      // console.log("DISTANCE INTERSECTION P", distanceIntersectionPointsToEdge)
    }
    if (
      isNearNode ||
      isNearEdge ||
      Math.abs(distanceIntersectionPointsToEdge.d) > 0 ||
      d < defaultDistance
    ) {
      if (d > defaultDistance) {
        d = 0;
      }
      if (distanceFromSourceEdgeToCheckingNode > defaultDistance) {
        distanceFromSourceEdgeToCheckingNode = 0;
      }
      if (distanceToEdge > defaultDistance) {
        distanceToEdge = 0;
      }
      d = d ? defaultDistance - d : 0;
      distanceToEdge = distanceToEdge ? defaultDistance - distanceToEdge : 0;
      distanceFromSourceEdgeToCheckingNode =
        distanceFromSourceEdgeToCheckingNode
          ? defaultDistance - distanceFromSourceEdgeToCheckingNode
          : 0;
      if (Math.abs(distanceIntersectionPointsToEdge.d) > 0) {
        return {
          ...item,
          distance: distanceIntersectionPointsToEdge.d,
          up: distanceIntersectionPointsToEdge.up,
          down: distanceIntersectionPointsToEdge.down,
        };
      }
      return {
        ...item,
        distance: Math.max(
          d,
          distanceToEdge,
          distanceFromSourceEdgeToCheckingNode
        ),
      };
    }
    return null;
  });
  return foundItems;
};

export const findAllChildNodeV2 = (
  currentNode,
  elementsObject,
  removeCurrentNode,
  sortVertical = "",
  sortFromParentToChild = false
) => {
  if (!currentNode) {
    return [];
  }
  let allChildEdge = elementsObject[`SOURCE_${currentNode.id}`];

  if (allChildEdge && allChildEdge?.length) {
    if (sortVertical === "DOWN") {
      allChildEdge = allChildEdge.sort((a, b) => {
        let aNode = elementsObject[a.target];
        let bNode = elementsObject[b.target];
        let aY = aNode?.__rf?.position?.y || aNode?.position?.y;
        let bY = bNode?.__rf?.position?.y || bNode?.position?.y;
        return aY - bY;
      });
    }
    if (sortVertical === "UP") {
      allChildEdge = allChildEdge.sort((a, b) => {
        let aNode = elementsObject[a.target];
        let bNode = elementsObject[b.target];
        let aY = aNode?.__rf?.position?.y || aNode?.position?.y;
        let bY = bNode?.__rf?.position?.y || bNode?.position?.y;
        return bY - aY;
      });
    }
    if (removeCurrentNode) {
      return flatternArray([
        ...allChildEdge.map((edge) => {
          return findAllChildNodeV2(
            elementsObject[edge.target],
            elementsObject,
            false,
            sortVertical,
            sortFromParentToChild
          );
        }),
      ]);
    }
    if (sortFromParentToChild) {
      return flatternArray([
        currentNode,
        ...allChildEdge.map((edge) => {
          return findAllChildNodeV2(
            elementsObject[edge.target],
            elementsObject,
            false,
            sortVertical,
            sortFromParentToChild
          );
        }),
      ]);
    }
    return flatternArray([
      ...allChildEdge.map((edge) => {
        return findAllChildNodeV2(
          elementsObject[edge.target],
          elementsObject,
          false,
          sortVertical,
          sortFromParentToChild
        );
      }),
      currentNode,
    ]);
  } else {
    return [currentNode];
  }
};

export const getNodeTravel = (node, elementsObject = {}) => {
  let flag = true;
  let currentNode = node;
  let nodeTravel = [];
  if (!node) {
    return null;
  }
  while (flag && currentNode?.id !== "root") {
    let foundEdge = elementsObject[`TARGET_${currentNode.id}`];
    if (foundEdge) {
      nodeTravel.push({ road: foundEdge });
      currentNode = {
        id: foundEdge.source,
      };
    } else {
      flag = false;
    }
  }
  return nodeTravel;
};
export const prepareData = (nodes = [], edges = []) => {
  const elementsObject = {};
  edges.forEach((item) => {
    let mappingTargetID = `TARGET_${item.target}`;
    let mappingSourceID = `SOURCE_${item.source}`;
    elementsObject[item.id] = item;
    elementsObject[mappingTargetID] = item;
    if (elementsObject?.[mappingSourceID]) {
      elementsObject[mappingSourceID] = [
        ...elementsObject[mappingSourceID],
        item,
      ];
    } else {
      elementsObject[mappingSourceID] = [item];
    }
  });
  nodes.forEach((item) => {
    elementsObject[item.id] = item;
  });
  nodes.forEach((item) => {
    elementsObject[`NODE_TRAVEL_${item.id}`] = getNodeTravel(
      item,
      elementsObject
    );
  });
  return elementsObject;
};
export const prepareDataForElement = (elements = []) => {
  const elementsObject = {};
  elements.forEach((item) => {
    if (isEdge(item || {})) {
      let mappingTargetID = `TARGET_${item.target}`;
      let mappingSourceID = `SOURCE_${item.source}`;
      elementsObject[item.id] = item;
      elementsObject[mappingTargetID] = item;
      if (elementsObject?.[mappingSourceID]) {
        elementsObject[mappingSourceID] = [
          ...elementsObject[mappingSourceID],
          item,
        ];
      } else {
        elementsObject[mappingSourceID] = [item];
      }
    } else {
      elementsObject[item.id] = item;
    }
  });
  return elementsObject;
};
const getHighestPushDistance = (nodes = []) => {
  let max = 0;
  let maxUp = 0;
  let maxDown = 0;
  for (let i = 0; i <= nodes.length; i++) {
    if (Math.abs(max) <= Math.abs(nodes[i]?.distance)) {
      max = nodes[i].distance;
    }
    if (Math.abs(maxUp) <= Math.abs(nodes[i]?.up)) {
      maxUp = nodes[i].up;
    }
    if (Math.abs(maxDown) <= Math.abs(nodes[i]?.down)) {
      maxDown = nodes[i].down;
    }
  }
  return { max, maxUp, maxDown };
};
export const getElementsInLayoutingArea = (
  nodePush,
  edges = [],
  nodes = []
) => {
  try {
    let rootNode,
      rootPos,
      isLeft,
      nodesResults = {},
      edgesResults,
      elementsObject;
    rootNode = nodes.find((node) => node.id === "root");
    rootPos = {
      x: rootNode.__rf.position.x + (rootNode.__rf.width || 0) / 2,
      y: rootNode.__rf.position.y + (rootNode.__rf.height || 0) / 2,
    };

    elementsObject = prepareData(nodes, edges);
    let rootNodeTravel = elementsObject[`NODE_TRAVEL_${nodePush.id}`]?.find(
      (ele) => ele.road.source === "root"
    );
    let parentNode = elementsObject[rootNodeTravel?.road?.target];
    isLeft =
      (parentNode?.__rf?.position.x || parentNode?.position?.x) < rootPos.x;

    if (isLeft) {
      nodes.forEach((node) => {
        if (node.id === "root") {
          nodesResults[node.id] = node;
        } else if (
          node?.data?.level === 1 &&
          node.__rf.position.x < rootPos.x
        ) {
          let allChildNode = findAllChildNodeV2(node, elementsObject, true);
          nodesResults[node.id] = node;
          allChildNode.forEach((ele) => {
            nodesResults[ele.id] = ele;
          });
        }
      });
    } else {
      nodes.forEach((node) => {
        if (node.id === "root") {
          nodesResults[node.id] = node;
        } else if (
          node?.data?.level === 1 &&
          node.__rf.position.x >= rootPos.x
        ) {
          let allChildNode = findAllChildNodeV2(node, elementsObject, true);
          nodesResults[node.id] = node;
          allChildNode.forEach((ele) => {
            nodesResults[ele.id] = ele;
          });
        }
      });
    }

    edgesResults = edges.filter(
      (ele) =>
        Object.values(nodesResults || {}).findIndex(
          (node) =>
            node.id === ele.target ||
            (node.id === ele.source && node.id !== "root")
        ) !== -1
    );
    return {
      nodesResults: Object.values(nodesResults || {}),
      edgesResults,
      isLeft,
    };
  } catch (err) {
    return {
      nodesResults: [],
      edgesResults: [],
      isLeft: false,
    };
  }
};
const checkingEdgeStillIntersect = (
  nodePush,
  listNode = [],
  listEdge = [],
  blackList = [],
  isDown = false,
  listNodeToFindNearest = [],
  nodeToCheck,
  allChildOfTargetNodeObject,
  listNodeToFindNearestObject
) => {
  let listNearestNode, elementsObject;
  elementsObject = prepareData(listNode, listEdge);
  listNearestNode = listNodeToFindNearest
    ?.map((item) =>
      findNearestNode(
        item,
        elementsObject,
        listNode,
        [...blackList, ...listNodeToFindNearest],
        allChildOfTargetNodeObject
      )
    )
    ?.filter((item) => item);

  listNearestNode = flatternArray(listNearestNode)?.filter((item) => item);
  const highestDistance = getHighestPushDistance(listNearestNode);
  listNearestNode = listNearestNode?.filter((v, i, a) => {
    let idx = a.findIndex((v2) => v2.id === v.id);
    if (idx === i) {
      return true;
    }
    if (listNearestNode[idx]?.distance >= listNearestNode[i]?.distance) {
      listNearestNode[i].distance = listNearestNode[idx]?.distance;
    }
    return false;
  });
  if (!listNearestNode || !listNearestNode?.length) {
    return 0;
  }
  let DELTA = isDown
    ? Math.abs(highestDistance.maxDown || highestDistance.max)
    : -Math.abs(highestDistance.maxUp || highestDistance.max);
  return DELTA;
};

const autoLayoutV2 = (
  nodePush,
  listNode = [],
  listEdge = [],
  blackList = [],
  isDragging = true,
  level = 1,
  isDown = "isDown"
) => {
  let listNearestNode,
    listNodePushed,
    nodeChildren,
    listNearestNodeRoad,
    listParentNodeToPush,
    currentNodeRoad,
    listParentNodeToPushRemoveDupplicate,
    elementsObject;

  elementsObject = prepareData(listNode, listEdge);

  listNodePushed = [];
  //GET ALL CHILD NODE TO CHECK IF THEY ARE OVERLAP OTHERS
  nodeChildren = findAllChildNodeV2(nodePush, elementsObject, true);
  //THE ROAD MAP FROM CURRENT NODE TO ROOT
  currentNodeRoad = elementsObject[`NODE_TRAVEL_${nodePush.id}`];
  //LIST NEAREST NODE FROM EACH CHILD NODE
  listNearestNode = [...nodeChildren, nodePush]
    .map((item) =>
      findNearestNode(item, elementsObject, listNode, [
        ...blackList,
        nodePush,
        ...nodeChildren,
      ])
    )
    ?.filter((item) => item);

  listNearestNode = flatternArray(listNearestNode)?.filter((item) => item);
  const highestDistance = getHighestPushDistance(listNearestNode);
  listNearestNode = listNearestNode?.filter((v, i, a) => {
    let idx = a.findIndex((v2) => v2.id === v.id);
    if (idx === i) {
      return true;
    }
    if (listNearestNode[idx]?.distance >= listNearestNode[i]?.distance) {
      listNearestNode[i].distance = listNearestNode[idx]?.distance;
    }
    return false;
  });

  listNearestNodeRoad = listNearestNode
    ?.map((item) => elementsObject[`NODE_TRAVEL_${item?.id}`])
    ?.filter((item) => item);

  //LIST PARENT NODE TO PUSH
  listParentNodeToPush = listNearestNodeRoad
    .map((item) => getPushingNodeParent(currentNodeRoad, item))
    ?.filter((item) => item);

  //REMOVE DUPPLICATE PARENT NODE TO PUSH
  listParentNodeToPushRemoveDupplicate = listParentNodeToPush
    ?.filter(function (item, pos) {
      return listParentNodeToPush.indexOf(item) == pos;
    })
    ?.filter((item) => {
      let allChildNode = findAllChildNodeV2(item.target, elementsObject, true);
      if (allChildNode.findIndex((ele) => ele === nodePush.id) !== -1) {
        return false;
      }
      return true;
    });
  if (!listParentNodeToPushRemoveDupplicate.length) {
    return [];
  }
  let newList = [];
  listNodePushed = listParentNodeToPushRemoveDupplicate.map((item) => {
    let clockNode = elementsObject[item?.clockNode];
    let targetNode = elementsObject[item?.target];
    let sourceEdge = elementsObject[`TARGET_${targetNode.id}`];
    let sourceNode = elementsObject[sourceEdge.source];
    let allChildOfTargetNodeObject = {};
    let allChildOfTargetNode = findAllChildNodeV2(
      targetNode,
      elementsObject,
      true
    );
    allChildOfTargetNode?.forEach((ele) => {
      allChildOfTargetNodeObject[ele.id] = ele;
    });
    const IS_GO_DOWN =
      isDown === "isDown"
        ? clockNode.position.y < targetNode.position.y
        : isDown;
    const MAX_PUSH_DISTANCE = 200;
    let DELTA = IS_GO_DOWN
      ? Math.min(
          MAX_PUSH_DISTANCE,
          Math.abs(highestDistance.maxDown || highestDistance.max)
        )
      : -Math.min(
          MAX_PUSH_DISTANCE,
          Math.abs(highestDistance.maxUp || highestDistance.max)
        );

    let { edgesResultObject, nodesResultObject, listResults } =
      updateAllChildPositionV3(elementsObject, targetNode, 0, DELTA, true);
    let listNodeUpdated = listNode.map((ele) => {
      let foundNode = nodesResultObject[ele.id];
      if (foundNode) {
        return foundNode;
      }
      return ele;
    });

    let listEdgeUpdated = listEdge.map((ele) => {
      let foundNode = edgesResultObject[ele.id];
      if (foundNode) {
        return foundNode;
      }
      return ele;
    });
    let listNodeToFindNearestObject = {};
    let temp = [...nodeChildren, nodePush];
    temp.forEach((ele) => {
      listNodeToFindNearestObject[ele.id] = ele;
    });

    const LOOP_LIMIT_DEFAULT = 0;

    let currentLoopIndex = 0;
    while (currentLoopIndex <= LOOP_LIMIT_DEFAULT) {
      let newDistance = checkingEdgeStillIntersect(
        nodePush,
        listNodeUpdated,
        listEdgeUpdated,
        blackList,
        IS_GO_DOWN,
        [...nodeChildren, nodePush],
        targetNode,
        allChildOfTargetNodeObject,
        listNodeToFindNearestObject
      );
      if (newDistance === 0) {
        currentLoopIndex = LOOP_LIMIT_DEFAULT + 1;
        break;
      }
      if (IS_GO_DOWN) {
        DELTA += Math.min(Math.abs(newDistance), MAX_PUSH_DISTANCE);
      } else {
        DELTA -= Math.min(Math.abs(newDistance), MAX_PUSH_DISTANCE);
      }

      let {
        edgesResultObject: edgesResult,
        nodesResultObject: nodesResult,
        listResults: latestResults,
      } = updateAllChildPositionV3(elementsObject, targetNode, 0, DELTA, true);
      listResults = latestResults;
      listNodeUpdated = listNode.map((ele) => {
        let foundNode = nodesResult?.[ele.id];
        if (foundNode) {
          return foundNode;
        }
        return ele;
      });
      listEdgeUpdated = listEdge.map((ele) => {
        let foundNode = edgesResult?.[ele.id];
        if (foundNode) {
          return foundNode;
        }
        return ele;
      });
      ++currentLoopIndex;
    }

    let listUpdatedNext = autoLayoutV2(
      {
        ...targetNode,
        position: {
          x: targetNode.position.x,
          y: targetNode.position.y + DELTA,
        },
      },
      listNodeUpdated,
      listEdgeUpdated,
      [
        ...blackList,
        ...findAllChildNodeV2(item.target, elementsObject, true),
        ...nodeChildren,
        nodePush,
        elementsObject[item.target],
      ],
      isDragging,
      level + 1,
      IS_GO_DOWN
    );

    newList = [...newList, ...listResults, ...listUpdatedNext];
    return item;
  });
  return newList;
};
export default autoLayoutV2;
