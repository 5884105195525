import axios from "axios";
import { BASE_MONGO_URL } from "@configs/api";

const axiosCreate = axios.create({
  baseURL: BASE_MONGO_URL,
});

axiosCreate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
axiosCreate.interceptors.request.use((request) => {
  return request;
});

export default axiosCreate;
