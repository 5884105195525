import { getDiffAlpha, getDistance } from "./mathUtils"
import { Intersection, ShapeInfo } from "kld-intersections"
import { Bezier } from "./lib/Bezier"
import {
  getBezierDraggingLine,
  getCurveBezier,
  getRootBezierDraggingLine,
  getTaperedBezier
} from "./curveUtils"
import { LINE_SHAPES } from "../configs/constants"

export const AutoLayoutMain = (
  nodes,
  edges,
  lastNodeChange,
  blackNodes = {},
  limitLoop = 0
) => {
  let blackNodesTemp = blackNodes
  let limitLoopTemp = limitLoop + 1
  let edgesGroupBySourceNode = {}
  let edgesGroupByTargetNode = {}
  let edgesGroupById = {}
  let edgesGroupByIdWithIndex = {}
  let nodesGroupById = {}

  let resultNodes = {}
  let resultEdges = {}

  //Index Before auto layout
  for (const i in nodes) {
    const nodeItem = nodes[i]
    nodesGroupById[nodeItem.id] = {
      index: i,
      node: nodeItem
    }
  }

  for (const i in edges) {
    const edgeItem = edges[i]
    //Index Edges
    if (edgesGroupBySourceNode[edgeItem.source] === undefined) {
      edgesGroupBySourceNode[edgeItem.source] = []
    }
    edgesGroupBySourceNode[edgeItem.source].push(edgeItem)

    edgesGroupByTargetNode[edgeItem.target] = edgeItem
    edgesGroupById[edgeItem.id] = edgeItem
    edgesGroupByIdWithIndex[edgeItem.id] = i

    //Index Nodes
    if (nodesGroupById[edgeItem.source]["child"] === undefined) {
      nodesGroupById[edgeItem.source]["child"] = []
    }

    nodesGroupById[edgeItem.source]["child"].push({
      index: nodesGroupById[edgeItem.target].index,
      node: nodesGroupById[edgeItem.target].node
    })

    nodesGroupById[edgeItem.target]["parent"] = edgeItem.source
  }

  let listNearestNode = {}
  let highestDistance = 0
  const currentNodeRoad = getNodeRoad(
    lastNodeChange,
    nodesGroupById,
    edgesGroupByTargetNode
  )

  for (const i in nodes) {
    if (nodes[i].id !== "root" && blackNodes[nodes[i].id] === undefined) {
      const result = getNearestNodeChild(
        lastNodeChange.id,
        edgesGroupByTargetNode,
        nodesGroupById,
        0,
        nodes[i],
        {},
        currentNodeRoad
      )
      listNearestNode = { ...listNearestNode, ...result.list }
      if (highestDistance < result.highestDistance) {
        highestDistance = result.highestDistance
      }
    }
  }

  Object.values(listNearestNode).map((item) => {
    const result = updatePosition(
      item.pushingNodeParent,
      nodesGroupById,
      edgesGroupByTargetNode,
      edgesGroupById,
      edgesGroupBySourceNode,
      highestDistance,
      resultNodes,
      resultEdges
    )
    resultNodes = { ...resultNodes, ...result.nodes }
    resultEdges = { ...resultEdges, ...result.edges }
  })

  if (Object.values(resultNodes).length > 0) {
    Object.values(resultNodes).map((item) => {
      nodes[nodesGroupById[item.id]["index"]] = {
        ...item
      }
    })
    Object.values(resultEdges).map((item) => {
      edges[edgesGroupByIdWithIndex[item.id]] = {
        ...item
      }
    })

    Object.values(resultNodes).map((item) => {
      blackNodesTemp[lastNodeChange.id] = lastNodeChange
      if (limitLoopTemp < 20) {
        const result = AutoLayoutMain(
          nodes,
          edges,
          item,
          blackNodesTemp,
          limitLoopTemp
        )
        resultNodes = { ...result.resultNodes, ...resultNodes }
        resultEdges = { ...result.resultEdges, ...resultEdges }
      }
    })
  }
  return { resultNodes, resultEdges }
}

export const getNearestNodeChild = (
  idCheck,
  edgesGroupByTargetNode,
  nodesGroupById,
  highestDistance = 0,
  item,
  resultCheck = {},
  currentNodeRoad
) => {
  let defaultDistance = 60
  let highestDistanceTemp = highestDistance
  let result = resultCheck

  const nodeCenterItem = nodesGroupById[idCheck]
  const nodeCenter = nodeCenterItem?.node
  let sourceEdge = edgesGroupByTargetNode?.[nodeCenter?.id]
  let sourceRoot = nodesGroupById[sourceEdge.source].node

  let edgeTarget = edgesGroupByTargetNode[item.id]
  if (edgeTarget.data.level === 1) {
    if (edgeTarget.data.align === "CENTER") {
      defaultDistance = 100
    } else if (edgeTarget.data.align === "LEFT") {
      defaultDistance = 150
    } else if (edgeTarget.data.align === "RIGHT") {
      defaultDistance = 120
    } else {
      defaultDistance = 100
    }
  } else if (edgeTarget.data.level > 1) {
    if (edgeTarget.data.align === "CENTER") {
      defaultDistance = 60
    } else if (edgeTarget.data.align === "LEFT") {
      defaultDistance = 80
    } else {
      defaultDistance = 40
    }
  }

  let distanceToEdge = getIsNearEdge(
    nodeCenter.position,
    edgeTarget,
    defaultDistance
  )

  let isNearEdge = distanceToEdge && distanceToEdge < defaultDistance

  let d = getDistance(nodeCenter.position, item.position)

  let sourceNodeOfEdgeTarget = nodesGroupById[edgeTarget.source].node

  let listPointIntersections = getEdgeIntersections(
    sourceEdge,
    edgeTarget,
    sourceRoot,
    sourceNodeOfEdgeTarget,
    nodeCenter,
    item
  )
  // console.log("LONG LIST POINT INTERSEC", listPointIntersections)
  let distanceIntersectionPointsToEdge = 0

  if (listPointIntersections.length) {
    distanceIntersectionPointsToEdge = getDistance(
      item.position,
      listPointIntersections[0]
    )
  }

  if (
    (isNearEdge ||
      distanceIntersectionPointsToEdge > 0 ||
      d < defaultDistance) &&
    idCheck !== item.id &&
    item.id !== "root"
  ) {
    if (d > defaultDistance) {
      d = 0
    }
    if (distanceToEdge > defaultDistance) {
      distanceToEdge = 0
    }
    const distance = Math.max(
      d,
      distanceToEdge,
      distanceIntersectionPointsToEdge
    )

    if (result[item.id] !== undefined) {
      if (distance > result[item.id].distance) {
        result[item.id].distance = distance
      }
    } else {
      const nodeRoad = getNodeRoad(
        {
          ...item,
          distance: distance
        },
        nodesGroupById,
        edgesGroupByTargetNode
      )

      const pushingNodeParent = getPushingNodeParent(currentNodeRoad, nodeRoad)

      result[item.id] = {
        ...item,
        distance: distance,
        currentNodeRoad: currentNodeRoad,
        pushingNodeParent: pushingNodeParent
      }
    }

    if (highestDistanceTemp < distance) {
      highestDistanceTemp = distance
    }
  }

  if (nodeCenterItem["child"] !== undefined) {
    for (const i in nodeCenterItem["child"]) {
      const resultChild = getNearestNodeChild(
        nodeCenterItem["child"][i]["node"].id,
        edgesGroupByTargetNode,
        nodesGroupById,
        highestDistanceTemp,
        item,
        result,
        currentNodeRoad
      )
      result = { ...result, ...resultChild.list }
      if (highestDistanceTemp < resultChild.highestDistance) {
        highestDistanceTemp = resultChild.highestDistance
      }
    }
  }
  return { highestDistance: highestDistanceTemp, list: result }
}

export const updatePosition = (
  item,
  nodesGroupById,
  edgesGroupByTargetNode,
  edgesGroupById,
  edgesGroupBySourceNode,
  highestDistance,
  nodesCheck,
  edgesCheck
) => {
  let clockNode = nodesGroupById[item?.clockNode].node
  let targetNode = nodesGroupById[item?.target].node
  let sourceEdge = edgesGroupByTargetNode[targetNode.id]
  let sourceNode = nodesGroupById[sourceEdge.source].node

  let sourcePosition = {
    ...sourceNode.position
  }

  if (sourceNode.id === "root") {
    sourcePosition.x += (sourceNode?.__rf?.width || 150) / 2
    sourcePosition.y += (sourceNode?.__rf?.height || 100) / 2
  }

  const diffX = targetNode.position.x - sourcePosition.x
  const diffY = targetNode.position.y - sourcePosition.y

  const diffXClockNode = clockNode.position.x - sourcePosition.x
  const diffYClockNode = clockNode.position.y - sourcePosition.y

  const distanceTargetNode = getDistance(targetNode.position, sourcePosition)

  const angleTargetNode = Math.atan2(diffY, diffX)
  const angleClockNode = Math.atan2(diffYClockNode, diffXClockNode)

  let resultAngle = 0
  let newX = 0
  let newY = 0
  let bonusDistance = 0

  resultAngle = getAngleOffset(angleClockNode, angleTargetNode, 1, item)

  newX =
    (distanceTargetNode + bonusDistance) * Math.cos(resultAngle) +
    (sourcePosition.x + (sourceNode.id === "root"))
  newY =
    (distanceTargetNode + bonusDistance) * Math.sin(resultAngle) +
    sourcePosition.y

  let resultNodesTemp = { ...nodesCheck }

  resultNodesTemp[targetNode.id] = {
    ...targetNode,
    position: {
      x: newX,
      y: newY
    },
    data: {
      ...targetNode?.data
    }
  }
  let resultEdgesTemp = { ...edgesCheck }

  let oldAngel = getAngleOffset(angleClockNode, angleTargetNode, 1, item)

  let isReverseX =
    (Math.abs(oldAngel) < Math.PI / 2 && Math.abs(resultAngle) > Math.PI / 2) ||
    (Math.abs(oldAngel) > Math.PI / 2 && Math.abs(resultAngle) < Math.PI / 2)
  let isReverseY =
    (oldAngel > 0 && resultAngle < 0) || (oldAngel < 0 && resultAngle > 0)

  return updateChildPosition(
    targetNode,
    {
      x: newX,
      y: newY
    },
    nodesGroupById,
    edgesGroupByTargetNode,
    edgesGroupById,
    edgesGroupBySourceNode,
    sourceNode?.__rf,
    resultNodesTemp,
    resultEdgesTemp,
    sourceEdge,
    isReverseX,
    isReverseY,
    1
  )
}

export const updateChildPosition = (
  parentNode,
  positionNew,
  nodesGroupById,
  edgesGroupByTargetNode,
  edgesGroupById,
  edgesGroupBySourceNode,
  sourceRf,
  nodesCheck,
  edgesCheck,
  sourceEdge = null,
  isReverseX = false,
  isReverseY = false,
  level
) => {
  const nodeHasChild = nodesGroupById[parentNode.id]
  let listEdge = edgesGroupBySourceNode[parentNode.id]
  let result = { ...nodesCheck }
  let resultEdges = { ...edgesCheck }
  if (listEdge !== undefined) {
    listEdge = [sourceEdge, ...listEdge]
  } else {
    listEdge = [sourceEdge]
  }
  listEdge.map((item) => {
    if (item === null || resultEdges[item.id]) {
      return
    }
    let itemHistory = edgesGroupById[item.id]
    let button1 = { x: 0, y: 0 }
    let button2 = { x: 0, y: 0 }
    let newControlPoints = []
    let newShowPoints = []

    let oldSourceNode = nodesGroupById[item.source].node
    let oldTargetNode = nodesGroupById[item.target].node

    let tempPoint = oldSourceNode?.position || { x: 0, y: 0 }

    let centerPoint = {
      x: tempPoint.x + (sourceRf?.width / 2 || 0),
      y: tempPoint.y + (sourceRf?.height / 2 || 0)
    }

    let distanceFromDragNodeToCenterOfArc = getDistance(
      positionNew,
      centerPoint
    )
    let distanceFromDragNodeHistoryToCenterOfArc = getDistance(
      parentNode.position,
      centerPoint
    )
    let ratioDragDistance =
      distanceFromDragNodeToCenterOfArc /
      distanceFromDragNodeHistoryToCenterOfArc

    const deltaAlpha = getDiffAlpha(parentNode.position, positionNew, {
      x: centerPoint.x,
      y: centerPoint.y
    })

    let oldControlPoints = itemHistory?.data?.controlPoints
    let oldShowPoints = itemHistory?.data?.showPoints
    const isFromRoot = item?.source === "root"

    if (
      item?.data?.isLinear &&
      level === 1 &&
      item?.target === parentNode?.id
    ) {
      const isFromRoot = item?.source === "root"
      let { button1, button2 } = getBezierDraggingLine(
        {
          x: centerPoint.x,
          y: centerPoint.y
        },
        positionNew
      )

      if (isFromRoot) {
        // console.log("IS FROM ROOT", tempPoint, centerPoint)
        let rootData = getRootBezierDraggingLine(
          {
            x: centerPoint.x,
            y: centerPoint.y
          },
          positionNew
        )
        button1 = rootData.button1
        button2 = rootData.button2
      }
      newControlPoints = [...(item?.data?.controlPoints || [])].map(
        (ele, index, arr) => {
          return {
            x: index === 0 ? button1.x : button2.x,
            y: index === 0 ? button1.y : button2.y
          }
        }
      )
      newShowPoints = [...(item?.data?.showPoints || [])].map(
        (ele, index, arr) => {
          const newBezierData =
            item?.type === LINE_SHAPES.TAPERED_LINE
              ? getTaperedBezier(
                  centerPoint.x,
                  centerPoint.y,
                  positionNew.x,
                  positionNew.y,
                  button1,
                  button2,
                  1,
                  1
                )
              : getCurveBezier(
                  centerPoint.x,
                  centerPoint.y,
                  positionNew.x,
                  positionNew.y,
                  button1,
                  button2,
                  1,
                  1
                )

          return {
            x:
              index === 0
                ? newBezierData.controlPointStart.x
                : newBezierData.controlPointEnd.x,
            y:
              index === 0
                ? newBezierData.controlPointStart.y
                : newBezierData.controlPointEnd.y
          }
        }
      )
    } else {
      if (
        (isReverseX || isReverseY) &&
        !item?.data?.visibleTarget &&
        item.source === parentNode.id
      ) {
        //FLIP CONTROL POINTS
        newControlPoints = [...(item?.data?.controlPoints || [])].map(
          (ele, index, arr) => {
            return {
              x: isReverseX
                ? positionNew.x +
                  (parentNode.position.x - oldControlPoints[index].x)
                : oldControlPoints[index].x -
                  (parentNode?.position?.x - positionNew.x),
              y: isReverseY
                ? positionNew.y +
                  (parentNode.position.y - oldControlPoints[index].y)
                : oldControlPoints[index].y -
                  (parentNode?.position?.y - positionNew.y)
            }
          }
        )
        // FLIP SHOW POINTS
        newShowPoints = [...(item?.data?.showPoints || [])].map(
          (ele, index, arr) => {
            return {
              x: isReverseX
                ? positionNew.x +
                  (parentNode.position.x - oldShowPoints[index].x)
                : oldShowPoints[index].x -
                  (parentNode?.position?.x - positionNew.x),
              y: isReverseY
                ? positionNew.y +
                  (parentNode.position.y - oldShowPoints[index].y)
                : oldShowPoints[index].y -
                  (parentNode?.position?.y - positionNew.y)
            }
          }
        )
        // newShowPoints = newControlPoints
      } else {
        newControlPoints = [...(item?.data?.controlPoints || [])].map(
          (ele, index, arr) => {
            let diffX = oldControlPoints[index]?.x - centerPoint.x
            let diffY = oldControlPoints[index]?.y - centerPoint.y
            let alpha = Math.atan2(diffY, diffX) + deltaAlpha
            let distanceFromControlPointToCenterOfArc =
              getDistance(
                {
                  x: oldControlPoints[index].x,
                  y: oldControlPoints[index].y
                },
                centerPoint
              ) * ratioDragDistance
            let newX = distanceFromControlPointToCenterOfArc * Math.cos(alpha)
            let newY = distanceFromControlPointToCenterOfArc * Math.sin(alpha)
            if (item?.target === parentNode?.id) {
              return {
                x: newX + centerPoint.x,
                y: newY + centerPoint.y
              }
            }
            return {
              x:
                oldControlPoints[index]?.x -
                (parentNode?.position?.x - positionNew.x),
              y:
                oldControlPoints[index]?.y -
                (parentNode?.position?.y - positionNew.y)
            }
          }
        )
        newShowPoints = [...(item?.data?.showPoints || [])].map(
          (ele, index, arr) => {
            let diffX = oldShowPoints[index]?.x - centerPoint.x
            let diffY = oldShowPoints[index]?.y - centerPoint.y
            let alpha = Math.atan2(diffY, diffX) + deltaAlpha

            let distanceFromControlPointToCenterOfArc =
              getDistance(
                {
                  x: oldShowPoints[index].x,
                  y: oldShowPoints[index].y
                },
                centerPoint
              ) * ratioDragDistance
            let newX = distanceFromControlPointToCenterOfArc * Math.cos(alpha)
            let newY = distanceFromControlPointToCenterOfArc * Math.sin(alpha)
            if (item?.target === parentNode?.id && level === 1) {
              return {
                x: newX + centerPoint.x,
                y: newY + centerPoint.y
              }
            }
            return {
              x:
                oldShowPoints[index]?.x -
                (parentNode?.position?.x - positionNew.x),
              y:
                oldShowPoints[index]?.y -
                (parentNode?.position?.y - positionNew.y)
            }
          }
        )
      }
    }
    resultEdges[item.id] = {
      ...item,
      data: {
        ...item?.data,
        previousEdgePosition: {
          ...itemHistory?.data,
          targetX: oldTargetNode.position.x,
          targetY: oldTargetNode.position.y,
          sourceX:
            oldSourceNode.position.x +
            (isFromRoot ? oldSourceNode?.__rf?.width || 150 : 0) / 2,
          sourceY:
            oldSourceNode.position.y +
            (isFromRoot ? oldSourceNode?.__rf?.height || 100 : 0) / 2
        },
        bezier_start_point: button1,
        bezier_end_point: button2,
        controlPoints: newControlPoints,
        showPoints: newShowPoints,
        targetX: positionNew.x,
        targetY: positionNew.y,
        sourceX: centerPoint.x,
        sourceY: centerPoint.y
      }
    }
  })
  if (nodeHasChild["child"] !== undefined) {
    nodeHasChild["child"].map((item) => {
      if (result[item.id]) {
        return
      }
      const node = item.node
      let newX = node?.position?.x - (parentNode?.position?.x - positionNew.x)
      let newY = node?.position?.y - (parentNode?.position?.y - positionNew.y)

      result[node.id] = {
        ...node,
        position: {
          x: isReverseX ? positionNew.x - (newX - positionNew.x) : newX,
          y: isReverseY ? positionNew.y - (newY - positionNew.y) : newY
        },
        data: {
          ...node?.data
        }
      }

      const resultLoop = updateChildPosition(
        node,
        {
          x: newX,
          y: newY
        },
        nodesGroupById,
        edgesGroupByTargetNode,
        edgesGroupById,
        edgesGroupBySourceNode,
        parentNode?.__rf,
        result,
        resultEdges,
        null,
        isReverseX,
        isReverseY,
        level + 1
      )
      result = { ...result, ...resultLoop.nodes }
      resultEdges = { ...resultEdges, ...resultLoop.edges }
    })
  }
  return { nodes: result, edges: resultEdges }
}

export const getAngleOffset = (
  clockAngle,
  moveAngle,
  loopValue = 1,
  item = null
) => {
  console.log("getAngleOffset")
  let rate = 1
  if (item) {
    if (item.d > 0) {
      if (item.source === "root") {
        rate = 1 + 120 / item.d
      } else {
        rate = 1 + 50 / item.d
      }
    }
  }
  let resultAngle = 0
  // const DELTA_ANGLE = (Math.PI / 60) * loopValue
  const DELTA_ANGLE = 0.05 * loopValue * rate

  if (clockAngle < 0) {
    if (moveAngle < 0) {
      if (Math.abs(clockAngle) < Math.abs(moveAngle)) {
        resultAngle = moveAngle - DELTA_ANGLE
      } else {
        resultAngle = moveAngle + DELTA_ANGLE
      }
    } else {
      if (moveAngle < Math.PI / 2) {
        resultAngle = moveAngle + DELTA_ANGLE
      } else {
        resultAngle = moveAngle - DELTA_ANGLE
      }
    }
  } else {
    if (moveAngle < 0) {
      if (clockAngle < Math.PI / 2) {
        resultAngle = moveAngle - DELTA_ANGLE
      } else {
        resultAngle = moveAngle + DELTA_ANGLE
      }
    } else {
      if (Math.abs(clockAngle) < Math.abs(moveAngle)) {
        resultAngle = moveAngle + DELTA_ANGLE
      } else {
        resultAngle = moveAngle - DELTA_ANGLE
      }
    }
  }
  if (resultAngle > Math.PI) {
    return -Math.PI + (resultAngle - Math.PI)
  }
  if (resultAngle < -Math.PI) {
    return Math.PI - (resultAngle + Math.PI)
  }
  return resultAngle
}

const getEdgeIntersections = (edge1, edge2, sn1, sn2, tn1, tn2) => {
  if (
    edge1.source === edge2.source ||
    edge1.source === edge2.target ||
    edge2.source === edge1.target
  ) {
    return []
  }
  let path1 = ShapeInfo.path(getEdgePath(edge1, sn1, tn1).path)
  let path2 = ShapeInfo.path(getEdgePath(edge2, sn2, tn2).path)
  const intersections = Intersection.intersect(path1, path2)
  return intersections?.points || []
}

const getIsNearEdge = (point, edge, inputDistance) => {
  let sx, sy, tx, ty, cps, cpe

  sx = edge?.data.sourceX
  sy = edge?.data.sourceY
  tx = edge?.data.targetX
  ty = edge?.data.targetY
  cps = edge?.data?.controlPoints?.[0] || edge?.data?.bezier_start_point
  cpe = edge?.data?.controlPoints?.[1] || edge?.data?.bezier_end_point

  let curve = null

  if (Math.abs(sx - cps?.x) <= 2 && Math.abs(sy - cps?.y) <= 2) {
    curve = new Bezier(sx, sy, cpe?.x, cpe?.y, tx, ty)
  } else {
    curve = new Bezier(sx, sy, cps?.x, cps?.y, cpe?.x, cpe?.y, tx, ty)
  }
  let p = curve.project(point)
  if (p.t <= 0.8 && p.t >= 0.2) {
    return p.d
  }
  return null
}

const getEdgePath = (edge, sourceNode, targetNode) => {
  let sx, sy, tx, ty, cps, cpe
  sx = sourceNode.position.x
  sy = sourceNode.position.y
  tx = targetNode.position.x
  ty = targetNode.position.y
  cps = edge?.data?.controlPoints?.[0] || edge?.data?.bezier_start_point
  cpe = edge?.data?.controlPoints?.[1] || edge?.data?.bezier_end_point
  return getCurveBezier(sx, sy, tx, ty, cps, cpe)
}

export const getNodeRoad = (node, nodesGroupById, edgesGroupByTargetNode) => {
  let nodeRoad = []
  nodeRoad.push({
    road: edgesGroupByTargetNode[node.id],
    d: node.distance === undefined ? 0 : node.distance
  })
  if (
    nodesGroupById[node.id]?.["parent"] !== undefined &&
    nodesGroupById[node.id]["parent"] !== "root"
  ) {
    nodeRoad = [
      ...nodeRoad,
      ...getNodeRoad(
        nodesGroupById[nodesGroupById[node.id]["parent"]].node,
        nodesGroupById,
        edgesGroupByTargetNode
      )
    ]
  }
  return nodeRoad
}

const getPushingNodeParent = (sourceNodeRoad = [], targetNodeRoad = []) => {
  let foundNodeId = null
  for (let i = 0; i < sourceNodeRoad.length; i++) {
    let flag = false
    for (let j = 0; j < targetNodeRoad.length; j++) {
      if (sourceNodeRoad[i]?.road.source === targetNodeRoad[j]?.road.source) {
        foundNodeId = {
          target: targetNodeRoad[j]?.road.target,
          clockNode: sourceNodeRoad[i]?.road.target,
          d: targetNodeRoad[j]?.d,
          source: targetNodeRoad[j]?.road.source
        }
        flag = true
      }
    }
    if (flag) {
      break
    }
  }
  return foundNodeId
}
