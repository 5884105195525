import API from "@configs/api";
import API_AUTH from "@Modules/Authenticate/configs/api";
import apiMethod from "@utility/ApiMethod";
import apiMethodSTNHD from "@utility/ApiMethodSTNHD";
import { APP_ID, APP_ID_VMINDMAP } from "../../../../configs/contants";

export const signIn = (payload) => {
  return apiMethodSTNHD.post(API_AUTH.AUTH_LOGIN, {
    ...payload,
    appID: APP_ID,
  });
};
export const signIn5ptb = (payload) => {
  return apiMethodSTNHD.post(API_AUTH.AUTH_LOGIN, {
    ...payload,
    appID: "5PTB",
  });
};

export const syncInfo = (payload) => {
  return apiMethod.post(API.SYNC_INFO, payload);
};

export const sendOtp = ({ codeLanguage, payload }) => {
  return apiMethodSTNHD.post(codeLanguage + API.POST_SEND_OTP, {
    phoneNumber: payload,
    appID: APP_ID,
  });
};
export const sendOtpEmail = ({ email, culture }) => {
  return apiMethodSTNHD.post(API.SEND_OTP_REGISTER_EMAIL, {
    email: email,
    culture: culture,
    appID: APP_ID,
  });
};

export const forgotPasswordCallMethod = (payload, { isEmailOTP }) => {
  if (isEmailOTP) {
    return apiMethodSTNHD.post(API.POST_AUTH_FORGOT_PASSWORD_EMAIL, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
  } else
    return apiMethodSTNHD.post(API.POST_AUTH_FORGOT_PASSWORD, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
};
export const sendOtpForgotPasswordMethod = (payload, { isEmailOTP }) => {
  if (isEmailOTP) {
    return apiMethodSTNHD.post(API.SEND_OTP_FOGET_PASSWORD_EMAIL, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
  }
  return apiMethodSTNHD.post(API.SEND_OTP_FOGET_PASSWORD, {
    ...payload,
    appID: APP_ID_VMINDMAP,
  });
};
export const sendOtpForgotPasswordFireBase = (payload) => {
  return apiMethodSTNHD.post(API.SEND_OTP_FOGET_PASSWORD_FIRE_BASE, {
    ...payload,
    appID: APP_ID_VMINDMAP,
  });
};
export const sendOtpForgotPasswordUserNameFireBase = (payload) => {
  return apiMethodSTNHD.post(API.SEND_OTP_FOGET_PASSWORD_USERNAME_FIRE_BASE, {
    ...payload,
    appID: APP_ID_VMINDMAP,
  });
};
export const forgotUserCallMethod = (payload, { isEmailOTP }) => {
  if (isEmailOTP) {
    return apiMethodSTNHD.post(API.POST_FORGET_USERNAME_EMAIL, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
  } else
    return apiMethodSTNHD.post(API.POST_FORGET_USERNAME, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
};
export const sendOtpForgotUserMethod = (payload, { isEmailOTP }) => {
  if (isEmailOTP) {
    return apiMethodSTNHD.post(API.SEND_OTP_FORGET_USERNAME_EMAIL, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
  } else
    return apiMethodSTNHD.post(API.SEND_OTP_FORGET_USERNAME, {
      ...payload,
      appID: APP_ID_VMINDMAP,
    });
};

// export const confirmOtp = ({ codeLanguage, payload }) => {
//   return apiMethodSTNHD.post(codeLanguage + API_AUTH.POST_CONFIRM_OTP, {
//     phone: payload.phone,
//     otp: payload.otp,
//   });
// };
export const confirmOtpRegisterEmail = ({ codeLanguage, payload }) => {
  return apiMethodSTNHD.post(
    codeLanguage + API_AUTH.POST_CONFIRM_OTP_REGISTER_EMAIL,
    {
      email: payload.email,
      fullName: payload.fullName,
      password: payload.password,
      phone: payload.phone,
      otp: payload.otp,
      cityId: payload.cityId,
      districtId: payload.districtId,
      address: payload.address,

      appID: APP_ID,
    }
  );
};
export const confirmOtpRegisterFirebase = ({ codeLanguage, payload }) => {
  return apiMethodSTNHD.post(
    codeLanguage + API_AUTH.POST_SIGN_UP_TRIAL_ACCOUNT,
    {
      email: payload.email,
      fullName: payload.fullName,
      password: payload.password,
      phone: payload.phone,
      token: payload.token,
      uid: payload.uid,
      otp: payload.otp,
      cityId: payload.cityId,
      districtId: payload.districtId,
      address: payload.address,
      appID: APP_ID,
      child1_ClassId: 0,
    }
  );
};

export const syncUserInfo = (payload) => {
  return apiMethod.post(API.POST_SYNC_USER_INFO, payload);
};

export const registerSTNHD = ({ codeLanguage, payload }) => {
  return apiMethodSTNHD.post(codeLanguage + API_AUTH.POST_REGISTER_DAISUSTNHD, {
    ...payload,
    appID: APP_ID,
  });
};

export const loginSocial = async (payload) => {
  try {
    const { data } = await apiMethod.post(API_AUTH.LOGIN_SOCIAL, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPrivateList = async () => {
  try {
    const { data } = await apiMethod.get(`${API.LIST_PRIVATE}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getServiceList = async () => {
  try {
    const { data } = await apiMethod.get(`${API.LIST_SERVICE}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getUserInfo = (payload) => {
  return apiMethod.post(API_AUTH.GET_USER_INFO, payload);
};
export const getUserInfoSTNHD = (payload) => {
  return apiMethodSTNHD.get(
    API_AUTH.GET_PROFILE_STNHD + `?userName=${payload?.userName}`
  );
};
export const updateFlagViewTutorial = (payload) => {
  return apiMethod.put(API_AUTH.UPDATE_FLAG_VIEW_TUTORIAL, payload);
};
// export const checkEmailInSystem = (payload) => {
//   return apiMethod.put(API_AUTH.CHECK_EMAIL_IN_SYSTEM, payload);
// };

export const checkEmailInSystem = async ({ payload, codeLanguage }) => {
  try {
    const { data } = await apiMethodSTNHD.post(
      codeLanguage + `${API_AUTH.CHECK_EMAIL_IN_SYSTEM}?email=${payload.email}`,
      { ...payload, appID: APP_ID }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const registerTrialAccount = async ({ payload, codeLanguage }) => {
  try {
    const { data } = await apiMethodSTNHD.post(
      codeLanguage + `${API_AUTH.POST_SIGN_UP_TRIAL_ACCOUNT}`,
      { ...payload, appID: APP_ID }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOptionsOtpOption = async () => {
  return apiMethodSTNHD.get(
    API.GET_OTP_OPTION_VMINDMAP + `?code=${"app_vmindmap"}`
  );
};
