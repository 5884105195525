import { createAction, handleActions } from "redux-actions";

export const types = {
  SET_REALTIME_INTERACTION_DATA: "SET_REALTIME_INTERACTION_DATA",
  REMOVE_ONLINE_MEMBER_BY_CLIENT_ID: "REMOVE_ONLINE_MEMBER_BY_CLIENT_ID",
  CLEAR_REALTIME_INTERACTION_DATA: "CLEAR_REALTIME_INTERACTION_DATA",
};

export const actions = {
  setRealtimeInteractionData: createAction(types.SET_REALTIME_INTERACTION_DATA),
  removeOnlineMembersByClientIds: createAction(
    types.REMOVE_ONLINE_MEMBER_BY_CLIENT_ID
  ),
  clearRealtimeInteractionData: createAction(
    types.CLEAR_REALTIME_INTERACTION_DATA
  ),
};

export const defaultState = {
  elementsDisabled: null,
  isStartDragRealtime: false,
  usersInteracting: null,
  elementDataEdited: null,
  onlineUsers: {},
};

export default handleActions(
  {
    [types.SET_REALTIME_INTERACTION_DATA]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [types.REMOVE_ONLINE_MEMBER_BY_CLIENT_ID]: (state, { payload }) => {
      let newObj = {
        ...state.onlineUsers,
      };
      delete newObj[payload];
      return {
        ...state,
        onlineUsers: newObj,
      };
    },
    [types.CLEAR_REALTIME_INTERACTION_DATA]: (state, { payload }) => {
      return defaultState;
    },
  },
  defaultState
);
