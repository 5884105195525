import * as Actions from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PAGE_DEFAULT, LIMIT_DEFAULT } from '@configs/contants';

const initState = {
  levelList: [],
  teacherListByLevel: [],
  teacherListSearch: [],
  levelTabListUpgradeAccountByLevel: [],
  levelTabListUpgradeAccountSearch: [],
  pageIndexUpgradeAccountByLevel: PAGE_DEFAULT,
  pageSizeUpgradeAccountByLevel: LIMIT_DEFAULT,
  totalRowsUpgradeAccountByLevel: 0,
  totalPageUpgradeAccountByLevel: 0,
  pageIndexUpgradeAccountSearch: PAGE_DEFAULT,
  pageSizeUpgradeAccountSearch: LIMIT_DEFAULT,
  totalRowsUpgradeAccountSearch: 0,
  totalPageUpgradeAccountSearch: 0,
  loadingUpgradeAccountByLevel: false,
  loadingUpgradeAccountSearch: false,
  loadingLevelList: false,
  levelCodeUpgradeAccountByLevel: undefined,
  levelCodeUpgradeAccountSearch: undefined,
  error: '',
};

const UpgradeAccountReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_LEVEL_LIST_REQUEST:
      return { ...state, loadingLevelList: true };
    case Actions.GET_LEVEL_LIST_SUCCESS:
      return {
        ...state,
        levelList: action.payload,
        loadingLevelList: false,
      };
    case Actions.GET_LEVEL_LIST_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingLevelList: false,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_REQUEST:
      return { ...state, loadingUpgradeAccountByLevel: true };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_SUCCESS:
      return {
        ...state,
        levelTabListUpgradeAccountByLevel: action.payload.levelTabList,
        teacherListByLevel: action.payload.teacherList,
        loadingUpgradeAccountByLevel: false,
        pageIndexUpgradeAccountByLevel: action.payload.paging.curPage,
        pageSizeUpgradeAccountByLevel: action.payload.paging.perPage,
        totalRowsUpgradeAccountByLevel: action.payload.paging.totalRows,
        totalPageUpgradeAccountByLevel: action.payload.paging.totalPage,
        levelCodeUpgradeAccountByLevel: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingUpgradeAccountByLevel: false,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_REQUEST:
      return { ...state, loadingUpgradeAccountSearch: true };
    case Actions.GET_TEACHER_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        levelTabListUpgradeAccountSearch: action.payload.levelTabList,
        teacherListSearch: action.payload.teacherList,
        loadingUpgradeAccountSearch: false,
        pageIndexUpgradeAccountSearch: action.payload.paging?.curPage,
        pageSizeUpgradeAccountSearch: action.payload.paging?.perPage,
        totalRowsUpgradeAccountSearch: action.payload.paging?.totalRows,
        totalPageUpgradeAccountSearch: action.payload.paging?.totalPage,
        levelCodeUpgradeAccountSearch: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingUpgradeAccountSearch: false,
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'UpgradeAccount',
  storage,
  blacklist: ['loading', 'error'],
};

export default persistReducer(persistConfig, UpgradeAccountReducers);
