import storage from "redux-persist/lib/storage/session"
import { persistReducer } from "redux-persist"
import * as types from "./constants"

import {
  autoSpeedLayout,
  autoSpeedLayoutEachSide
} from "../../utils/autoSpeedLayout"
import { AutoLayoutMain } from "../../utils/autoLayout"
import autoLayoutV2 from "../../utils/autoLayoutV2"
import { isNode } from "../../../../components/ReactFlow/dist/ReactFlow.esm"

const initState = {
  nodesAutoLayout: [],
  edgesAutoLayout: [],
  nodesAuto: {},
  edgesAuto: {}
}

const AutoLayoutReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case types.RUN_AUTO_LAYOUT:
      let { nodes, edges, lastNodeChange } = action.payload
      if (lastNodeChange === undefined || lastNodeChange === null) {
        return {
          ...state,
          nodesAuto: {},
          edgesAuto: {}
        }
      }
      nodes = nodes.map((item) => ({
        ...item,
        position: {
          x: item?.__rf?.position?.x || item?.position?.x,
          y: item?.__rf?.position?.y || item?.position?.y
        }
      }))
      const elementLayouted = autoLayoutV2(
        lastNodeChange,
        nodes,
        edges,
        [],
        true,
        1
      )
      if (!elementLayouted || !elementLayouted?.length) {
        return {
          ...state,
          nodesAuto: { isEmpty: true },
          edgesAuto: { isEmpty: true }
        }
      }
      let nodesAuto = {}
      let edgesAuto = {}
      const { nextEdges, nextNodes } = elementLayouted.reduce(
        (prev, cur) => {
          if (!cur.id?.includes("edge")) {
            return {
              nextNodes: [...prev.nextNodes, cur],
              nextEdges: prev.nextEdges
            }
          } else {
            return {
              nextEdges: [...prev.nextEdges, cur],
              nextNodes: prev.nextNodes
            }
          }
        },
        {
          nextEdges: [],
          nextNodes: []
        }
      )
      nextNodes.forEach((node) => {
        nodesAuto[node.id] = node
      })
      nextEdges.forEach((edge) => {
        edgesAuto[edge.id] = edge
      })
      if (Object.keys(nodesAuto).length === 0) {
        return {
          ...state,
          nodesAutoLayout: [...nodes],
          edgesAutoLayout: [...edges],
          nodesAuto: { isEmpty: true },
          edgesAuto: { isEmpty: true }
        }
      }
      return {
        ...state,
        nodesAutoLayout: [...nodes],
        edgesAutoLayout: [...edges],
        nodesAuto: nodesAuto,
        edgesAuto: edgesAuto
      }
    case types.CREATE_SPEED_LAYOUT: {
      let { nodes, edges } = action.payload
      nodes = nodes.map((item) => ({
        ...item,
        position: {
          x: item?.__rf?.position?.x || item?.position?.x,
          y: item?.__rf?.position?.y || item?.position?.y
        }
      }))
      let newNodes, newEdges
      if (
        action.payload?.lastNodeChange &&
        action.payload?.lastNodeChange?.id !== "root"
      ) {
        let data = autoSpeedLayoutEachSide(
          action.payload?.lastNodeChange,
          nodes,
          edges
        )
        newNodes = data.nodes
        newEdges = data.edges
      } else {
        let data = autoSpeedLayout(nodes, edges, null, false)
        newNodes = data.nodes
        newEdges = data.edges
      }

      let nodesAuto = {}
      let edgesAuto = {}
      newNodes.forEach((node) => {
        nodesAuto[node.id] = {
          ...node,
          __rf: {
            ...node.__rf,
            position: node.position
          }
        }
      })
      newEdges.forEach((edge) => {
        edgesAuto[edge.id] = edge
      })
      return {
        ...state,
        nodesAuto: nodesAuto,
        edgesAuto: edgesAuto,
        elementLayouted: [...newNodes, ...newEdges]
      }
    }

    case types.RUN_AUTO_LAYOUT_DONE:
      return {
        ...state,
        nodesAuto: {},
        edgesAuto: {}
      }
    default:
      return state
  }
}

const persistConfig = {
  key: "AutoLayout",
  storage
}

export default persistReducer(persistConfig, AutoLayoutReducer)
