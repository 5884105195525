import {
  ENV_ENVIRONMENT_BASE_URL_API,
  ENVIRONMENT_STNHD_BASE_URL_API,
  ENV_ENVIRONMENT_BASE_SOCKET_URL,
  ENV_ENVIRONMENT_MONGO_API_URL,
  ENV_MM_THUMBNAIL_SSR,
  ENV_VMM_AGENCY_API,
} from "./environment";
export const BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const BASE_URL_API_STNHD = ENVIRONMENT_STNHD_BASE_URL_API;
export const BASE_SOCKET_URL = ENV_ENVIRONMENT_BASE_SOCKET_URL;
export const BASE_MONGO_URL = ENV_ENVIRONMENT_MONGO_API_URL;
export const BASE_SAVE_THUMBNAIL_URL = ENV_MM_THUMBNAIL_SSR;
export const BASE_AGENCY_API_URL = ENV_VMM_AGENCY_API;
export const BASE_URL_CLOUDFLARE = "https://www.cloudflare.com";
export default {
  /*Common*/
  GET_COMMON_GET_CITY: "/Province",
  GET_COMMON_GET_DISTRICT: "/District",
  GET_LIST_CLASS: "/SchoolClass",
  GET_SHARE_INFO: "WebsiteSTNHD/ShareInfo",
  UPDATE_USER_INFO: "MstUser/update",
  UPDATE_USER_INFO_STNHD: "vi-VN/Profile_STNHD/UpdateProfile",
  GET_PROFILE: "MstUser/GetProfile",
  /*End Common*/

  /*MINDMAP*/
  CREATE_MINDMAP: "/MstMindmap/create",
  GET_LIST_MINDMAP: "/MstMindmap/list",
  GET_LIST_MINDMAP_FILTERS: "/MstMindmap/listFilter",
  GET_DETAIL_MINDMAP: "/MstMindmap/detail",
  DELETE_MINDMAP: "/MstMindmap/delete",
  POST_FILTER_MINDMAP: "/MstMindmap/listFilter",
  PUT_UPDATE_MINDMAP: "/MstMindmap/update",
  POST_GET_LIST_USER_MINDMAP: "/MstMindmap/list-user",
  /*END MINDMAP*/

  /*MINDMAP INFO*/
  CREATE_MINDMAP_INFO: "/InfoMindmaps/create",
  UPDATE_MINDMAP_INFO: "/InfoMindmaps/update",
  DELETE_MINDMAP_INFO: "/InfoMindmaps/delete",
  GET_DETAIL_MINDMAP_INFO: "/InfoMindmaps/detail",
  /*END MINDMAP INFO*/

  /*MIND MAP IMAGE */
  GET_LIST_IMAGE: "/MstWarehouseImage/listFilter",
  POST_UPLOAD_IMAGE: "/MstWarehouseImage/create",
  GET_LIST_IMAGE_CATEGORY: "/MstCategoryImage/List",
  PUT_UPDATE_IMAGE: "/MstWarehouseImage/update",
  DELETE_IMAGE: "/MstWarehouseImage/delete",
  /*END MIND MAP IMAGE */
  /*MINDMAP CATEGORY*/
  POST_CREATE_MINDMAP_CATEGORY: "/MstCategoryMindmap/Create",
  GET_LIST_MINDMAP_CATEGORY: "/MstCategoryMindmap/List",
  UPDATE_CATEGORY_INFO: "/MstCategoryMindmap/Update",
  DELETE_MINDMAP_CATEGORY: "/MstCategoryMindmap/Delete",
  /*END MINDMAP CATEGORY*/
  //STNHD API
  POST_AUTH_SIGN_IN: "/SieuTriNhoHocDuong/login",
  POST_AUTH_REGISTER: "/DaiSuSTNHD/RegisterUserDaiSu",
  SYNC_INFO: "/MstUser/SyncInfo",
  POST_SEND_OTP: "/SieuTriNhoHocDuong/SendOTP",
  POST_SIGN_UP_TRIAL_ACCOUNT: "/SieuTriNhoHocDuong/Register",
  POST_UPDATE_TO_TRIAL_ACCOUNT: "/SieuTriNhoHocDuong/UpdateUserExperience",
  GET_TRIAL_ACCOUNT_INFO: "/SieuTriNhoHocDuong/GetUserExperience",
  POST_ACTIVE_CODE: "/SieuTriNhoHocDuong/ActiveCode",
  POST_CHECK_ACTIVE_CODE: "/SieuTriNhoHocDuong/CheckCode",
  GET_VIDEO_TUTORIAL_ACTIVE_CODE: "/SieuTriNhoHocDuong/ActiveCodeTutorial",
  POST_AUTH_FORGOT_PASSWORD: "/AccountHelper/ForgetPassword",
  SEND_OTP_FOGET_PASSWORD: "/AccountHelper/OTPConfirmPassword",
  POST_FORGET_USERNAME: "/AccountHelper/ForgetUsername",
  SEND_OTP_FORGET_USERNAME: "/AccountHelper/OTPConfirmAccount",
  POST_AUTH_FORGOT_PASSWORD_EMAIL: "/AccountHelperEmail/ForgetPassword",
  SEND_OTP_FOGET_PASSWORD_EMAIL: "/AccountHelperEmail/OTPConfirmPassword",
  SEND_OTP_FOGET_PASSWORD_FIRE_BASE: "/AccountHelper/OTPConfirmAccount",
  SEND_OTP_FOGET_PASSWORD_USERNAME_FIRE_BASE:
    "/AccountHelper/OTPConfirmPassword",
  POST_FORGET_USERNAME_EMAIL: "/AccountHelperEmail/ForgetUsername",
  SEND_OTP_FORGET_USERNAME_EMAIL: "/AccountHelperEmail/OTPConfirmAccount",

  SEND_OTP_REGISTER_EMAIL: "AccountHelperEmail/SendOTPEmail",

  //END STNHD API

  //OPTION API
  GET_OTP_OPTION_VMINDMAP: "/Options/detail",
  //END OPTION API

  //MESSAGE
  POST_SEND_MESSAGE: "/api/message",
  POST_GET_MORE_MESSAGE: "/api/message/more",
  POST_GET_LIST_ROOM: "/api/rooms/list",
  POST_CREATE_ROOM: "/api/rooms/create",
  POST_TYPING: "/api/rooms/typing",
  POST_UPDATE_READ_FLAG: "/api/rooms/status/update",
  GET_SEARCH_USER: "/api/user/search/",
  POST_CREATE_GROUP: "/api/group/create",
  POST_ADD_MEMBER_GROUP: "/api/group/add/members",
  POST_REMOVE_MEMBER_GROUP: "/api/group/delete/member",
  POST_OUT_GROUP: "/api/group/out",
  POST_PIN_MESSAGE: "/api/rooms/add/pin",
  POST_UNPIN_MESSAGE: "/api/rooms/unpin",
  POST_REACT_MESSAGE: "/api/message/react",
  POST_UNREACT_MESSAGE: "/api/message/unreact",
  POST_GET_LIST_REACT_MESSAGE: "/api/message/react/list",
  POST_SEARCH_MESSAGE: "/api/message/search",
  POST_SEARCH_ROOM: "/api/rooms/search_room",
  GET_ROOM_BY_MINDMAP: "/api/group",
  //END MESSAGE

  //NOTES
  POST_GET_LIST_NOTES_BY_MINDMAPID: "/InfoMindmapNode/listByInfoMindmapId",
  POST_CREATE_NOTES: "/InfoMindmapNode/create",
  PUT_UPDATE_NOTES: "/InfoMindmapNode/update",
  DELETE_NOTES: "/InfoMindmapNode/delete",
  //END NOTES

  //COMMENT
  POST_CREATE_COMMENT: "/api/comment/create",
  POST_ADD_MEMBERS_COMMENT: "/api/comment/add/members",
  POST_GET_COMMENT_ROOM: "/api/comment/list",
  POST_SEND_COMMENT: "/api/message/comment",
  POST_GET_MORE_COMMENT: "/api/message/comment/more",
  POST_DELETE_COMMENT: "/api/comment/delete",
  POST_COUNT_COMMENT: "/api/comment/count/message",
  POST_COUNT_FILE: "/api/comment/count/file",
  POST_COUNT_ALL_COMMENT: "/api/comment/count/all",
  POST_DELETE_COMMENT_BY_ID: "/api/message/comment/delete",
  POST_EDIT_COMMENT_BY_ID: "/api/message/comment/edit",
  GET_BRANCH_FILES: "/api/comment/file/list",
  //END COMMENT

  LIST_PRIVATE: "/MstOptions/list/groupcode/private-code",
  LIST_SERVICE: "/MstOptions/list/groupcode/service-code",
  //PATTERNS
  LIST_PATTERN: "/MstPattern/listFilter",
  GET_IP: "/cdn-cgi/trace",
  //DELETE ASSEST
  DELETE_ASSETS: "/api/comment/delete",
};
