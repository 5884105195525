import storage from "redux-persist/lib/storage/session"
import { persistReducer } from "redux-persist"
import * as Actions from "./constants"

const initState = {
  users: {},
  clients: [],
  myIdMember: null,
  roomId: null,
  eventMouse: null
}

const MouseUsersReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.SET_USER_DATA:
      const { idMember } = action.payload
      let usersNewObject = { ...state.users }
      if (idMember) {
        usersNewObject[idMember] = { ...action.payload }
        return {
          ...state,
          users: { ...usersNewObject }
        }
      }
      return state
    case Actions.SET_ID_MEMBER:
      return {
        ...state,
        myIdMember: action.payload
      }
    case Actions.USER_LEAVE:
      let usersNewLeaveObject = { ...state.users }
      delete usersNewLeaveObject[action.payload.idMember]
      return {
        ...state,
        users: { ...usersNewLeaveObject }
      }
    case Actions.SET_CLIENTS:
      return {
        ...state,
        clients: [...action.payload]
      }
    case Actions.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.payload
      }
    case Actions.SET_EVENT_MOUSE:
      return {
        ...state,
        eventMouse: action.payload
      }
    default:
      return state
  }
}

const persistConfig = {
  key: "MouseUsers",
  storage
}

export default persistReducer(persistConfig, MouseUsersReducer)
