import common from "./common/reducer";
import user from "./user/reducer";
import message from "./message/reducer";
import notification from "./notification/reducer";

export default {
  common: common,
  user,
  message,
  notification,
};
