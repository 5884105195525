import shareInfoSaga from "./common/sagas";
import userSagas from "./user/sagas";
import messageSagas from "./message/sagas";
import notificationSagas from "./notification/sagas";

export default [
  shareInfoSaga(),
  userSagas(),
  messageSagas(),
  notificationSagas(),
];
