import {
  getBezierPath,
  Position,
} from "../../../components/ReactFlow/dist/ReactFlow.esm";
import { Bezier } from "./lib/Bezier";
import {
  angleFromTwoLine,
  getAngleFromTwoPoint,
  getDistance,
  twoCircleIntersection,
} from "./mathUtils";
export const renderCurve = (points = [], isHead = false, isReverse = false) => {
  if (!isReverse) {
    if (isHead) {
      if (points.length === 3) {
        return `M ${points[0].x} ${points[0].y} Q ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y}`;
      }
      return `M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}`;
    }
    if (points.length === 3) {
      return `Q ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y}`;
    }
    return `C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}`;
  }
  if (isHead) {
    if (points.length === 3) {
      return `M ${points[2].x} ${points[2].y} Q ${points[1].x} ${points[1].y} ${points[0].x} ${points[0].y}`;
    }
    return `M ${points[3].x} ${points[3].y} C ${points[2].x} ${points[2].y} ${points[1].x} ${points[1].y} ${points[0].x} ${points[0].y}`;
  }
  if (points.length === 3) {
    return `Q ${points[1].x} ${points[1].y} ${points[0].x} ${points[0].y}`;
  }
  return `C ${points[2].x} ${points[2].y} ${points[1].x} ${points[1].y} ${points[0].x} ${points[0].y}`;
};
const renderTaperedCurve = (curves = []) => {
  return curves
    .map((item, index) => renderCurve(item.points, index === 0, true))
    .join(" ");
};

const getRelativeControlPoints = (
  sX = 0,
  sY = 0,
  p1 = { x: 0, y: 0 },
  p2 = { x: 0, y: 0 }
) => {
  let newP1 = { ...p1 };
  let newP2 = { ...p2 };
  p1.x -= sX;
  p1.y -= sY;
  p2.x -= sX;
  p2.x -= sY;
  return [newP1, newP2];
};

export const getTaperedBezier = (
  sourceX,
  sourceY,
  targetX,
  targetY,
  bezierPoint1,
  bezierPoint2,
  deltaTail,
  deltaHead = 1
) => {
  try {
    let bezier = null;
    if (!bezierPoint1 || !bezierPoint2) {
      return null;
    }
    if (
      Math.abs(sourceX - bezierPoint1?.x) <= 2 &&
      Math.abs(sourceY - bezierPoint1?.y) <= 2
    ) {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    } else {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint1?.x || sourceX + 100,
        bezierPoint1?.y || sourceY - 100,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    }

    let outline = bezier.outline(deltaTail, deltaTail, deltaHead, deltaHead);
    let p1 = bezier.get(0.44);
    let p2 = bezier.get(0.77);
    let length = outline.length();
    return {
      path: renderTaperedCurve(outline.curves.reverse()),
      controlPointStart: p1,
      controlPointEnd: p2,
      length,
    };
  } catch (err) {
    console.log("GET TAPRED ERR", err);
    return null;
  }
};
export const getCurveBezier = (
  sourceX,
  sourceY,
  targetX,
  targetY,
  bezierPoint1,
  bezierPoint2,
  isSpeedLayout
) => {
  try {
    let bezier = null;

    // if (isSpeedLayout) {
    //   return {
    //     path: `M${sourceX},${sourceY} Q${sourceX},${targetY} ${targetX},${targetY}`,
    //     length: 0,
    //     controlPointStart: { x: 0, y: 0 },
    //     controlPointEnd: { x: 0, y: 0 },
    //   };
    // }

    if (
      Math.abs(sourceX - bezierPoint1?.x) <= 2 &&
      Math.abs(sourceY - bezierPoint1?.y) <= 2
    ) {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    } else {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint1?.x || sourceX + 100,
        bezierPoint1?.y || sourceY - 100,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    }
    let { points } = bezier;
    let p1 = bezier.get(0.44);
    let p2 = bezier.get(0.77);
    let length = bezier.length();

    return {
      path: renderCurve(points, true),
      controlPointStart: p1,
      controlPointEnd: p2,
      length,
    };
  } catch (err) {
    return {
      path: "",
      controlPointStart: { x: 0, y: 0 },
      controlPointEnd: { x: 0, y: 0 },
      length: 0,
    };
  }
};

export const getCurveBezierData = (
  sourceX,
  sourceY,
  targetX,
  targetY,
  bezierPoint1,
  bezierPoint2,
  delta
) => {
  try {
    let bezier = null;
    if (
      Math.abs(sourceX - bezierPoint1?.x) <= 2 &&
      Math.abs(sourceY - bezierPoint1?.y) <= 2
    ) {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    } else {
      bezier = new Bezier(
        sourceX,
        sourceY,
        bezierPoint1?.x || sourceX + 100,
        bezierPoint1?.y || sourceY - 100,
        bezierPoint2?.x || targetX + 100,
        bezierPoint2?.y || targetY - 100,
        targetX,
        targetY
      );
    }

    return bezier;
  } catch (err) {
    return null;
  }
};

export const getBezierDraggingLine = (p1, p2) => {
  let startX = p1.x,
    startY = p1.y,
    endX = p2.x,
    endY = p2.y,
    inset,
    width,
    scale = 1;
  width = Math.abs(endX - startX);
  inset = Math.min(width * 0.2, 20 * scale);
  let button1 = { x: 0, y: 0 };
  let button2 = { x: 0, y: 0 };
  if (startX > endX) {
    button1.x = startX;
    button1.y = startY - 1;
    button2.x = startX - inset;
    button2.y = endY;
  } else {
    button1.x = startX;
    button1.y = startY - 1;
    button2.x = startX + inset;
    button2.y = endY;
  }

  return {
    button1,
    button2,
  };
};

export const getRootBezierDraggingLine = (p1, p2) => {
  let startX = p1.x,
    startY = p1.y,
    endX = p2.x,
    endY = p2.y,
    angle,
    dx,
    dxAbs,
    dy,
    dyAbs,
    dyCentreAbs,
    heightLimit,
    rootBranchControlPoint1,
    rootBranchControlPoint2,
    upperEdge,
    yOffset;
  dx = startX - endX;
  dy = startY - endY;
  dxAbs = Math.abs(dx);
  dyAbs = Math.abs(dy);
  upperEdge = false;
  if (dyAbs > dxAbs) {
    upperEdge = true;
  }
  angle = 2.0;
  if (dxAbs > 0.0) {
    angle = dyAbs / dxAbs;
  }
  if (angle > 2.0) {
    angle = 2.0;
  }
  rootBranchControlPoint1 = { x: 0, y: 0 };
  rootBranchControlPoint2 = { x: 0, y: 0 };

  rootBranchControlPoint1.x = startX - (startX - endX) * 0.45;

  rootBranchControlPoint2.x = endX + (startX - endX) * 0.45;

  rootBranchControlPoint1.y =
    startY -
    (Math.abs((startY - endY) * 0.2) < 100
      ? (startY - endY) * 0.2
      : startY < endY
      ? -100
      : 100);
  rootBranchControlPoint2.y =
    endY +
    (Math.abs((startY - endY) * 0.2) < 50
      ? (startY - endY) * 0.2
      : startY < endY
      ? -50
      : 50);
  return {
    button1: rootBranchControlPoint1,
    button2: rootBranchControlPoint2,
  };
};
export const isApproximately = (a = 0, b = 0, delta = 0) =>
  Math.abs(a - b) <= delta;

export const getDefaultConnectionLinePosition = (
  sx = 0,
  sy = 0,
  tx = 0,
  ty = 0,
  sw = 0,
  sh = 0,
  tw = 0,
  th = 0,
  isLeft = false,
  isOrganic = false,
  spacingHead = false,
  spacingTail = false,
  ssx = 0,
  ssy = 0,
  stx = 0,
  sty = 0
) => {
  const APPOXIMATELY_DELTA = 4;
  const DELTA_SOURCE_HEIGHT = sh / 5;
  const DELTA_TARGET_HEIGHT = th / 5;
  const DEFAULT_DADIUS = 4;
  let nsx, nsy, ntx, nty, cps, cpe, isUpper;

  if (isApproximately(sy, ty, APPOXIMATELY_DELTA)) {
    if (sx <= tx) {
      nsx = sx - 0;
      nsy = sy - DELTA_SOURCE_HEIGHT;
      ntx = tx + 0;
      nty = ty - DELTA_TARGET_HEIGHT;
    } else {
      nsx = sx + 0;
      nsy = sy - DELTA_SOURCE_HEIGHT;

      ntx = tx - 0;
      nty = ty - DELTA_TARGET_HEIGHT;
    }
  } else if (sy <= ty) {
    if (sx <= tx) {
      nsx = sx - 0;
      nsy = sy + DELTA_SOURCE_HEIGHT;

      ntx = tx + 0;
      nty = ty - DELTA_TARGET_HEIGHT;
    } else {
      nsx = sx + 0;
      nsy = sy + DELTA_SOURCE_HEIGHT;

      ntx = tx - 0;
      nty = ty - DELTA_TARGET_HEIGHT;
    }
  } else {
    if (sx <= tx) {
      nsx = sx - 0;
      nsy = sy - DELTA_SOURCE_HEIGHT;

      ntx = tx + 0;
      nty = ty + DELTA_TARGET_HEIGHT;
    } else {
      nsx = sx + 0;
      nsy = sy - DELTA_SOURCE_HEIGHT;

      ntx = tx - 0;
      nty = ty + DELTA_TARGET_HEIGHT;
    }
  }
  if (isApproximately(sx, tx)) {
    if (!isLeft) {
      nsx += sw;
      ntx += tw;
    }
  }
  if (isLeft) {
    if (nsy === nty || isApproximately(nsy, nty)) {
      if (ntx <= nsx) {
        isUpper = true;
      } else {
        isUpper = false;
      }
    } else if (nsy <= nty) {
      isUpper = true;
    } else {
      isUpper = false;
    }
  } else {
    if (nsy === nty || isApproximately(nsy, nty)) {
      if (ntx <= nsx) {
        isUpper = true;
      } else {
        isUpper = false;
      }
    } else if (nsy <= nty) {
      isUpper = false;
    } else {
      isUpper = true;
    }
  }
  const angleOfTwoBranch = angleFromTwoLine(sx, sy, ssx, ssy, tx, ty, stx, sty);
  if (isOrganic) {
    let alpha1 = getAngleFromTwoPoint(
      {
        x: tx,
        y: ty,
      },
      {
        x: sx,
        y: sy,
      }
    );
    let alpha2 = getAngleFromTwoPoint(
      {
        x: sx,
        y: sy,
      },
      {
        x: tx,
        y: ty,
      }
    );
    if (spacingHead) {
      ntx = Math.cos(alpha2) * DEFAULT_DADIUS + tx;
      nty = Math.sin(alpha2) * DEFAULT_DADIUS + ty;
    }
    if (spacingTail) {
      nsx = Math.cos(alpha1) * DEFAULT_DADIUS + sx;
      nsy = Math.sin(alpha1) * DEFAULT_DADIUS + sy;
    }
  }
  if (angleOfTwoBranch && isOrganic) {
    isUpper = angleOfTwoBranch < 0;
  }
  let r = getDistance(
    {
      x: nsx,
      y: nsy,
    },
    { x: ntx, y: nty }
  );

  r /= 2;
  // let newR = 60
  r += Math.round(r / 10);
  // r += 20
  const { p1, p2 } = twoCircleIntersection(nsx, nsy, r + 2, ntx, nty, r - 2);
  let centerPoint = isUpper ? p1 : p2;
  let angle = getAngleFromTwoPoint(
    {
      x: nsx,
      y: nsy,
    },
    { x: ntx, y: nty }
  );
  let newPointStart = {
    x: Math.cos(angle) * (r / 2) + centerPoint.x,
    y: Math.sin(angle) * (r / 2) + centerPoint.y,
  };
  let newPointEnd = {
    x: Math.cos(angle + Math.PI) * (r / 2) + centerPoint.x,
    y: Math.sin(angle + Math.PI) * (r / 2) + centerPoint.y,
  };
  cpe = newPointEnd;
  cps = newPointStart;
  return {
    nsx,
    nsy,
    ntx,
    nty,
    cps,
    cpe,
  };
};
