import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage/session";
import { createAction, handleActions } from "redux-actions";

// const createNoopStorage = () => {
//   return {
//     getItem(_key) {
//       return Promise.resolve(null)
//     },
//     setItem(_key, value) {
//       return Promise.resolve(value)
//     },
//     removeItem(_key) {
//       return Promise.resolve()
//     }
//   }
// }
export const types = {
  SET_LIST_MINDMAP: "SET_LIST_MINDMAP",
  REMOVE_MINDMAP: "REMOVE_MINDMAP",
  DUPPLICATE_MINDMAP: "DUPPLICATE_MINDMAP",
  CREATE_MINDMAP: "CREATE_MINDMAP",
  UPDATE_COLLECTION: "UPDATE_COLLECTION",
};

export const actions = {
  setListMindmap: createAction(types.SET_LIST_MINDMAP),
  removeMindmap: createAction(types.REMOVE_MINDMAP),
  dupplicateMindmap: createAction(types.DUPPLICATE_MINDMAP),
  createMindmap: createAction(types.CREATE_MINDMAP),
  updateCollection: createAction(types.UPDATE_COLLECTION),
};

const defaultState = {
  listMindmap: [],
};
// const storage =
//   typeof window !== "undefined"
//     ? createWebStorage("local")
//     : createNoopStorage()

const reducer = (state = defaultState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case types.SET_LIST_MINDMAP: {
      return {
        ...state,
        listMindmap: payload,
      };
    }
    case types.CREATE_MINDMAP: {
      return {
        ...state,
        listMindmap: [...state.listMindmap, payload],
      };
    }
    case types.REMOVE_MINDMAP: {
      let id = payload;
      return {
        ...state,
        listMindmap: [...state.listMindmap?.filter((item) => item?.id !== id)],
      };
    }
    case types.DUPPLICATE_MINDMAP: {
      let id = Date.now();
      let dupplicateMindmap = state.listMindmap?.find(
        (item) => item?.id == payload
      );

      return {
        ...state,
        listMindmap: [
          ...state.listMindmap,
          {
            ...dupplicateMindmap,
            id,
            name: dupplicateMindmap?.name + "(copy)",
          },
        ],
      };
    }
    case types.UPDATE_COLLECTION: {
      let { id, data } = payload;
      return {
        ...state,
        listMindmap: [
          ...state.listMindmap.map((item) =>
            item.id == id
              ? {
                  ...item,
                  ...data,
                }
              : item
          ),
        ],
      };
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: "collections",
  storage,
};

export default persistReducer(persistConfig, reducer);
